import { Component, OnInit, NgZone, EventEmitter, Output } from '@angular/core';
import { NgForm } from '@angular/forms';
import { FormsModule, FormGroup } from '@angular/forms';
import { FormControl, FormArray, FormBuilder, ReactiveFormsModule } from '@angular/forms';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { TokenService } from '../../Services/token.service';
import { Router } from '@angular/router';
import Swal from 'sweetalert2';
import { IDropdownSettings } from 'ng-multiselect-dropdown';

@Component({
  selector: 'app-job-post',
  templateUrl: './job-post.component.html',
  styleUrls: ['./job-post.component.css']
})
export class JobPostComponent implements OnInit {

  minDate = new Date();
  stdate = true;
  // rolein_hire: any;
  hirerole: string = "";
  role_hire: any;
  rolein_hires: any;
  hire: any;
  job_lat: any;
  job_lng: any;
  clickChange(){
      this.stdate =true;     
  }
  clickdel(){
    this.stdate =false;     
  }
  jobids = localStorage.getItem('job_id');

  status: boolean = true;
  salarypd: any;
  jobscheduled: any;
  schedule: string = "";
  jobdetailone: any;
  jobs_role: any;
  payhire: any;
  jobs_title: any;
  jobs_hire: any;
  jobs_sche: any;
  jobs_date: any;
  isstartdate: any;
  jobs_cntrct: any;
  jbtype: any;

  pro_address: any;
  citydata: any;
  statedata: any;
  pindata: any;
  formattedaddress = " ";
  addata = " ";
  options = {
    componentRestrictions: {
      country: ["USA"]
    }
  }
  public AddressChange(address: any) {
    //setting address from API to local variable
    this.formattedaddress = address.address_components;
    var html_attributions = address.html_attributions;
    //console.log(address.formatted_address);
    this.pro_address = address.formatted_address;

    this.job_lat = address.geometry.location.lat();
    this.job_lng = address.geometry.location.lng();


    for (const component of address.address_components) {
      const componentType = component.types[0];
      switch (componentType) {
        case "street_number": {
          break;
        }
        case "route": {
          //  this.pro_address = `${component.long_name}`;
          break;
        }
        case "postal_code": {
          this.pindata = `${component.long_name}`;
          break;
        }
        case "postal_code_suffix": {
          this.pindata = `${this.pindata}-${component.long_name}`;
          break;
        }
        case "locality":
          this.citydata = component.long_name;
          break;
        case "administrative_area_level_1": {
          this.statedata = component.short_name;
          break;
        }
        case "country":
          //this. = component.long_name;
          break;
      }
    }
  }

  clickEvent() {
    this.status = !this.status;
  }


  url1: any = '';
  empjobtype: any;
  empjobtyp: string = "";
  empcategory: any;
  empcat: string = "";

  pagejobid: any = [];
  dropdownSettings;
  multi_location;
  selectedItem: any;
  jobmultiple_loc: any;


  constructor(private http: HttpClient, private Token: TokenService, private router: Router) { }

  ngOnInit(): void {
    this.url1 = this.Token.baseurl();
    this.getempjobtype();
    this.getcategory();
    this.getsalarypd();
    this.getjobschedule();
    this.getpostedjobdetail();
    this.getrole_inhiring();
    this.getmultilocationbyid();
    this.multi_location = this.getlocation();
    this.dropdownSettings = {
      singleSelection: false,
      idField: 'item_id',
      textField: 'item_text',
      selectAllText: 'Select All',
      unSelectAllText: 'UnSelect All',
      itemsShowLimit: 5,
      allowSearchFilter: true
    };

  }

  logout(Event: MouseEvent) {
    Event.preventDefault();
    this.Token.remove();
    this.router.navigateByUrl('/employer-login');
  }
  
  lat: any;

  getpostedjobdetail() {

    var jb_id = localStorage.getItem('job_id');
    this.http.get<any>(this.Token.baseurl()+ 'api/getpostedjobbyid/' + jb_id).subscribe(  
      response => {
        this.jobdetailone = response;
       // console.log(response);
        this.jobs_role = this.jobdetailone[0].jobdata.job_role_id;
       // console.log(this.jobs_role);
         this.jobs_hire = this.jobdetailone[0].jobdata.hireperson;
         this.jobs_title = this.jobdetailone[0].jobdata.job_title;
         this.jobs_sche = this.jobdetailone[0].jobdata.job_shift_id;
         this.jobs_cntrct = this.jobdetailone[0].jobdata.salary_type_id;
        // console.log(this.jobs_cntrct);
         this.jobs_date = this.jobdetailone[0].jobdata.start_date;
         this.selectedItem = this.jobdetailone[0].jobdata.multil_loc_id;
         this.isstartdate = this.jobdetailone[0].jobdata.isstartdate;
        // console.log(this.isstartdate);
        this.hire = this.jobdetailone[0].jobdata.role_inhire;
         this.jbtype = this.jobdetailone[0].jobdata.job_type_id;
         this.pro_address = this.jobdetailone[0].jobdata.job_location;
         
        // console.log(this.hire);

         this.statedata = this.jobdetailone[0].jobdata.job_state;
         this.citydata = this.jobdetailone[0].jobdata.job_city;
         this.pindata = this.jobdetailone[0].jobdata.job_zip;

         this.job_lat = this.jobdetailone[0].jobdata.job_late;
         this.job_lng = this.jobdetailone[0].jobdata.job_long;
        // console.log(this.job_lat);
      }
    );
  }

  getjobschedule(){
    this.http.get<any>(this.url1 +'api/getdesired_work_schedule').subscribe(
      response => {
        this.jobscheduled = response;
      }
    );
  }
  getsalarypd() {
    this.http.get<any>(this.url1 + 'api/salaryperiod').subscribe(
      response => {
        this.salarypd = response;
      }
    );
  }
  getrole_inhiring(){
    this.http.get<any>(this.url1 +'api/rolein_hiring').subscribe(
      response => {
        this.rolein_hires = response;
        // console.log(this.rolein_hire);
      }
    );
  }


  getempjobtype() {
    this.http.get<any>(this.url1 + 'api/employerjobtype').subscribe(
      response => {
        this.empjobtype = response;
      }
    );
  }
  getcategory() {
    this.http.get<any>(this.url1 + 'api/employercategory').subscribe(
      response => {
        this.empcategory = response;
      }
    );
  }

  onAddJob(data) {
    // console.log(data);
    var tokens = this.Token.get();
    var jobid = '';
    const url = this.url1 + 'api/addnewjob';
    this.http.post(url, { token: tokens, job_title: data.job_title, job_type_id: data.job_type_id, job_category_id: data.job_category_id, description: data.description, page: 1 })
      .subscribe((result) => {
        this.pagejobid = result;
        // console.log(this.pagejobid.job_id);
        localStorage.setItem('job_id', this.pagejobid.job_id);
        jobid = this.pagejobid.job_id;
        for (var dt in data.required_skill) {
          const urls = this.url1 + 'api/updatejobskill';
          this.http.post(urls, { skill_id: data.required_skill[dt].item_id, job_id: jobid, token: tokens })
            .subscribe((result) => {
              // this.ngOnInit(); //reload the table
            });
        }
        this.router.navigateByUrl('/job-post2'); 
      });
    // var jid = localStorage.getItem('job_id');
    //alert(jobid);
  }


  onAddJobfst(data){
    
    // if(this.job_lat == null){
    //   var txt = 'Please choose full addreess from dropdown';
    //   this.alertWithWarning(txt);
    // }else
    // {
      var j_id = localStorage.getItem('grp_job_id');
      var j_id1 = localStorage.getItem('job_id');
    var tokens = this.Token.get();
    var jobid = '';
    const url = this.url1 + 'api/addnewjob';
    var s='';
    for (var dt in data.new_location) {
   s =  data.new_location[dt].item_id+'|'+s ;
     }

    this.http.post(url, { token: tokens, grp_job_id: j_id, job_id: j_id1, job_title: data.job_title,role_inhire:data.role_inhire,job_role:data.job_role_id, job_type_id: data.job_typ_id, hireperson: data.hires, salary_type_id: data.contract_id, job_shift_id: data.job_sh_id,multi_location_id: s,isstartdate: data.isstartdate,start_date: data.startdate, location: data.location, city: data.city,
      state: data.state, zip: data.zip, late: data.job_late, long: data.job_long, page: 1 })
      .subscribe((result) => {
        this.pagejobid = result;
        localStorage.setItem('grp_job_id', this.pagejobid.grp_job_id);
        jobid = this.pagejobid.grp_job_id;
        this.router.navigateByUrl('/job-post2'); 
      });
   // }
    
  }

  
  getmultilocationbyid() {

    var em_id = localStorage.getItem('employer_id');
    if(em_id){
    this.http.get<any>(this.Token.baseurl()+ 'api/getmultilocationby_id/' + em_id).subscribe(
      response => {
        this.jobmultiple_loc = response;
        console.log(response);
         //this.selectedItem = this.jobmultiple_loc[0].item_id;
      }
    );
    }
  }

  getlocation() {
    var ems_id = localStorage.getItem('employer_id');
    if(ems_id){
      this.http.get<any>(this.Token.baseurl()+ 'api/getmulti_location/' + ems_id).subscribe(
      response => {
        this.multi_location = response;
      }
    );
    }
  }

  alertWithSuccess(txt) {
    Swal.fire('Thank you...', txt, 'success')
  }
  alertWithWarning(txt) {
    Swal.fire('Warning...', txt, 'warning')
  }
  privacy_policy() {
    this.router.navigate(['/privacy_policy']);
  }

 agrement(){
    this.router.navigateByUrl('/agreement');
  }

faq_data() {
    this.router.navigate(['/faq_desc']);
  }
  invest_data(){
    this.router.navigate(['/invest']);
  }
  about_us(){
    this.router.navigate(['/about']);
  }
}
