import { Component } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import Swal from 'sweetalert2';
import { HostListener } from '@angular/core';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
 
  skeletonloader = true;
  data: any;
  constructor( private http: HttpClient) {
   
    // this.http.get('https://hospyapi.snckapp.com/').subscribe(data => {
    //     this.data = data;
    //     this.skeletonloader = false;
    //     }, error => console.error(error));
   }

  // For refreshing using back btn
  @HostListener('window:popstate', ['$event'])
  onPopState(event) {
  location.reload()
  }
  // Ends refreshing using back btn
  title = 'hospy';

   status: boolean = true;
  clickEvent(){
      this.status = !this.status;       
  }

  loadedFeature = 'edit-candidate';
  onNavigate(feature: string){
    this.loadedFeature = feature;
  }

   //Local Variable defined
  formattedaddress=" ";
  options={
    componentRestrictions:{
      country:["AU"]
    }
  }
  public AddressChange(address: any) {
  //setting address from API to local variable
   this.formattedaddress=address.formatted_address
}

}
