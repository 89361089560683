import { Component, OnInit } from '@angular/core';
import {NgbModal, ModalDismissReasons} from '@ng-bootstrap/ng-bootstrap';
import Swal from 'sweetalert2';
import { TokenService } from '../../Services/token.service';
import { HttpClient,HttpHeaders } from '@angular/common/http';
import { Router } from '@angular/router';

export {};
declare global {
   interface Window {
      Calendly: any;
   }
}
declare var preInitCalendly: Function;
declare var isCalendlyEvent: Function;

@Component({
  selector: 'app-view-applied-candidate',
  templateUrl: './view-applied-candidate.component.html',
  styleUrls: ['./view-applied-candidate.component.css']
})
export class ViewAppliedCandidateComponent implements OnInit {
  applycandidate: any;
  apply_candidates: string = "";
  workdetails: any;
  workde: string = "";
  profirst_name: any;
  prolast_name: any;
  proemail: any;
  pro_phone:any;
  pro_whatsapp:any;
  pro_address:any;
  citydata:any;
  statedata:any;
  pindata:any;
  pro_fb:any;
  pro_workas:any;
  userdetail: any;
  transport: any;
  background: any;
  drugtest: any;
  felonies: any;
  pic: any;
  workas: any;
  workin: any;
  educationdata: any;
  education: string = "";
  school_name: any;
  location_educat: any;
  field_educat: any;
  start_educat: any;
  end_educat: any;
  current_study: any;
  desiredetail: any;
  desire_job: any;
  desire_schedule: any;
  desire_relocation: any;
  not_interested: any;
  expectations: any;
  age: any;
  eligible: any;
  travel: any;
  license: any;
  trnsprt_cmmnt: any;
  bg_cmmnt: any;
  drug_cmmnt: any;
  felony_cmmnt: any;
  fctchip: any;
  nextrole: any;
  skil_describe: any;
  strt_soon: any;
  
  summary_job: any;
  summaryd: any;
  resume_skillsum: any;
  urii: any;
  clndly: any;

  

  open(content) {
    this.modalService.open(content, {ariaLabelledBy: 'modal-basic-title'}).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
    this.ngOnInit();
  }
  closeResult = '';
  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }

  
  

  constructor( private http: HttpClient, private modalService: NgbModal,private router: Router, private Token : TokenService ) { }

  ngOnInit(): void {
    this.getapplycandidate();
    this.getworkdetails();
    this.getuserdetail();
    this.geteducationdetail();
    this.getdesiredetail();
    this.getfactchips();
    this.getsummarydetail();
    this.getcalendy_data();
  }
  url_c:any;
  calndr_data: any = "";
  getcalendy_data() {
    var chipstoken = localStorage.getItem('token');
    this.http.get<any>(this.Token.baseurl() + 'api/getcalendar_link/' + chipstoken).subscribe(
      response => {
        this.clndly = response;
         
        this.calndr_data = this.clndly[0].calendar_link;
        console.log(this.calndr_data);
        this.url_c = 'https://calendly.com/' +this.calndr_data;
      }
    );
  }
  

  preInitCalendly(){
    this.getuserdetail();
  window.Calendly.initPopupWidget({
     url: this.url_c,
     parentElement: document.querySelector('.calendly-popup-widget'),
     prefill: {
       name: this.profirst_name,
     email: this.proemail
    }
  });
  
  window.addEventListener("message", (e) => {

    if (this.isCalendlyEvent(e)) {
      this.urii = e.data.payload.invitee.uri;
     // console.log(this.urii);
     this.interviewdata();
    }
    
    
  }, false);      
 
}
 isCalendlyEvent(e) {
  return e.data.event && e.data.event.indexOf('calendly') === 0;
  }
   
  interviewdata(){
    var jb_id = localStorage.getItem('job_id');
    var employee = localStorage.getItem('employee_id');
    var employer = localStorage.getItem('employer_id');
    const url = this.Token.baseurl() + 'api/updateurii';
    this.http.post(url, { uri: this.urii, jobid: jb_id, employe_id:employee, employer_id:employer })
    .subscribe((result) => {
      var txt='Interview is scheduled';
      this.alertWithSuccess(txt);
      this.ngOnInit();
     // console.log(result);
    });
  }
  alertWithSuccess(txt) {
    Swal.fire('Thank you...', txt, 'success')
  }


  getapplycandidate(){
    var jb_id = localStorage.getItem('job_id');
    this.http.get<any>(this.Token.baseurl()+'api/getappliedemployee/'+jb_id).subscribe( response => {
      this.applycandidate = response;
      this.not_interested=this.applycandidate[0].notint;
       console.log(this.applycandidate);
      }
    );
  }
  setemployeeid(id){
    localStorage.setItem('employee_id',id);
  }
  setemployerid(id){
    localStorage.setItem('employer_id',id);
  }
  getusertoken(id){
      localStorage.setItem('user_token',id);
    }
    setviewed(id){
      this.http.get(this.Token.baseurl()+'api/updateapplicationproview/'+id) .subscribe((result) => {
        this.ngOnInit();
      });
    }
    notinterested(id){
      this.http.get(this.Token.baseurl()+'api/updatenotinterested/'+id) .subscribe((result) => {
        this.ngOnInit();
      });
    }

  getworkdetails(){
    var worktoken = localStorage.getItem('user_token');
     this.http.get<any>(this.Token.baseurl()+'api/getresumeworkdetails/'+worktoken).subscribe(
       response => {
       this.workdetails = response;
      // console.log(response);
       }
     );
   }
   getuserdetail(){
    var profiletoken = localStorage.getItem('user_token');
     this.http.get<any>(this.Token.baseurl()+'api/getresumeprofile/'+profiletoken).subscribe(
       response => {
       this.userdetail = response;
      //  console.log(response);
        this.profirst_name=this.userdetail[0].f_name;
        this.prolast_name=this.userdetail[0].l_name;
        this.proemail=this.userdetail[0].email;
        this.pro_phone=this.userdetail[0].phone;
        this.pro_whatsapp=this.userdetail[0].whatsapp;
        this.pro_address=this.userdetail[0].location;
        this.citydata=this.userdetail[0].city;
        this.statedata=this.userdetail[0].state;
        this.pindata=this.userdetail[0].zip;
        this.pro_fb=this.userdetail[0].facebook;
        this.workas=this.userdetail[0].sub_job_types;
        this.workin=this.userdetail[0].job_types;
        this.transport=this.userdetail[0].istransportation;
        this.background=this.userdetail[0].isbackground;
        this.drugtest=this.userdetail[0].isdrugtest;
        this.felonies=this.userdetail[0].isfelonies;

        this.expectations = this.userdetail[0].hourly_expectation;
        this.age = this.userdetail[0].age_eighteen;
        this.eligible = this.userdetail[0].eligible_towork;
        this.travel = this.userdetail[0].travel_willing;
        this.license = this.userdetail[0].license_valid;
        this.trnsprt_cmmnt = this.userdetail[0].transport_comment;
        this.bg_cmmnt = this.userdetail[0].background_comment;
        this.drug_cmmnt = this.userdetail[0].drugtest_comment;
        this.felony_cmmnt = this.userdetail[0].felony_comment;

        

        if(this.userdetail[0].picture!=null){
        this.pic=this.Token.baseurl()+'employee/'+this.userdetail[0].picture;
        }
        else{
         this.pic=this.Token.baseurl()+'employee/'+'profile.png';
        }
       }
     );
   }
   getsummarydetail() {
    var summarytoken = localStorage.getItem('user_token');
    this.http.get<any>(this.Token.baseurl() + 'api/getresumesummarydetails/' + summarytoken).subscribe(
      response => {
        this.summaryd = response;
        if (this.summaryd.length > 0) {
          this.summary_job = this.summaryd[0].summary;
          this.resume_skillsum = this.summaryd[0].skill_summary;
        //  console.log(this.summary_job);
        }
      }
    );
  }

   getfactchips() {
    var chipstoken = localStorage.getItem('user_token');
    this.http.get<any>(this.Token.baseurl() + 'api/getfactchips/' + chipstoken).subscribe(
      response => {
        this.fctchip = response;
        // console.log(this.fctchip);
        this.nextrole = this.fctchip.next_role_name;
        this.skil_describe = this.fctchip.skilldescribe_name;
        this.strt_soon = this.fctchip.soonstart_name;
        
      }
    );
  }
   geteducationdetail(){
    var educationtoken = localStorage.getItem('user_token');
     this.http.get<any>(this.Token.baseurl()+'api/getresumeeducationdetails/'+educationtoken).subscribe(
       response => {
       this.educationdata = response;
      // console.log(response);
      // this.resume_edu_id=this.educationdata[0].id;
        this.school_name=this.educationdata[0].school_name;
        this.location_educat=this.educationdata[0].location;
        this.field_educat=this.educationdata[0].field_study;
        this.start_educat=this.educationdata[0].start_date;
        this.end_educat=this.educationdata[0].end_date;
        this.current_study=this.educationdata[0].job_title;
       }
     );
   }
   getdesiredetail() {

    var desiretoken = localStorage.getItem('user_token');
    this.http.get<any>(this.Token.baseurl() + 'api/getdesiredjobworkschedule/' + desiretoken).subscribe(
      response => {
        this.desiredetail = response;
      //  console.log(response);
        if (this.desiredetail != '') {
          this.desire_job = this.desiredetail.job_type_name;
          this.desire_schedule = this.desiredetail.work_schedule_name;
          this.desire_relocation = this.desiredetail.relocation;
          
        }
      }
    );
  }
  privacy_policy() {
    this.router.navigate(['/privacy_policy']);
  }

 agrement(){
    this.router.navigateByUrl('/agreement');
  }

faq_data() {
    this.router.navigate(['/faq_desc']);
  }
  invest_data(){
    this.router.navigate(['/invest']);
  }
  about_us(){
    this.router.navigate(['/about']);
  }
  
  // initPopupWidget(){
  //       url: 'https://calendly.com/pablo-184/30min';
  //       window.location.href="https://calendly.com/pablo-184/30min";
             
  //  }


}
