import { Component, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { FormsModule, FormGroup } from '@angular/forms';
import { FormControl, FormArray, FormBuilder, ReactiveFormsModule } from '@angular/forms';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Router } from '@angular/router';
import { TokenService } from '../../Services/token.service';
import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-latest-job',
  templateUrl: './latest-job.component.html',
  styleUrls: ['./latest-job.component.css']
})
export class LatestJobComponent implements OnInit {

  closeResult = '';
  status: boolean = true;
  job_list: any;
  job_lis: string = "";
  data: string = "";
  isapplied: any;
  comp_name: any;
  comp_specialty: any;
  comp_details: any;
  comp_type: any;
  comp_address: any;
  comp_established_in: any;
  all_job_data_by_company: any;
  clickEvent(){
      this.status = !this.status;       
  }

  constructor(private http: HttpClient, private router: Router, private Token: TokenService, private modalService: NgbModal) { }

  open(content) {
    this.ngOnInit();
    this.modalService.open(content, { ariaLabelledBy: 'modal-basic-title' }).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
  }
  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }

  ngOnInit(): void {
    this.getjoblists();
    this.showMoreJobDetailsnyuserid();
  }
  getuserid(id){
    localStorage.setItem('jobdetail_id',id);
  
  }
  getjoblists(){
    var token = this.Token.get();
    this.http.get<any>(this.Token.baseurl()+'api/getalljob/'+token).subscribe( response => {
      this.job_list = response;
    //  this.ngOnInit();
    //  console.log(response);
      }
    );
  }

  showMoreJobDetailsnyuserid() {

    var jobsdetail_id = localStorage.getItem('jobdetail_id');
    var token = localStorage.getItem("token");

    this.http.get<any>(this.Token.baseurl() + 'api/getjobdetailsbyuserid/' + jobsdetail_id + '/' + token).subscribe(
      response => {
        this.all_job_data_by_company = response;
        //  this.isapplied = response[0].jobdata.isapplied;
  
        //  this.rest_main_image = this.Token.baseurl() + 'assets/employersilder/' + response[0].companydata[0].image1;
          this.comp_name = this.all_job_data_by_company[0].userdata[0].first_name;
          this.comp_specialty = this.all_job_data_by_company[0].companydata[0].speciality;
          this.comp_details = this.all_job_data_by_company[0].companydata[0].details;
          this.comp_type = this.all_job_data_by_company[0].companydata[0].industry_id;
          this.comp_address = this.all_job_data_by_company[0].companydata[0].address;
          this.comp_established_in = this.all_job_data_by_company[0].companydata[0].established_in;
      });
  }

}
