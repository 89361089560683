import { Component, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { FormsModule, FormGroup } from '@angular/forms';
import { FormControl, FormArray, FormBuilder, ReactiveFormsModule } from '@angular/forms';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Router } from '@angular/router';
import { TokenService } from '../../Services/token.service';
declare var $: any;

@Component({
  selector: 'app-job-post-s1',
  templateUrl: './job-post-s1.component.html',
  styleUrls: ['./job-post-s1.component.css']
})
export class JobPostS1Component implements OnInit {

  status: boolean = true;
  compensation: any;
  compensat: string = "";
  jobdetailbenifit: any;
  job_cmpnsation: any;
  selectedItem: any;
  selectedItems: any;
  job_pays: any;
  job_pay: any;
  jobs_pay: any='';
  jobs_payment: any;
  clickEvent() {
    this.status = !this.status;
  }
  url1: any = this.Token.baseurl();
  
  state_short_name:any;
  latitude = " ";
  longitude = " ";
  dropdownList;
  dropdownSettings;
  benifitdropdownLists;
  benifitdropdownSetting;

  constructor(private http: HttpClient, private Token: TokenService, private router: Router) { }

  ngOnInit(): void {
    this.getcompensation();
    this.getpostedjobbenifit();
    
    this.dropdownList = this.getPayOffer();
    this.dropdownSettings = {
      singleSelection: false,
      idField: 'item_id',
      textField: 'item_text',
      selectAllText: 'Select All',
      unSelectAllText: 'UnSelect All',
      itemsShowLimit: 5,
      allowSearchFilter: true
    };

    this.benifitdropdownLists = this.getbenifitoffer();
    this.benifitdropdownSetting = {
      singleSelection: false,
      idField: 'item_id',
      textField: 'item_text',
      selectAllText: 'Select All',
      unSelectAllText: 'UnSelect All',
      itemsShowLimit: 5,
      allowSearchFilter: true
    };
  }
  logout(Event: MouseEvent) {
    Event.preventDefault();
    this.Token.remove();
    this.router.navigateByUrl('/employer-login');
  }

  getpostedjobbenifit() {

    var jb_id = localStorage.getItem('job_id');
    if(jb_id){
    this.http.get<any>(this.Token.baseurl()+ 'api/getpostedjobbyid/' + jb_id).subscribe(
      response => {
        this.jobdetailbenifit = response;
        console.log(response);
      this.jobs_payment = this.jobdetailbenifit[0].jobdata.jobs_pay;
      console.log(this.jobs_payment);
        this.job_cmpnsation = this.jobdetailbenifit[0].jobdata.compensation_type;
        console.log(this.job_cmpnsation);
         this.selectedItem = this.jobdetailbenifit[0].jobdata.pay_offer_id;
        this.selectedItems = this.jobdetailbenifit[0].jobdata.benefit_offer_id;
      }
    );
    }
  }

  getPayOffer() {
    this.http.get<any>(this.url1 + 'api/getpay_offer').subscribe(
      response => {
        this.dropdownList = response;
      }
    );
  }
  getbenifitoffer() {
    this.http.get<any>(this.url1 + 'api/getbenefit_offer').subscribe(
      response => {
        this.benifitdropdownLists = response;
      }
    );
  }
  getcompensation() {
    this.http.get<any>(this.url1 + 'api/getcompensation_type').subscribe(
      response => {
        this.compensation = response;
        console.log(response);
        console.log(response);

      }
    );
  }
 
  onAddJobsecond(data) {

    var jid = localStorage.getItem('job_id');
    var jid1 = localStorage.getItem('grp_job_id');
    var tokens = this.Token.get();
    var s='';
    for (var dt in data.pay_offered) {
   s =  data.pay_offered[dt].item_id+'|'+s ;
     }
     var benifit='';
    for (var bfit in data.benefits_offered) {
     
      benifit =  data.benefits_offered[bfit].item_id+'|'+benifit ;
     }
    const url = this.url1 + 'api/addnewjob';
    this.http.post(url, { token: tokens, grp_job_id: jid1, job_id: jid, jobs_pay:data.jobs_pay, compensation_type:data.compensat,pay_offer_id: s, benefit_offer_id:benifit, page: 2 })
      .subscribe((result) => {
        this.ngOnInit();
        this.router.navigateByUrl('/job-post3');
      });
  }
  privacy_policy() {
    this.router.navigate(['/privacy_policy']);
  }

 agrement(){
    this.router.navigateByUrl('/agreement');
  }

faq_data() {
    this.router.navigate(['/faq_desc']);
  }
  invest_data(){
    this.router.navigate(['/invest']);
  }
  about_us(){
    this.router.navigate(['/about']);
  }
}
