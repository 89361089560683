import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-detail-job',
  templateUrl: './detail-job.component.html',
  styleUrls: ['./detail-job.component.css']
})
export class DetailJobComponent implements OnInit {
status: boolean = true;
  clickEvent(){
      this.status = !this.status;       
  }

  constructor() { }

  ngOnInit(): void {
  }

}
