import { Component, OnInit } from "@angular/core";
import { NgModule, EventEmitter, Output } from "@angular/core";
import {
  FormControl,
  FormArray,
  FormBuilder,
  ReactiveFormsModule,
} from "@angular/forms";
import { FormsModule, FormGroup } from "@angular/forms";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Router } from "@angular/router";
import { TokenService } from "../../Services/token.service";
import { MatAutocompleteModule } from "@angular/material/autocomplete";

import { Observable } from "rxjs";
import { map, startWith } from "rxjs/operators";

declare var $: any;

@Component({
  selector: "app-profile-second",
  templateUrl: "./profile-second.component.html",
  styleUrls: ["./profile-second.component.css"],
})
export class ProfileSecondComponent implements OnInit {
  @Output() getsumm: EventEmitter<any> = new EventEmitter();
  @Output() removesumm: EventEmitter<any> = new EventEmitter();

  summs: any;
  summ: string = "";

  sumred: any;
  sumr: string = "";

  summbtn1: string = "Add";

  // filtered_searchdata:any= [['id',[]],['name',[]]];

  filtered_searchdata: any = { id: [], name: [] };
  referencedataName: any = [];
  referencedataId: any = [];
  searchdata: any = [];
  dt: any = {};

  constructor(private http: HttpClient, private Token: TokenService) {}

  ngOnInit(): void {
    this.getSumm();
    this.getasumrjobtype();
  }

  summdtaDiv(summid) {
    var btn = document.getElementById("add" + summid)!.innerHTML;
    var summdata = document.getElementById("summdiv" + summid)!.innerHTML;

    if (btn == "Add") {
      this.getsumm.emit(summdata);
      document.getElementById("add" + summid)!.innerHTML = "Remove";
      btn = "Remove";
    } else {
      this.removesumm.emit(summdata);
      document.getElementById("add" + summid)!.innerHTML = "Add";
      btn = "Add";
    }
  }

  getSumm() {
    var sumjobid = localStorage.getItem("jobtype");
    this.http
      .get<any>(this.Token.baseurl() + "api/getressummary/" + sumjobid)
      .subscribe((response) => {
        this.summs = response;
      });
  }

  getasumrjobtype() {
    this.http
      .get<any>(this.Token.baseurl() + "api/getjobtype")
      .subscribe((response) => {
        this.searchdata = response;
        // for (var i = 0; i < response.length; i++) {
        //   // this.searchdata[i] = response[i]["name"];
        //   // this.referencedataId[i] = response[i]["id"];
        //   // this.referencedataName[i] = response[i]["name"];
        //   // this.filtered_searchdata.name[i] = response[i]["name"];
        //   // this.filtered_searchdata.id[i] = response[i]["id"];
        // }
        // // this.sumred = response;
      });
  }

  onJobsearchInput(data: any) {
    let res = data.target.value;
    if (
      res == "" ||
      res == " " ||
      res == "null" ||
      res == "NULL" ||
      res == null
    ) {
      // console.log(res);
      $("#searchrecommand").css("display", "none");
    } else if (
      res != "" ||
      res != " " ||
      res != "null" ||
      res != "NULL" ||
      res != null
    ) {
      let query = res.toUpperCase();

      let allListElements = $("#searchrecommand > div");

      var matchingListElements = allListElements.filter((i, label) => {
        var listItemText = $(label).text().toUpperCase();
        return ~listItemText.indexOf(query);
      });

      allListElements.css("display", "none");
      matchingListElements.css("display", "block");

      $("#searchrecommand").css("display", "block");
    }
  }

  changessumr(sumrdid, sumrdnm) {
    this.sumred = sumrdnm;
    $("#searchrecommand").css("display", "none");
    this.http
      .get<any>(this.Token.baseurl() + "api/getressummary/" + sumrdid)
      .subscribe((response) => {
        this.summs = response;
      });
  }
}
