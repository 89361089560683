import { Component, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
declare var $: any;
import { FormsModule, FormGroup } from '@angular/forms';
import { FormControl, FormArray, FormBuilder, ReactiveFormsModule } from '@angular/forms';
import { HttpClient,HttpHeaders } from '@angular/common/http';
import { TokenService } from '../../Services/token.service';
import { Router } from '@angular/router';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class HeaderComponent implements OnInit {

  isemployee:boolean=false;
  resume_token: any;
status: boolean = true;
  clickEvent(){
      this.status = !this.status;    
  }

  constructor( private http:HttpClient, private Token : TokenService, private router: Router ) { }

  ngOnInit(): void {
    this.resume_token = localStorage.getItem("resume_token");
    
    if(this.resume_token!='' && this.resume_token!=null)
    {
      this.isemployee=true;
    }
      else{
        this.isemployee=false;
      }
    this.loadCss();
  }
  dropboxarrow() {
    $(".dropbox").toggle("slow");
  }

  candidate_logout(Event: MouseEvent){
    this.Token.remove();
    localStorage.clear();
    window.location.href = '/';
  //  this.ngOnInit();
  }
  public loadCss() {
    const node = document.createElement('link');
    node.href = 'assets/digital-marketing/css/style_new.css'; // put there your js file location
    node.rel = 'stylesheet';
    node.charset = 'utf-8';
   document.getElementsByTagName('head')[0].appendChild(node);
  }
  dashboard() {
    this.router.navigateByUrl('/candidate-dashboard');
  }
  jobsearch() {
    window.location.href = '/searchjobs';
  }
  communication() {
    this.router.navigateByUrl('/communication');
  }
  aplydjobs() {
    this.router.navigateByUrl('/applied-jobs');
  }
  home_page(){
    window.location.href = '/';
   }
}
