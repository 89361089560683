import { Component, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { FormsModule, FormGroup } from '@angular/forms';
import { FormControl, FormArray, FormBuilder, ReactiveFormsModule } from '@angular/forms';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { TokenService } from '../../Services/token.service';
import { Router } from '@angular/router';
import Swal from 'sweetalert2';
import { IDropdownSettings } from 'ng-multiselect-dropdown';

@Component({
  selector: 'app-edit-jobpost2',
  templateUrl: './edit-jobpost2.component.html',
  styleUrls: ['./edit-jobpost2.component.css']
})
export class EditJobpost2Component implements OnInit {
  status: boolean = true;
  clickEvent() {
    this.status = !this.status;
  }
  url1: any = this.Token.baseurl();
  careerlv: any;
  careerl: string = "";
  degreelv: any;
  degreel: string = "";
  funcarea: any;
  funcar: string = "";
  jobshift: any;
  jobshif: string = "";
  dropdownList;
  dropdownSettings;
  selectedItem;

  jobdetailthree: any;
  c_levelid: any;
  c_shift: any;
  c_degree: any;
  c_functional: any;
  c_position: any;
  c_experience: any;
  c_freelance: any;
  hide_sal: any;

  constructor(private http: HttpClient, private Token: TokenService, private router: Router) { }

  ngOnInit(): void {
    this.getcareer();
    this.getdegree();
    this.getfuncarea();
    this.getshift();
    this.getpostedjobdetail();

    this.dropdownList = this.getTagData();
    this.dropdownSettings = {
      singleSelection: false,
      idField: 'item_id',
      textField: 'item_text',
      selectAllText: 'Select All',
      unSelectAllText: 'UnSelect All',
      itemsShowLimit: 5,
      allowSearchFilter: true
    };
    this.getpostedtag();
  }
  logout(Event: MouseEvent) {
    Event.preventDefault();
    this.Token.remove();
    this.router.navigateByUrl('/employer-login');
  }

  getpostedjobdetail() {

    var jb_id = localStorage.getItem('job_id');
    this.http.get<any>(this.url1 + 'api/getpostedjobbyid/' + jb_id).subscribe(
      response => {
        this.jobdetailthree = response;
        this.c_levelid = this.jobdetailthree[0].career_level_id;
        this.c_shift = this.jobdetailthree[0].job_shift_id;
        this.c_degree = this.jobdetailthree[0].degree_level_id;
        this.c_functional = this.jobdetailthree[0].functional_area_id;
        this.c_position = this.jobdetailthree[0].position;
        this.c_experience = this.jobdetailthree[0].experience;
        this.c_freelance = this.jobdetailthree[0].is_freelance;
        this.hide_sal = this.jobdetailthree[0].hide_salary;
      }
    );
  }

  getpostedtag() {

    var jid = localStorage.getItem('job_id');

    this.http.get<any>(this.url1 + 'api/getpostedtagjob/' + jid).subscribe(
      response => {
        this.selectedItem = response;
      }
    );
  }
  getTagData() {
    this.http.get<any>(this.url1 + 'api/getjobtags').subscribe(
      response => {
        this.dropdownList = response;
      }
    );
  }
  getcareer() {
    this.http.get<any>(this.url1 + 'api/getcareerlevel').subscribe(
      response => {
        this.careerlv = response;
      }
    );
  }
  getdegree() {
    this.http.get<any>(this.url1 + 'api/getdegreelevel').subscribe(
      response => {
        this.degreelv = response;
      }
    );
  }
  getfuncarea() {
    this.http.get<any>(this.url1 + 'api/getfunctionalarea').subscribe(
      response => {
        this.funcarea = response;
      }
    );
  }
  getshift() {
    this.http.get<any>(this.url1 + 'api/getjobshift').subscribe(
      response => {
        this.jobshift = response;
      }
    );
  }
  onEditJobthird(data) {
    var jid = localStorage.getItem('job_id');
    var tokens = this.Token.get();
    const url = this.url1 + 'api/addnewjob';
    this.http.post(url, { token: tokens, job_id: jid, career_level_id: data.career_level_id, functional_area_id: data.functional_area_id, job_shift_id: data.job_shift_id, degree_level_id: data.degree_level_id, position: data.position, hide_salary: data.hide_salary, is_freelance: data.is_freelance, experience: data.experience, page: 3 })
      .subscribe((result) => {

        this.http.get(this.url1 + 'api/deletepostedtagjob/' + jid).subscribe((result) => {
          this.ngOnInit();
        });

        this.ngOnInit();

        for (var dt in data.job_tag) {
          const urls = this.url1 + 'api/updatejobtag';
          this.http.post(urls, { tag_id: data.job_tag[dt].item_id, job_id: jid, token: tokens })
            .subscribe((result) => {
    
            });
        }
        localStorage.removeItem('job_id');
        this.router.navigateByUrl('/jobs-employer');
      });
    
 
  }

}
