import { Component, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { FormsModule, FormGroup } from '@angular/forms';
import { FormControl, FormArray, FormBuilder, ReactiveFormsModule } from '@angular/forms';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { TokenService } from '../../Services/token.service';
import { Router } from '@angular/router';
import Swal from 'sweetalert2';
declare var $: any;


@Component({
  selector: 'app-edit-jobpost1',
  templateUrl: './edit-jobpost1.component.html',
  styleUrls: ['./edit-jobpost1.component.css']
})
export class EditJobpost1Component implements OnInit {
  status: boolean = true;
  clickEvent() {
    this.status = !this.status;
  }
  url1: any = this.Token.baseurl();
  currencylist: any;
  currency: string = "";
  salarypd: any;
  salary: string = "";

  jobdetailtwo: any;
  s_form: any;
  s_to: any;
  s_pd: any;
  s_address: any;
  state_short_name: any;
  c_address: any;
  city_address: any;
  expiry_date: any;

  formattedaddress: any;
  citydata = " ";
  statedata = " ";
  latitude = " ";
  longitude = " ";
  options = {
    componentRestrictions: {
      country: ["USA"]
    }
  }
  public AddressChange(address: any) {

    this.latitude = address.geometry.location.lat();
    this.longitude = address.geometry.location.lng();

    this.formattedaddress = address.formatted_address;
    var add = this.formattedaddress.split(',');
    this.citydata = add[add.length - 3];
    this.statedata = add[add.length - 2];

    for (var i = 0; i < address.address_components.length; i++) {
      if (address.address_components[i].types[0] && 'administrative_area_level_1' === address.address_components[i].types[0]) {
        this.state_short_name = address.address_components[i].short_name;
      }
    }
    // this.statedata=this.statedata.substring(0,this.statedata.length-6);
  }


  constructor(private http: HttpClient, private Token: TokenService, private router: Router) { }

  ngOnInit(): void {
    this.loadCss();
    $('.input-grou .mat-form-field .mat-form-field-wrapper .mat-form-field-flex').addClass('pt-0');
    $('.input-grou .mat-form-field .mat-form-field-wrapper .mat-form-field-flex').css('border','1px solid black');
    $('.input-grou .mat-form-field .mat-form-field-wrapper .mat-form-field-flex').css('border-radius','5px');

    this.getcurrencylist();
    this.getsalarypd();
    this.getpostedjobdetail();
  }

  public loadCss() {
    const node = document.createElement('link');
    node.href = 'assets/digital-marketing/css/style_new.css'; // put there your js file location
    node.rel = 'stylesheet';
    node.charset = 'utf-8';
    document.getElementsByTagName('head')[0].appendChild(node);
  }

  logout(Event: MouseEvent) {
    Event.preventDefault();
    this.Token.remove();
    this.router.navigateByUrl('/employer-login');
  }

  getpostedjobdetail() {

    var jb_id = localStorage.getItem('job_id');
    this.http.get<any>(this.url1 + 'api/getpostedjobbyid/' + jb_id).subscribe(
      response => {
        this.jobdetailtwo = response;
        this.s_form = this.jobdetailtwo[0].salary_from;
        this.s_to = this.jobdetailtwo[0].salary_to;
        this.expiry_date = this.jobdetailtwo[0].job_expiry_date;
        this.currency = this.jobdetailtwo[0].currency_id;
        this.s_pd = this.jobdetailtwo[0].salary_period_id;
        this.c_address = this.jobdetailtwo[0].country_id;
        this.s_address = this.jobdetailtwo[0].state_id;
        this.state_short_name = this.jobdetailtwo[0].state_short_name;
        this.city_address = this.jobdetailtwo[0].city_id;
      });
  }

  getcurrencylist() {
    this.http.get<any>(this.url1 + 'api/getcurrencylist').subscribe(
      response => {
        this.currencylist = response;
      }
    );
  }
  getsalarypd() {
    this.http.get<any>(this.url1 + 'api/salaryperiod').subscribe(
      response => {
        this.salarypd = response;
      }
    );
  }
  onEditJobsecond(data) {

    var lati = this.latitude;
    var longi = this.longitude;
    var jid = localStorage.getItem('job_id');
    var tokens = this.Token.get();
    const url = this.url1 + 'api/addnewjob';
    this.http.post(url, { token: tokens, job_id: jid, country_id: data.country_id, state_id: data.state_id, state_short_name: data.state_short_name, city_id: data.city_id, salary_from: data.salary_from, salary_to: data.salary_to, job_expiry_date: data.job_expiry_date, late: lati, long: longi, currency_id: data.currency_id, salary_period_id: data.salary_period_id, page: 2 })
      .subscribe((result) => {
        this.ngOnInit();
      });
  }

}