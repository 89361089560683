import { Component, OnInit, NgZone } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { TokenService } from '../../Services/token.service';
import { Router } from '@angular/router';
import { NgModule, EventEmitter, Output } from '@angular/core';
import {NgbModal, ModalDismissReasons} from '@ng-bootstrap/ng-bootstrap';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-employer-demo',
  templateUrl: './employer-demo.component.html',
  styleUrls: ['./employer-demo.component.css']
})
export class EmployerDemoComponent implements OnInit {

  status: boolean = true;
  clickEvent() {
    this.status = !this.status;
  }

  employer_profile() {
    this.router.navigate(['/edit-employer']);
  }
  employer_dashboard() {
    this.router.navigate(['/employer-dashboard']);
  }
  employer_billing() {
    this.router.navigate(['/employer-billing']);
  }
  privacy_policy() {
    this.router.navigate(['/privacy_policy']);
  }
  employer_communication() {
    this.router.navigate(['/communication']);
  }
  employer_data_management() {
    this.router.navigate(['/employer_communication']);
  }
  employer_manage_jobs() {
    this.router.navigate(['/jobs-employer']);
  }
  profile() {
    this.router.navigateByUrl('/profile-builder');
  }
  communication() {
    this.router.navigateByUrl('/communication');
  }
  employees() {
    this.router.navigateByUrl('/candidate-login');
  }
  jobsearch() {
    window.location.href = '/searchjobs';
  }
  blogs() {
    this.router.navigate(['/blog']);
  }
  candidate_logout(Event: MouseEvent) {
    this.Token.remove();
    localStorage.clear();
    this.router.navigateByUrl('');
    this.ngOnInit();
  }
  employer_logout(Event: MouseEvent) {
    this.Token.remove();
    localStorage.clear();
    this.router.navigateByUrl('');
    this.ngOnInit();
  }
  employers() {
    this.router.navigate(['/employer-login']);
  }
  demo() {
    this.router.navigate(['/employer-demo']);
  }
  employer_signup() {
    this.router.navigate(['/employer-signup']);
  }
  faq_data() {
    this.router.navigate(['/faq_desc']);
  }
  agrement(){
    this.router.navigateByUrl('/agreement');
  }
  dashboard() {
    this.router.navigateByUrl('/candidate-dashboard');
  }

  resume_token: any;
  testtoken: any;
  isemployee: boolean = false;
  isemployeer: boolean = false;
  islog: any;

  url1: any = '';
  scheduled: any;
  constructor( private modalService: NgbModal, private http:HttpClient, private Token : TokenService, private router: Router, private ngZone: NgZone ) { }

  ngOnInit(): void {
    this.url1 = this.Token.baseurl();

    this.resume_token = localStorage.getItem("resume_token");
    this.testtoken = localStorage.getItem("token");
    if (this.resume_token != '' && this.resume_token != null) {
      this.isemployee = true;
      this.isemployeer = false;
      this.islog = false;

    }
    else {
      if (this.testtoken != '' && this.testtoken != null) {
        this.isemployee = false;
        this.isemployeer = true;
        this.islog = false;
        // console.log(this.islog + '2');
      }
      else {
        this.islog = true;
        this.isemployee = false;
        this.isemployeer = false;
        // console.log(this.islog + '3');
      }
    }
  }
  

  onscheduledemo(data){
    const url = this.url1 + 'api/scheduledemos';
    this.http.post(url, { full_name: data.full_name, business:data.business, title: data.title, phonenumber: data.phonenumber, email: data.email, companyaddress: data.companyaddress, url: data.url })
      .subscribe((result) => {
        this.scheduled = result;
        if(this.scheduled.status == false)
        { 
          alert(this.scheduled.message);
      } 
        else {
        var txt='Demo will be Scheduled soon';
        this.alertWithSuccess(txt);
        this.ngOnInit();
        }
      });
  }
  
  alertWithSuccess(txt) {
    Swal.fire('Thank you...', txt, 'success')
  }
  home_page(){
    window.location.href = '/';
   }
   invest_data(){
    this.router.navigate(['/invest']);
  }
  about_us(){
    this.router.navigate(['/about']);
  }
}
