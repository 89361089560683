import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { TokenService } from '../Services/token.service';
import { HttpClient } from '@angular/common/http';

declare var $: any;

@Component({
  selector: 'app-blogs',
  templateUrl: './blogs.component.html',
  styleUrls: ['./blogs.component.css']
})
export class BlogsComponent implements OnInit {

  constructor(public httpclient: HttpClient, private router: Router, private Token: TokenService ) { }

  ngOnInit(): void {

    $(".loader-area").css("display", "none");
  }

}
