import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-profile-seventh',
  templateUrl: './profile-seventh.component.html',
  styleUrls: ['./profile-seventh.component.css']
})
export class ProfileSeventhComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
