import {
  Component,
  OnInit,
  ViewEncapsulation,
  Renderer2,
  NgZone,
} from "@angular/core";
import { FormControl } from "@angular/forms";
import { Observable } from "rxjs";
import { map, startWith } from "rxjs/operators";
import {
  Router,
  Event as RouterEvent,
  NavigationStart,
  NavigationEnd,
  NavigationCancel,
  NavigationError,
  ActivatedRoute,
} from "@angular/router";
import { HttpClient } from "@angular/common/http";
import { TokenService } from "../Services/token.service";
import Swal from "sweetalert2";
import * as tt from "@tomtom-international/web-sdk-maps";
import { DomSanitizer, SafeResourceUrl } from "@angular/platform-browser";
import * as turf from "@turf/turf";

// import "../hospy_map_style";

declare var $: any;

export interface SearchReference {
  id: Number;
  name: string;
}

@Component({
  selector: "app-searchmap",
  templateUrl: "./searchmap.component.html",
  styleUrls: ["./searchmap.component.css"],
  encapsulation: ViewEncapsulation.None,
})
export class SearchmapComponent implements OnInit {
  job_search_control = new FormControl();

  loading = false;

  filtered_searchdata: any = [];

  map: any;
  statecenterlat: any;
  statecenterlng: any;
  citycenterlat: any;
  citycenterlng: any;

  stateorcitycenterlat: any;
  stateorcitycenterlnt: any;

  marker: any;
  userData: any;
  userData1: any;
  mapCenterLatLngData: any;
  state: any;
  city: any;

  find_search_jobs_data: any = null;
  find_search_jobs_address: any = null;
  find_search_jobs_city: any = null;
  find_search_jobs_state: any = null;

  rest_main_image: any;
  comp_name: any;
  comp_details: any;
  comp_specialty: any;
  comp_type: any;
  comp_address: any;
  comp_lat: any;
  comp_lng: any;
  comp_embd_url: any;
  comp_established_in: any;

  isapplied: any;

  job_id: any;
  job_title: any;
  job_type_name: any;
  job_contract_type: any;
  description: any;
  required_degree_level_name: any;
  functional_area_name: any;
  experience: any;
  industrie: any;
  level_name: any;
  period: any;
  position: any;
  salary_from: any;
  salary_to: any;
  currency_name: any;
  shift: any;
  details: any;
  size: any;
  totalapplication: any;
  location: any;
  fax: any;
  website: any;

  all_job_data: any;
  all_job_data_by_company: any;

  jobs_near_me_range: any;

  // filter_job_roles: any;
  filter_job_types: any;
  filter_salary: any;
  filter_work_schedule: any;
  filter_compensation: any;
  // filter_career_level: any;

  job_type: any;
  salary_type: any;
  job_shift: any;
  compensation: any;

  toggle_state: boolean = false;

  all_filter_job_data: any = [];
  filter_job_type_data: any = [];
  filter_salary_data: any = [];
  filter_job_shift_data: any = [];
  // filter_work_schedule_data: any = "";
  filter_compensation_data: any = [];
  // filter_career_level_data: any = "";

  searchdata: any = [];
  filter_search_jobs_data: any;
  // filter_search_jobs_control = new FormControl();
  filteredOptions = new Observable<SearchReference[]>();

  resume_token: any;

  HTMLButtonElement: any;

  jobsearchmanual: any;
  jobsearchdata: any;
  jobsearchaddress: any;
  jobsearchcity: any;
  mapsearch_state: any;

  public form = {
    email: null,
    password: null,
  };
  public error = null;

  hero = {
    first_name: "",
    last_name: "",
    phone: "",
    email: "",
    password: "",
    confirmpwd: "",
  };

  jobtypes: any;
  depend: any;
  workas: any;
  iworkas: any;
  workin: any;
  iworkin: any;
  pro_address: any;
  pindata: any;
  citydata: any;
  statedata: any;
  issrc: any;
  options = {
    componentRestrictions: {
      country: ["USA"],
    },
  };
  tok: any;
  rest_image: any;

  constructor(
    private ngZone: NgZone,
    private renderer: Renderer2,
    public httpclient: HttpClient,
    private Activatedroute: ActivatedRoute,
    private router: Router,
    public sanitizer: DomSanitizer,
    private Token: TokenService
  ) {
    this.router.events.subscribe((e: RouterEvent) => {
      this.navigationInterceptor(e);
    });
  }

  navigationInterceptor(event: RouterEvent): void {
    if (event instanceof NavigationStart) {
      this.loading = true;
    }
    if (event instanceof NavigationEnd) {
      this.loading = false;
    }

    // Set loading state to false in both of the below events to hide the spinner in case a request fails
    if (event instanceof NavigationCancel) {
      this.loading = false;
    }
    if (event instanceof NavigationError) {
      this.loading = false;
    }
  }
  isemployee: boolean = false;
  isemployeer: boolean = false;
  islog: any;
  testtoken: any;
  ngOnInit(): void {
    this.resume_token = localStorage.getItem("resume_token");
    this.testtoken = localStorage.getItem("token");
    if (this.resume_token != "" && this.resume_token != null) {
      this.isemployee = true;
      this.isemployeer = false;
      this.islog = false;
    } else {
      if (this.testtoken != "" && this.testtoken != null) {
        this.isemployee = false;
        this.isemployeer = true;
        this.islog = false;
      } else {
        this.islog = true;
        this.isemployee = false;
        this.isemployeer = false;
      }
    }

    window["angularComponentReference"] = {
      component: this,
      zone: this.ngZone,
      loadAngularFunction: (uid) => this.showMoreJobDetailsnyuserid(uid),
    };
    window["angularComponentReference1"] = {
      component: this,
      zone: this.ngZone,
      loadAngularFunction1: (city) => this.callcityJsonDataFunc(city),
    };
    this.loadCss();
    this.filters();
    this.getjobtype();
    this.getasumrjobtype();

    this.resume_token = localStorage.getItem("resume_token");

    this.jobs_near_me_range = 20;

    this.Activatedroute.queryParamMap.subscribe((params) => {
      if (params.get("jobsearchmanual") != "TRUE") {
        this.citycenterlat = params.get("lat");
        this.citycenterlng = params.get("long");
        this.filter_search_jobs();
        if (params.get("state")) {
          this.httpclient
            .get(
              this.Token.baseurl() +
                "api/getstatelatlong/" +
                localStorage.getItem("mapsearch_state")
            )
            .subscribe((res) => {
              this.citycenterlat = res[0].state_lat;
              this.citycenterlng = res[0].state_long;
              this.issrc = 0;
              this.filter_search_jobs();
            });

          this.state = params.get("state");
          localStorage.setItem("mapsearch_state", this.state);

          var token = localStorage.getItem("resume_token");
          if (token) {
            this.httpclient
              .get<any>(this.Token.baseurl() + "api/getresumeprofile/" + token)
              .subscribe((response: any) => {
                this.router.navigateByUrl(
                  "/searchmap?city=" + response[0]["city"]
                );
                this.ngOnInit();
              });
          }
        }
      } else {
        this.citycenterlat = params.get("lat");
        this.citycenterlng = params.get("long");
        this.issrc = 1;
        if (params.get("city")) {
          this.city = params.get("city");
        }

        if (params.get("jobsearchmanual")) {
          this.jobsearchmanual = params.get("jobsearchmanual");
        }
        if (params.get("jobsearchdata")) {
          this.jobsearchdata = params.get("jobsearchdata");
        }
        if (params.get("jobsearchaddress")) {
          this.jobsearchaddress = params.get("jobsearchaddress");
        }
        if (params.get("jobsearchcity")) {
          this.jobsearchcity = params.get("jobsearchcity");
        }
        if (params.get("mapsearch_state")) {
          this.mapsearch_state = params.get("mapsearch_state");
        }
        this.filter_search_jobs();
      }

      if (params.get("city")) {
        this.city = params.get("city");
      }

      if (params.get("jobsearchmanual")) {
        this.jobsearchmanual = params.get("jobsearchmanual");
      }
      if (params.get("jobsearchdata")) {
        this.jobsearchdata = params.get("jobsearchdata");
      }
      if (params.get("jobsearchaddress")) {
        this.jobsearchaddress = params.get("jobsearchaddress");
      }
      if (params.get("jobsearchcity")) {
        this.jobsearchcity = params.get("jobsearchcity");
      }
      if (params.get("mapsearch_state")) {
        this.mapsearch_state = params.get("mapsearch_state");
      }
    });

    /*  if (this.city != null) {
       this.httpclient.get(this.Token.baseurl() + 'api/getcitylatlong/' + localStorage.getItem('mapsearch_state') + '/' + this.city).subscribe((res) => {
         this.citycenterlat = res[0].lat;
         this.citycenterlng = res[0].lng;
 
         this.getcityJsonData(this.city, this.citycenterlat, this.citycenterlng);
       });
 
       this.httpclient.get(this.Token.baseurl() + 'api/getpostedjobfullbycity/' + this.city).subscribe((res) => {
         this.all_job_data = res;
       });
     } else {
       // this.getStateJsonData();
 
       // var jobsearchmanual = localStorage.getItem("jobsearchmanual");
       // var jobsearchdata = localStorage.getItem("jobsearchdata");
       // var jobsearchaddress = localStorage.getItem("jobsearchaddress");
       // var jobsearchcity = localStorage.getItem("jobsearchcity");
       // var mapsearch_state = localStorage.getItem("mapsearch_state");
 
       // if(this.jobsearchmanual){
       //   if(this.jobsearchmanual == "TRUE"){
       //     if(this.jobsearchcity == 'null'){
       //       this.httpclient.post(this.Token.baseurl() + 'api/getpostedjobfullbyfiltercity', { src: this.jobsearchdata, address: this.jobsearchaddress, city: this.jobsearchcity, state: this.mapsearch_state }).subscribe(
       //         (response: any) => {
       //           this.all_job_data = response;
       //         });
       //     }
       //     else{
       //         this.httpclient.get(this.Token.baseurl() + 'api/getcitylatlong/' + this.mapsearch_state + '/' + this.jobsearchcity).subscribe((res) => {
       //           this.citycenterlat = res[0].lat;
       //           this.citycenterlng = res[0].lng;
 
       //           this.getcityJsonData(this.city, this.citycenterlat, this.citycenterlng);
       //         });
 
       //         this.httpclient.get(this.Token.baseurl() + 'api/getpostedjobfullbycity/' + this.jobsearchcity).subscribe((res) => {
       //           this.all_job_data = res;
 
       //         });
       //       }
       //   }
       // } else{
       //   this.httpclient.get(this.Token.baseurl() + 'api/getpostedjobfullbystate/' + localStorage.getItem('mapsearch_state')).subscribe((res) => {
       //     this.all_job_data = res;
       //   });
       // }
     } */

    $(".loader-area").css("display", "none");
  }

  /*   getStateJsonData() {
      this.httpclient.get(this.Token.baseurl() + 'api/getstatelatlong/' + localStorage.getItem('mapsearch_state')).subscribe((res) => {
        this.statecenterlat = res[0].state_lat;
        this.statecenterlng = res[0].state_long;
  
        this.map = tt.map({
          key: 'G6pHCMGdQI4vGbUSdnvF54cKNkbLMqMC',
          container: 'map',
          //style: 'tomtom://vector/1/basic-main',
          zoom: 5,
          center: [this.statecenterlng, this.statecenterlat]
        });
  
        this.map.addControl(new tt.FullscreenControl());
        this.map.addControl(new tt.NavigationControl());
      });
  
  
      this.httpclient.get(this.Token.baseurl() + 'api/getstatecitylatlong/' + localStorage.getItem('mapsearch_state')).subscribe((res) => {
        this.userData1 = res;
        for (var i = 0; i < this.userData1.length; i++) {
          var markerElement = document.createElement('div');
          markerElement.className = 'marker';
          var markerContentElement = document.createElement('div');
  
          markerContentElement.style.backgroundColor = '#FFFFF';
          markerElement.appendChild(markerContentElement);
          var iconElement = document.createElement('div');
          iconElement.className = 'marker-icon';
          iconElement.style.backgroundImage = 'url(assets/digital-marketing/img/icon_rest.png)';
  
          // iconElement.addEventListener('click', function (e) { 
          //   localStorage.setItem('city_level','1');
          //   this.router.navigateByUrl('/employer-login');
          // });
          // iconElement.setAttribute('onclick', 'getcitydata("' + this.userData1[i].City + '")');
  
          markerContentElement.appendChild(iconElement);
          this.marker = new tt.Marker({
            draggable: false,
            element: markerElement,
            // anchor: 'bottom'
          })
            .setLngLat([this.userData1[i].lng, this.userData1[i].lat])
            .addTo(this.map);
          var boxText = document.createElement("div");
          var element = "<b>City: " + this.userData1[i].City + "</b><br/> <b>Total Jobs: " + this.userData1[i].totaljob + "</b><br/>";
  
          var viewallbtn = document.createElement("button");
          viewallbtn.setAttribute('class', 'btn btn-slider yellow-btn rounded-pill py-1');
          viewallbtn.setAttribute('onclick', 'getcitydata("' + this.userData1[i].City + '")');
          viewallbtn.innerHTML = "View All";
  
          boxText.innerHTML = element;
          boxText.appendChild(viewallbtn);
  
          var popup = new tt.Popup().setHTML(boxText.innerHTML);
          this.marker.setPopup(popup);
  
        }
      });
    } */

  callcityJsonDataFunc(city) {
    this.router.navigateByUrl("/searchmap?city=" + city).then((res) => {
      this.ngOnInit();
    });
  }

  dashboard() {
    var token = localStorage.getItem("token");
    if (token) {
      location.href = "/candidate-dashboard";
      //this.router.navigateByUrl('/candidate-dashboard');
    } else {
      location.href = "/";
    }
  }
  home_page(){
    window.location.href = '/';
   }
  /*  getcityJsonData(city, lat, lng) {
 
     this.map = tt.map({
       key: 'G6pHCMGdQI4vGbUSdnvF54cKNkbLMqMC',
       container: 'map',
       //style: 'tomtom://vector/1/basic-main',
       zoom: 9,
       center: [lng, lat]
     });
 
     this.map.addControl(new tt.FullscreenControl());
     this.map.addControl(new tt.NavigationControl());
 
     this.httpclient.get(this.Token.baseurl() + 'api/getalljobcitywise/' + city).subscribe((res) => {
       this.userData = res;
       for (var i = 0; i < this.userData.length; i++) {
         var markerElement = document.createElement('div');
         markerElement.className = 'marker';
         var markerContentElement = document.createElement('div');
 
         markerContentElement.style.backgroundColor = '#FFFFF';
         markerElement.appendChild(markerContentElement);
         var iconElement = document.createElement('div');
         iconElement.className = 'marker-icon';
         iconElement.style.backgroundImage = 'url(assets/digital-marketing/img/icon.png)';
 
         // iconElement.setAttribute('onclick', 'getangfun("' + this.userData[i].user_id + '")');
 
         markerContentElement.appendChild(iconElement);
         this.marker = new tt.Marker({
           draggable: false,
           element: markerElement,
           // anchor: 'bottom'
         })
           .setLngLat([this.userData[i].lng, this.userData[i].lat])
           .addTo(this.map);
 
 
         var element = "<b>Company: " + this.userData[i].company + "</b><br/> <b>Total Jobs: " + this.userData[i].totaljob + "</b><br/>";
 
         var div = document.createElement("div");
         var btn = document.createElement("button");
         btn.innerText = 'show more';
         btn.setAttribute('type', 'button');
         btn.setAttribute('class', 'btn btn-slider yellow-btn rounded-pill');
 
         div.innerHTML = element;
 
 
         btn.setAttribute('onclick', 'getangfun("' + this.userData[i].user_id + '")');
         div.appendChild(btn);
         var popup = new tt.Popup().setHTML(div.innerHTML);
         this.marker.setPopup(popup);
       }
     });
   } */

  applynow(job_id: any) {
    var tkn = localStorage.getItem("token");
    var rt = localStorage.getItem("resume_token");
    var ji = job_id;
    var es = " ";
    var nt = " ";

    localStorage.setItem("job_id", job_id);

    if (tkn != null || tkn == "") {
      this.httpclient
        .post(this.Token.baseurl() + "api/addjobapplication", {
          token: tkn,
          resume_token: rt,
          job_id: ji,
          expected_salary: es,
          notes: nt,
        })
        .subscribe((res: any) => {
          if (res.status === true) {
            Swal.fire({
              title: "Success!",
              text: "You have successfully applied for this job!",
              icon: "success",
              allowOutsideClick: false,
              confirmButtonText: "Done",
            }).then((result) => {
              if (result.isConfirmed) {
                jQuery.noConflict();
                $("#xlarge").modal("hide");
              }
            });
          } else {
            Swal.fire({
              title: "Error!",
              text: "Something went wrong!",
              icon: "error",
              allowOutsideClick: false,
              confirmButtonText: "Retry",
            });
          }
        });
    } else {
      jQuery.noConflict();
      $("#loginmodal").modal({ backdrop: "static", keyboard: false });
      $("#loginmodal").modal("show");
      $(".modal-backdrop").css("zIndex", "101");
    }
  }

  /*************** Employer Login ***************/
  employerlogin() {
    return this.httpclient
      .post(this.Token.baseurl() + "api/employeelogin", this.form)
      .subscribe(
        (data) => this.handleResponse(data)
        // (error) => console.log(error)
      );
  }

  handleResponse(data) {
    this.Token.handle(data.token);
    localStorage.setItem("token", data.token);
    localStorage.setItem("resume_token", data.token);
    this.hideLoginModal();
  }

  handleError(error) {
    this.error = error.error.error;
  }

  hideLoginModal() {
    jQuery.noConflict();
    $("#loginmodal").modal("hide");
    $(".modal-backdrop").css("zIndex", "100");
  }

  /*************** Employer Signup ***************/

  getjobtype() {
    this.httpclient
      .get<any>(this.Token.baseurl() + "api/getjobtype")
      .subscribe((response) => {
        this.jobtypes = response;
      });
  }

  dependWorkas(event) {
    const dependVal = event.target.value;
    this.httpclient
      .get<any>(this.Token.baseurl() + "api/getsubjobtype/" + dependVal)
      .subscribe((response) => {
        this.depend = response;
      });
  }

  showemySignupModal() {
    // jQuery.noConflict();
    // $('#signupmodal').modal({ backdrop: 'static', keyboard: false });
    // $('#signupmodal').modal('show');
    // $('.modal-backdrop').css('zIndex', '102');

    $("#xlarge").modal("hide");
    $("#loginmodal").modal("hide");
    this.router.navigateByUrl("/register-candidate");
  }

  AddressChange(address: any) {
    this.pro_address = address.formatted_address;

    for (const component of address.address_components) {
      const componentType = component.types[0];
      switch (componentType) {
        case "street_number": {
          break;
        }

        case "route": {
          break;
        }

        case "postal_code": {
          this.pindata = `${component.long_name}`;
          break;
        }

        case "postal_code_suffix": {
          this.pindata = `${this.pindata}-${component.long_name}`;
          break;
        }

        case "locality": {
          this.citydata = component.long_name;
          break;
        }

        case "administrative_area_level_1": {
          this.statedata = component.short_name;
          break;
        }

        case "country": {
          break;
        }
      }
    }
  }

  employersignup(data) {
    var uptoken = localStorage.getItem("resume_token");
    var token = localStorage.getItem("token");
    if (token == null) {
      token = "none";
    }

    const url = this.Token.baseurl() + "api/addprofiledetail";
    this.httpclient
      .post(url, {
        resume_token: uptoken,
        work_as: data.work_as,
        work_in: data.work_in,
        f_name: data.f_name,
        l_name: data.l_name,
        location: this.pro_address,
        city: this.citydata,
        state: this.statedata,
        zip: this.pindata,
        email: data.email,
        phone: data.phone,
        whatsapp: "",
        facebook: "",
        user_id: token,
        ip: 1,
      })
      .subscribe((result) => {
        this.tok = result;
        localStorage.setItem("resume_token", this.tok.resume_token);

        if (token == "none") {
          localStorage.setItem("token", this.tok.token);
        }

        localStorage.setItem("workas", data.work_as);
        $("#signupmodal").modal("hide");
        $("#loginmodal").modal("hide");
        $(".modal-backdrop").css("zIndex", "100");
      });
  }

  handleResponse1(data) {
    this.Token.handle(data.token);
    // this.router.navigateByUrl('/candidate-dashboard');
  }

  hidemySignupModal() {
    jQuery.noConflict();
    $("#signupmodal").modal("hide");
    $(".modal-backdrop").css("zIndex", "101");
  }

  showMoreJobDetailsnyuserid(jobid: any) {
    jQuery.noConflict();
    $("#xlarge").modal("show");

    var token = localStorage.getItem("token");
    var demo_img = this.Token.baseurl() + "assets/employersilder/" + 'demo.png';

    this.httpclient
      .get<any>(
        this.Token.baseurl() +
          "api/getjobdetailsbyjobid/" +
          jobid +
          "/" +
          token
      )
      .subscribe((response) => {
        this.isapplied = response[0].jobdata.isapplied;

        // this.rest_main_image =
        //   this.Token.baseurl() +
        //   "assets/employersilder/" +
        //   response[0].companydata[0].image1;

          if (response[0].companydata[0].image1 != '') {
            this.rest_main_image = this.Token.baseurl() + "assets/employersilder/" + response[0].companydata[0].image1;
          } else {
            this.rest_main_image = demo_img;
          }

        this.comp_name = response[0].userdata[0].first_name;
        this.comp_specialty = response[0].companydata[0].speciality;
        this.comp_details = response[0].companydata[0].details;
        this.comp_type = response[0].jobdata.industry_id;
        this.comp_address = response[0].jobdata.job_location;

        // this.comp_lat = response[0].companydata[0].lat;  Change this after Add Multiple Loc Func 10/04/2022
        // this.comp_lng = response[0].companydata[0].lng; Change this after Add Multiple Loc Func 10/04/2022

        this.comp_lat = response[0].jobdata.job_late;
        this.comp_lng = response[0].jobdata.job_long;

        this.comp_embd_url = this.sanitizer.bypassSecurityTrustResourceUrl(
          "https://maps.google.com/maps/embed/v1/place?key=AIzaSyDL5VMrQ9M0XPXz-RqNrPGXxNtTRIjyhys&q=" +
            this.comp_address
              .replace(" ", "+")
              .replace(" ", "+")
              .replace(" ", "+")
              .replace(" ", "+")
              .replace(" ", "+")
              .replace(" ", "+")
              .replace(" ", "+")
              .replace(" ", "+")
              .replace(",", "")
              .replace(",", "")
              .replace(",", "")
        );

        // $("#glembdmap").attr("src", "");

        this.comp_established_in = response[0].companydata[0].established_in;

        this.all_job_data_by_company = response;
      });
  }
  showMoreJobDetails1(jobid: any) {
    $('#right'+jobid).css('background-image','url(assets/digital-marketing/img/icon.png)');
    // $("#xlarge").modal("show");
    $('#right'+jobid).css('background-image','url(assets/digital-marketing/img/locatio.png)');
  }
  showMoreJobDetails2(jobid: any) {
    // $("#xlarge").modal("show");
    $('#right'+jobid).css('background-image','url(assets/digital-marketing/img/icon.png)');
  }
  showMoreJobDetails(jobid: any) {
    jQuery.noConflict();
    $("#xlarge").modal("show");
    $('#right'+jobid).css('border','2px solid #ffc107');
   
    var token = localStorage.getItem("token");

    // this.httpclient
    //   .get<any>(this.Token.baseurl() + "api/addtotalviewjob/" + jobid)
    //   .subscribe();

    this.httpclient
      .get<any>(
        this.Token.baseurl() + "api/getjobdetailsbyjobid/" + jobid + "/" + token
      )
      .subscribe((response) => {
        this.isapplied = response[0].jobdata.isapplied;

        console.log(response);

          if (response[0].companydata[0].image1 != '') {
            // this.rest_main_image = this.Token.baseurl() + "assets/employersilder/" + response[0].companydata[0].image1;
            this.rest_main_image =
          this.Token.baseurl() +
          "assets/employersilder/" +
          response[0].companydata[0].image1;
          } else {
            this.rest_main_image = this.Token.baseurl() + "assets/employersilder/" + 'demo.png';
          }


        this.comp_name = response[0].userdata[0].first_name;
        this.comp_specialty = response[0].companydata[0].speciality;
        this.comp_details = response[0].companydata[0].details;
        this.comp_type = response[0].jobdata.industry_id;
       // this.comp_address = response[0].companydata[0].address; Change this after Add Multiple Loc Func 10/04/2022
       this.comp_address = response[0].jobdata.job_location;

        // this.comp_lat = response[0].companydata[0].lat;  Change this after Add Multiple Loc Func 10/04/2022
        // this.comp_lng = response[0].companydata[0].lng;  Change this after Add Multiple Loc Func 10/04/2022
        this.comp_lat = response[0].jobdata.job_late;
        this.comp_lng = response[0].jobdata.job_long;
        this.comp_embd_url = this.sanitizer.bypassSecurityTrustResourceUrl(
          "https://maps.google.com/maps/embed/v1/place?key=AIzaSyDL5VMrQ9M0XPXz-RqNrPGXxNtTRIjyhys&q=" +
            this.comp_address
              .replace(" ", "+")
              .replace(" ", "+")
              .replace(" ", "+")
              .replace(" ", "+")
              .replace(" ", "+")
              .replace(" ", "+")
              .replace(" ", "+")
              .replace(" ", "+")
              .replace(",", "")
              .replace(",", "")
              .replace(",", "")
        );

        // $("#glembdmap").attr("src", "");

        this.comp_established_in = response[0].companydata[0].established_in;

        this.all_job_data_by_company = response;
       // console.log(this.all_job_data_by_company);
      });
  }

  hideMoreJobDetails() {
    jQuery.noConflict();
    $("#xlarge").modal("hide");
  }

  public loadCss() {
    const node = document.createElement("link");
    node.href = "assets/digital-marketing/css/style-map.css"; // put there your js file location
    node.rel = "stylesheet";
    node.charset = "utf-8";
    document.getElementsByTagName("head")[0].appendChild(node);
  }

  filters() {
    // this.httpclient
    //   .get(this.Token.baseurl() + "api/getjobtype")
    //   .subscribe((res) => {
    //     this.filter_job_roles = res;
    //   });

    this.httpclient
      .get(this.Token.baseurl() + "api/employercategory")
      .subscribe((res) => {
        this.filter_job_types = res;
      });

    this.httpclient
      .get(this.Token.baseurl() + "api/salaryperiod")
      .subscribe((res) => {
        this.filter_salary = res;
      });

    this.httpclient
      .get(this.Token.baseurl() + "api/getdesired_work_schedule")
      .subscribe((res) => {
        this.filter_work_schedule = res;
      });

    this.httpclient
      .get(this.Token.baseurl() + "api/getcompensation_type")
      .subscribe((res) => {
        this.filter_compensation = res;
      });

    // this.httpclient
    //   .get(this.Token.baseurl() + "api/getcareerlevel")
    //   .subscribe((res) => {
    //     this.filter_career_level = res;
    //   });
  }

  filterjobsetdata(type, id) {
    if (type == "job_type") {
      var job_type = this.filter_job_type_data;
      var last_index = job_type.length;

      for (var i = 0; i <= job_type.length; i++) {
        if (job_type[i] == id) {
          job_type.splice(i, 1);
          break;
        } else {
          if (i == last_index) {
            job_type[last_index] = id;
          }
        }
      }

      for (var i = 0; i < job_type.length; i++) {
        this.filter_job_type_data[i] = job_type[i];
      }
    } else if (type == "salary_type") {
      var salary_type = this.filter_salary_data;
      var last_index = salary_type.length;

      for (var i = 0; i <= salary_type.length; i++) {
        if (salary_type[i] == id) {
          salary_type.splice(i, 1);
          break;
        } else {
          if (i == last_index) {
            salary_type[last_index] = id;
          }
        }
      }

      for (var i = 0; i < salary_type.length; i++) {
        this.filter_salary_data[i] = salary_type[i];
      }
    } else if (type == "work_schedule") {
      var job_shift = this.filter_job_shift_data;
      var last_index = job_shift.length;

      for (var i = 0; i <= job_shift.length; i++) {
        if (job_shift[i] == id) {
          job_shift.splice(i, 1);
          break;
        } else {
          if (i == last_index) {
            job_shift[last_index] = id;
          }
        }
      }

      for (var i = 0; i < job_shift.length; i++) {
        this.filter_job_shift_data[i] = job_shift[i];
      }
    } else if (type == "compensation") {
      var compensation = this.filter_compensation_data;
      var last_index = compensation.length;

      for (var i = 0; i <= compensation.length; i++) {
        if (compensation[i] == id) {
          compensation.splice(i, 1);
          break;
        } else {
          if (i == last_index) {
            compensation[last_index] = id;
          }
        }
      }

      for (var i = 0; i < compensation.length; i++) {
        this.filter_compensation_data[i] = compensation[i];
      }
    }
  }

  filterjobsearchpostdata(type) {
    this.job_type = this.filter_job_type_data.join("|");
    this.salary_type = this.filter_salary_data.join("|");
    this.job_shift = this.filter_job_shift_data.join("|");
    this.compensation = this.filter_compensation_data.join("|");

    this.filter_search_jobs();
  }

  searchdropboxarrow(data) {
    var n;
    for (n = 1; n <= 7; n++) {
      if (data === n) {
        if (this.toggle_state == true) {
          this.toggle_state = false;
          $(".searchdropbox" + n).css("display", "none");
        } else if (this.toggle_state == false) {
          this.toggle_state = true;
          $(".searchdropbox" + n).css("display", "block");
        }
      }
    }
  }

  onJobsearchInput(data: any) {
    let res = data.target.value;
    if (
      res == "" ||
      res == " " ||
      res == "null" ||
      res == "NULL" ||
      res == null
    ) {
      $("#searchrecommand").css("display", "none");
    } else if (
      res != "" ||
      res != " " ||
      res != "null" ||
      res != "NULL" ||
      res != null
    ) {
      let query = res.toLowerCase();
      this.find_search_jobs_data = res;
      this.filtered_searchdata = this.searchdata.filter(
        (item) => item.toLowerCase().indexOf(query) >= 0
      );
      $("#searchrecommand").css("display", "block");
    }
  }

  getasumrjobtype() {
    this.httpclient
      .get<any>(this.Token.baseurl() + "api/getjobtype")
      .subscribe((response: any) => {
        for (var i = 0; i < response.length; i++) {
          this.searchdata[i] = response[i]["name"];
          this.filtered_searchdata[i] = response[i]["name"];
        }
      });

    // this.filteredOptions = this.filter_search_jobs_control.valueChanges
    //   .pipe(
    //     startWith(''),
    //     map(value => typeof value === 'string' ? value : value.name),
    //     map(name => name ? this._filter(name) : this.searchdata.slice())
    //   );
  }

  fill_find_search_jobs(data) {
    this.find_search_jobs_data = data;
    $("#searchrecommand").css("display", "none");
  }

  displayFn(user: SearchReference): string {
    return user && user.name ? user.name : "";
  }

  _filter(value: string): string[] {
    const filterValue = value.toLowerCase();

    return this.searchdata.filter((option) =>
      option.toLowerCase().includes(filterValue)
    );
  }

  fill_filter_search_jobs(id) {
    this.filter_search_jobs_data = id;
  }

  filter_search_jobs() {
    this.httpclient
      .post(this.Token.baseurl() + "api/getalljobfilter", {
        lat: this.citycenterlat,
        lng: this.citycenterlng,
        issrc: this.issrc,
        srcdata: this.jobsearchdata,
        state: this.state,
        city: this.city,
        address: this.jobsearchaddress,
        job_type: this.job_type,
        salary_type: this.salary_type,
        job_shift: this.job_shift,
        compensation: this.compensation,
      })
      .subscribe((response: any) => {
        this.userData = response;

        if (this.jobsearchmanual != "TRUE") {
          if (this.state) {
            this.map = tt.map({
              key: "G6pHCMGdQI4vGbUSdnvF54cKNkbLMqMC",
              container: "map",
              style: "assets/hospy_map_style.json",
              zoom: 5,
              center: [this.citycenterlng, this.citycenterlat],
            });
            this.map.addControl(new tt.FullscreenControl());
            this.map.addControl(new tt.NavigationControl());
            for (var i = 0; i < this.userData.length; i++) {
              var markerElement = document.createElement("div");
              markerElement.className = "marker";
              var markerContentElement = document.createElement("div");

              markerContentElement.style.backgroundColor = "#FFFFF";
              markerElement.appendChild(markerContentElement);
              var iconElement = document.createElement("div");
              iconElement.className = "marker-icon";
              iconElement.style.backgroundImage =
                "url(assets/digital-marketing/img/icon.png)";

              markerContentElement.appendChild(iconElement);
              this.marker = new tt.Marker({
                draggable: false,
                element: markerElement,
              })
                .setLngLat([this.userData[i].lng, this.userData[i].lat])
                .addTo(this.map);

              var boxText = document.createElement("div");
              var element =
                "<b>City: " +
                this.userData[i].City +
                "</b><br/> <b>Total Jobs: " +
                this.userData[i].totaljob +
                "</b><br/>";

              var viewallbtn = document.createElement("button");
              viewallbtn.setAttribute(
                "class",
                "btn btn-slider yellow-btn rounded-pill py-1"
              );
              viewallbtn.setAttribute(
                "onclick",
                'getcitydata("' + this.userData[i].City + '")'
              );
              viewallbtn.innerHTML = "View All";

              boxText.innerHTML = element;
              boxText.appendChild(viewallbtn);

              var popup = new tt.Popup().setHTML(boxText.innerHTML);
              this.marker.setPopup(popup);
            }
          }
        } else {
          this.map = tt.map({
            key: "G6pHCMGdQI4vGbUSdnvF54cKNkbLMqMC",
            container: "map",
            style: "assets/hospy_map_style.json",
            zoom: 10,
            center: [this.citycenterlng, this.citycenterlat],
          });
          this.map.addControl(new tt.FullscreenControl());
          this.map.addControl(new tt.NavigationControl());

          var center = [this.citycenterlng, this.citycenterlat];
          var radius = 25;
          var options = {
            steps: 50,
          };

          var circle = turf.circle(center, radius, options);

          this.map.on("load", () => {
            this.map.addLayer({
              id: "overlay",
              type: "fill",
              source: {
                type: "geojson",
                data: circle,
              },
              layout: {},
              paint: {
                "fill-color": "#2a3d9c",
                "fill-opacity": 0.2,
                "fill-outline-color": "black",
              },
            });
          });

          for (var i = 0; i < this.userData.length; i++) {
            var markerElement = document.createElement("div");
            markerElement.className = "marker";
            var markerContentElement = document.createElement("div");
          
            markerContentElement.style.backgroundColor = "#FFFFF";
            markerElement.appendChild(markerContentElement);
            markerElement.setAttribute(
              "onclick",
              'getangfun1("' + this.userData[i].job_id + '")'
            );
            var iconElement = document.createElement("div");
            iconElement.className = "marker-icon";
            iconElement.id= 'right' + this.userData[i].job_id;
            //iconElement.style.backgroundImage = 'url(' + this.Token.baseurl() + 'assets/employersilder/' + this.userData[i].logo + ')';
            iconElement.style.backgroundImage =
              "url(assets/digital-marketing/img/icon.png)";
            markerContentElement.appendChild(iconElement);
            this.marker = new tt.Marker({
              draggable: false,
              element: markerElement,
            })
              .setLngLat([this.userData[i].lng, this.userData[i].lat])
              .addTo(this.map);

            var element =
              "<b>Employer Name : " +
              this.userData[i].company +
              "</b><br/> <b>Work Schedule : " +
              this.userData[i].job_type +
              "</b><br/>";

            var div = document.createElement("div");
            var btn = document.createElement("button");
            btn.innerText = "show jobs";
            btn.setAttribute("type", "button");
            btn.setAttribute("class", "btn btn-slider yellow-btn rounded-pill");

            div.innerHTML = element;

            btn.setAttribute(
              "onclick",
              'getangfun("' + this.userData[i].job_id + '")'
            );
            div.appendChild(btn);
            var popup = new tt.Popup().setHTML(div.innerHTML);
            this.marker.setPopup(popup);
          }
        }

        this.httpclient
          .post(this.Token.baseurl() + "api/getpostedjobfullbyfiltercity", {
            lat: this.citycenterlat,
            lng: this.citycenterlng,
            issrc: this.issrc,
            srcdata: this.jobsearchdata,
            state: this.state,
            city: this.city,
            address: this.jobsearchaddress,
            job_type: this.job_type,
            salary_type: this.salary_type,
            job_shift: this.job_shift,
            compensation: this.compensation,
          })
          .subscribe((response: any) => {
            this.all_job_data = response;
             console.log(response);
             if (response[0].companydata[0].logo != '') {
              this.rest_image = this.Token.baseurl() + "assets/employersilder/" + response[0].companydata[0].logo;
            } else {
              this.rest_image = this.Token.baseurl() + "assets/employersilder/" + 'demo.png';
            }
          });
      });
  }

  filter_search_jobs_by_input() {
    var link =
      "jobsearchmanual=TRUE" +
      "&jobsearchdata=" +
      this.find_search_jobs_data +
      "&jobsearchaddress=" +
      this.jobsearchaddress +
      "&lat=" +
      this.citycenterlat +
      "&long=" +
      this.citycenterlng;

    window.location.href = "/searchmap?" + link;
  }

  profile() {
    this.router.navigateByUrl("/profile-builder");
  }
  home() {
    this.router.navigateByUrl("/home");
  }
  employeesdash() {
    this.router.navigateByUrl("/candidate-dashboard");
  }
  employees() {
    this.router.navigateByUrl("/candidate-login");
  }
  privacy_policy() {
    this.router.navigate(["/privacy_policy"]);
  }
  aplydjobs() {
    this.router.navigateByUrl('/applied-jobs');
  }
  faq_data() {
    this.router.navigate(["/faq_desc"]);
  }
  agrement(){
    this.router.navigateByUrl('/agreement');
  }
  employer_profile() {
    this.router.navigate(['/edit-employer']);
  }
  about_us(){
    this.router.navigate(['/about']);
  }
  invest_data(){
    this.router.navigate(['/invest']);
  }
  candidate_logout(Event: MouseEvent) {
    this.Token.remove();
    localStorage.clear();
    window.location.href = "/";
    //  this.ngOnInit();
  }
}
