import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { TokenService } from '../Services/token.service';

@Component({
  selector: 'app-about',
  templateUrl: './about.component.html',
  styleUrls: ['./about.component.css']
})
export class AboutComponent implements OnInit {

  status: boolean = true;
  clickEvent() {
    this.status = !this.status;
  }
  isemployee: boolean = false;
  isemployeer: boolean = false;
  
  resume_token: any;
  testtoken: any;
  islog: any;

  constructor(public httpclient: HttpClient,private Token: TokenService, private router: Router) { }

  ngOnInit(): void {
    this.resume_token = localStorage.getItem("resume_token");
    this.testtoken = localStorage.getItem("token");
    if (this.resume_token != '' && this.resume_token != null) {
      this.isemployee = true;
      this.isemployeer = false;
      this.islog = false;

     
    }
    else {
      if (this.testtoken != '' && this.testtoken != null) {
        this.isemployee = false;
        this.isemployeer = true;
        this.islog = false;
        // console.log(this.islog + '2');
      }
      else {
        this.islog = true;
        this.isemployee = false;
        this.isemployeer = false;
        // console.log(this.islog + '3');
      }
    }
  }
  public loadCss() {
    const node = document.createElement('link');
    node.href = 'assets/digital-marketing/css/style_new.css'; // put there your js file location
    node.rel = 'stylesheet';
    node.charset = 'utf-8';
   document.getElementsByTagName('head')[0].appendChild(node);
  }
  employer_logout(Event: MouseEvent) {
    this.Token.remove();
    localStorage.clear();
    window.location.href = '/index.html';
    this.ngOnInit();
  }
  candidate_logout(Event: MouseEvent) {
    this.Token.remove();
    localStorage.clear();
    this.router.navigateByUrl('');
    this.ngOnInit();
  }  
  employer_communication() {
    this.router.navigate(['/communication']);
  }
  employer_data_management() {
    this.router.navigate(['/employer_communication']);
  }
  employer_manage_jobs() {
    this.router.navigate(['/jobs-employer']);
  }
  profile() {
    this.router.navigateByUrl('/profile-builder');
  }
  communication() {
    this.router.navigateByUrl('/communication');
  }
  employees() {
    this.router.navigateByUrl('/candidate-login');
  }
  jobsearch() {
    window.location.href = '/searchjobs';
  }
  blogs() {
    this.router.navigate(['/blog']);
  }
  employers() {
    this.router.navigate(['/employer-login']);
  }
  demo() {
    this.router.navigate(['/employer-demo']);
  }
  employer_signup() {
    this.router.navigate(['/employer-signup']);
  }
 
  faq_data(){
    this.router.navigate(['/faq_desc']);
  }
  dashboard() {
    this.router.navigateByUrl('/candidate-dashboard');
  }
  privacy_policy() {
    this.router.navigate(['/privacy_policy']);
  }

 agrement(){
    this.router.navigateByUrl('/agreement');
  }
  employer_billing() {
    this.router.navigate(['/employer-billing']);
  }
  testimon(){
    this.router.navigateByUrl('/testimonials');
  }
  home_page(){
    window.location.href = '/';
   }
   about_us(){
    this.router.navigate(['/about']);
  }
  invest_data(){
    this.router.navigate(['/invest']);
  }
}
