import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, FormBuilder, AbstractControl, Validators } from '@angular/forms';
import { HttpClient } from '@angular/common/http';
import { TokenService } from '../Services/token.service';
import { Router } from '@angular/router';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-register-candidate',
  templateUrl: './register-candidate.component.html',
  styleUrls: ['./register-candidate.component.css']
})
export class RegisterCandidateComponent implements OnInit {

 hero = {first_name: '',last_name: '' ,phone: '',email: '',password: '',confirmpwd: ''};

  constructor( private http:HttpClient, private Token : TokenService, private router: Router ) {  }


  ngOnInit(): void {
    
  }
 

 onSubmit(hero) {
    
   return this.http.post(this.Token.baseurl() + 'api/employeeregister', hero).subscribe(

     data => this.handleResponse(data),
      error => this.alertWithWarning('Email already exist!'),
    );
  }
  handleResponse(data) {
    console.log(data.email);
     var txt='Please Enter your verification code. You will receive an Email or Text Message. Thank you!';
         this.alertWithSuccess(txt);
         localStorage.setItem('id', data.id);
     this.router.navigateByUrl('/jobseeker-verification');
   }
// handleResponse(data) {
//     this.Token.handle(data.token);
//     this.router.navigateByUrl('/candidate-dashboard');
//    }
 
alertWithSuccess(txt){
  Swal.fire('Thank you...',txt, 'success')
  }
  alertWithWarning(txt) {
    Swal.fire('Thank you...', txt, 'warning')
  }

  employer_communication() {
    this.router.navigate(['/communication']);
  }
  employer_data_management() {
    this.router.navigate(['/employer_communication']);
  }
  employer_manage_jobs() {
    this.router.navigate(['/jobs-employer']);
  }
  profile() {
    this.router.navigateByUrl('/profile-builder');
  }
  communication() {
    this.router.navigateByUrl('/communication');
  }
  employees() {
    this.router.navigateByUrl('/candidate-login');
  }
  jobsearch() {
    window.location.href = '/searchjobs';
  }
  blogs() {
    this.router.navigate(['/blog']);
  }
  employers() {
    this.router.navigate(['/employer-login']);
  }
  demo() {
    this.router.navigate(['/employer-demo']);
  }
  employer_signup() {
    this.router.navigate(['/employer-signup']);
  }
  faq_data() {
    this.router.navigate(['/faq_desc']);
  }
  dashboard() {
    this.router.navigateByUrl('/candidate-dashboard');
  }
  employer_dashboard(){
    this.router.navigate(['/employer-dashboard']);
  }
  privacy_policy() {
    this.router.navigate(['/privacy_policy']);
  }
  about_us(){
    this.router.navigate(['/about']);
  }
 agrement(){
    this.router.navigateByUrl('/agreement');
  }
  home_page(){
    window.location.href = '/';
   }
   employer_profile() {
    this.router.navigate(['/edit-employer']);
  }
   
  invest_data(){
    this.router.navigate(['/invest']);
  }


  eyebtnstate1: any = "fa-eye-slash";
  passwordtype1: any = "password";
  passwordstate1: boolean = true;
  
  passwordstatechng() {
    if (this.passwordstate1 === false) {
      this.eyebtnstate1 = "fa-eye";
      this.passwordtype1 = "text";
      this.passwordstate1 = true;
    } else {
      this.eyebtnstate1 = "fa-eye-slash";
      this.passwordtype1 = "password";
      this.passwordstate1 = false;
    }
  }

  eyebtnstate2: any = "fa-eye-slash";
  passwordtype2: any = "password";
  passwordstate2: boolean = true;
  
  cnfpasswordstatechng() {
    if (this.passwordstate2 === false) {
      this.eyebtnstate2 = "fa-eye";
      this.passwordtype2 = "text";
      this.passwordstate2 = true;
    } else {
      this.eyebtnstate2 = "fa-eye-slash";
      this.passwordtype2 = "password";
      this.passwordstate2 = false;
    }
  }

}