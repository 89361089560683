import { Component, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { FormsModule, FormGroup } from '@angular/forms';
import { FormControl, FormArray, FormBuilder, ReactiveFormsModule } from '@angular/forms';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { TokenService } from '../../Services/token.service';
import { Router } from '@angular/router';
import Swal from 'sweetalert2';
import { IDropdownSettings } from 'ng-multiselect-dropdown';

@Component({
  selector: 'app-edit-jobpost',
  templateUrl: './edit-jobpost.component.html',
  styleUrls: ['./edit-jobpost.component.css']
})
export class EditJobpostComponent implements OnInit {
  status: boolean = true;
  clickEvent() {
    this.status = !this.status;
  }
  dropdownList;
  dropdownSettings;
  selectedItems;

  url1: any = this.Token.baseurl();
  empjobtype: any;
  empjobtyp: string = "";
  empcategory: any;
  empcat: string = "";

  jobdetailone: any;
  job_categories: any;
  job_typ: any;
  job_title: any;
  job_desc: any;

  constructor(private http: HttpClient, private Token: TokenService, private router: Router) { }

  ngOnInit(): void {

    this.getempjobtype();
    this.getcategory();
    this.getpostedjobdetail();

    this.dropdownList = this.getSkill();
    this.dropdownSettings = {
      singleSelection: false,
      idField: 'item_id',
      textField: 'item_text',
      selectAllText: 'Select All',
      unSelectAllText: 'UnSelect All',
      itemsShowLimit: 5,
      allowSearchFilter: true
    };
    this.getpostedskill();
  }

  logout(Event: MouseEvent) {
    Event.preventDefault();
    this.Token.remove();
    this.router.navigateByUrl('/employer-login');
  }

  getpostedskill() {
    var jid = localStorage.getItem('job_id');

    this.http.get<any>(this.url1 + 'api/getpostedskilljob/' + jid).subscribe(
      response => {
        this.selectedItems = response;
      }
    );
  }

  getpostedjobdetail() {

    var jb_id = localStorage.getItem('job_id');
    this.http.get<any>(this.url1 + 'api/getpostedjobbyid/' + jb_id).subscribe(
      response => {
        this.jobdetailone = response;
        this.job_categories = this.jobdetailone[0].job_category_id;
        this.job_typ = this.jobdetailone[0].job_type_id;
        this.job_title = this.jobdetailone[0].job_title;
        this.job_desc = this.jobdetailone[0].description;
      }
    );
  }

  getSkill() {
    this.http.get<any>(this.url1 + 'api/getskill').subscribe(
      response => {
        this.dropdownList = response;
      }
    );
  }


  getempjobtype() {
    this.http.get<any>(this.url1 + 'api/employerjobtype').subscribe(
      response => {
        this.empjobtype = response;
      }
    );
  }
  getcategory() {
    this.http.get<any>(this.url1 + 'api/employercategory').subscribe(
      response => {
        this.empcategory = response;
      }
    );
  }


  onEditJob(data) {

    var j_id = localStorage.getItem('job_id');
    var tokens = this.Token.get();
    const url = this.url1 + 'api/addnewjob';
    this.http.post(url, { token: tokens, job_id: j_id, job_title: data.job_title, job_type_id: data.job_type_id, job_category_id: data.job_category_id, description: data.description, page: 1 })
      .subscribe((result) => {

        this.http.get(this.url1 + 'api/deletepostedskilljob/' + j_id).subscribe((result) => {
          this.ngOnInit();
        });

        for (var dt in data.required_skill) {
          const urls = this.url1 + 'api/updatejobskill';
          this.http.post(urls, { skill_id: data.required_skill[dt].item_id, job_id: j_id, token: tokens })
            .subscribe((result) => {
            });
        }
        this.ngOnInit();
      });
  }


}
