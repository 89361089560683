import { Component, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { TokenService } from '../Services/token.service';
import { Router } from '@angular/router';
import {NgbModal, ModalDismissReasons} from '@ng-bootstrap/ng-bootstrap';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-candidate-verification',
  templateUrl: './candidate-verification.component.html',
  styleUrls: ['./candidate-verification.component.css']
})
export class CandidateVerificationComponent implements OnInit {

  constructor(private modalService: NgbModal, private http: HttpClient, private Token: TokenService, private router: Router) { }

  ngOnInit(): void {
  }
  otp_verifyy: any;
  otp_passcode(data){
    this.http.post(this.Token.baseurl() + 'api/otpverify', { otp: data.otp })
      .subscribe((result) => {
        this.otp_verifyy = result;
        console.log(this.otp_verifyy);
       if (this.otp_verifyy.status == true) {
        var txt='OTP successfully verified!';
        this.alertWithSuccess(txt);
        this.router.navigateByUrl('/candidate-login');
        this.ngOnInit();
       } else if (this.otp_verifyy.status == false) {
        var txt='Please enter correct OTP!';
        this.alertWithWarning(txt);
        this.ngOnInit();
       }
        
      });
  }

  resend_otps(){
    var v_id = localStorage.getItem('id');
    const url = this.Token.baseurl() + 'api/resend_otp';
        this.http.post(url, {
          id: v_id
        }).subscribe((result) => {
          var txt='OTP Resend!';
          this.alertWithSuccess(txt);
            this.ngOnInit();
          });
  }

  alertWithSuccess(txt) {
    Swal.fire('Thank you...', txt, 'success')
  }
  alertWithWarning(txt) {
    Swal.fire('Thank you...', txt, 'warning')
  }
  

}
