import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';

@Injectable({
	providedIn: 'root'
})
export class TokenService {

	user_type: any;

	constructor(private http: HttpClient, private router: Router) { }
	baseurl() {
		   // return "https://hospyapi.snckapp.com/";
		    return "https://hospy-api.aofenggsys.us/";
	}

	handle(token) {
		this.set(token);
	}

	set(token) {
		localStorage.setItem('token', token);
	}
	get() {
		return localStorage.getItem('token');
	}
	remove() {
		localStorage.removeItem('token');
	}
	isValid() {
		const token = this.get();
		if (token != '') {
			return true;
		} else{
			return false;
		}
	}
	loggedIn() {
		//this.router.navigateByUrl('/employer-dashboard');
		return this.isValid();
	}
}
