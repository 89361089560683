import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { TokenService } from '../../Services/token.service';
import { DomSanitizer, SafeResourceUrl } from "@angular/platform-browser";

declare var $: any;

@Component({
  selector: 'app-applied-jobs',
  templateUrl: './applied-jobs.component.html',
  styleUrls: ['./applied-jobs.component.css']
})

export class AppliedJobsComponent implements OnInit {

  constructor(private router: Router, public httpclient: HttpClient, private Token: TokenService, public sanitizer: DomSanitizer, private http: HttpClient) { }

  rest_main_image: any;
  comp_name: any;
  comp_details: any;
  comp_specialty: any;
  comp_type: any;
  comp_address: any;
  comp_established_in: any;
  comp_embd_url: any;
  all_job_data: any;
  all_job_data_by_company: any;

  ngOnInit(): void {
    this.showAllapplied();
  }

  showAllapplied() {
    var token = localStorage.getItem('token')
    this.httpclient.get(this.Token.baseurl() + 'api/getappliedjob/' + token).subscribe((res) => {
      this.all_job_data = res;
    });

    this.httpclient.get<any>(this.Token.baseurl() + 'api/employeedetails/' + token).subscribe(
      (response: any) => {
        sessionStorage.setItem('employee_id',response.id);
      }
    );
  }
  invest_data(){
    this.router.navigate(['/invest']);
  }
  about_us(){
    this.router.navigate(['/about']);
  }
  agrement(){
    this.router.navigateByUrl('/agreement');
  }

  communicate(employer_id: any) {
    sessionStorage.setItem('employer_id',employer_id);
    this.router.navigateByUrl('/communication');
  }

showMoreJobDetails(jobid: any) {
  jQuery.noConflict();
  $('#xlarge').modal('show');

  var token = localStorage.getItem('token');

  this.http.get<any>(this.Token.baseurl() + 'api/getjobdetailsbyjobid/' + jobid + "/" + token).subscribe(
    response => {
      this.rest_main_image = this.Token.baseurl() + 'assets/employersilder/' + response[0].companydata[0].image1;
      this.comp_name = response[0].userdata[0].first_name;
      this.comp_specialty = response[0].companydata[0].speciality;
      this.comp_details = response[0].companydata[0].details;
      this.comp_type = response[0].jobdata.industry_id; 
       // console.log(this.comp_type);
        this.comp_address = response[0].jobdata.job_location;
        this.comp_established_in = response[0].companydata[0].established_in;

        this.comp_embd_url = this.sanitizer.bypassSecurityTrustResourceUrl(
          "https://maps.google.com/maps/embed/v1/place?key=AIzaSyDL5VMrQ9M0XPXz-RqNrPGXxNtTRIjyhys&q=" +
            this.comp_address
              .replace(" ", "+")
              .replace(" ", "+")
              .replace(" ", "+")
              .replace(" ", "+")
              .replace(" ", "+")
              .replace(" ", "+")
              .replace(" ", "+")
              .replace(" ", "+")
              .replace(",", "")
              .replace(",", "")
              .replace(",", "")
        );

      this.all_job_data_by_company = response;
    });
}

hideMoreJobDetails() {
  jQuery.noConflict();
  $('#xlarge').modal('hide');
}
}
