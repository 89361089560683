import { Component, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { FormsModule, FormGroup } from '@angular/forms';
import { FormControl, FormArray, FormBuilder, ReactiveFormsModule } from '@angular/forms';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Router } from '@angular/router';
import { TokenService } from '../../Services/token.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-job-post4',
  templateUrl: './job-post4.component.html',
  styleUrls: ['./job-post4.component.css']
})
export class JobPost4Component implements OnInit {

  stacademic = false;
  clickacademic(){
    this.stacademic =true;   
}
  stlanguage = false;
  clicklanguage(){
      this.stlanguage =true;   
  }
  clickremove(){
    this.stlanguage =false;     
  }

  url1: any = this.Token.baseurl();
  qualification: any;
  quali: string = "";
  languages: any;
  languag: string = "";
  permit_type: any;
  permit: string = "";
  jobqualifi: any;
  job_quali: any;
  job_training: any;
  quali_mandatory: any;
  trainingmandatory: any;
  job_language: any;
  job_permit: any;
  qualificationmandatory: any;
  languagemandatory: any;
  permitmandatory: any;
  stpermit = false;
  clickpermit(){
      this.stpermit =true;     
  }
  clickdele(){
    this.stpermit =false;     
  }
  sttraining = false;
  clicktraining(){
      this.sttraining =true;     
  }

  constructor(private http: HttpClient, private Token: TokenService, private router: Router) { }

  ngOnInit(): void {
    this.getqualification();
    this.getlanguages();
    this.getpermit();
    this.getpostqualification();
  }

  getpostqualification() {

    var jb_id = localStorage.getItem('job_id');
    this.http.get<any>(this.Token.baseurl()+ 'api/getpostedjobbyid/' + jb_id).subscribe(
      response => {
        this.jobqualifi = response;
        console.log(response);
        this.job_quali = this.jobqualifi[0].jobdata.qualification_id;
       // this.qualificationmandatory = this.jobqualifi[0].jobdata.isqualification_mandatory;
        this.job_training = this.jobqualifi[0].jobdata.professional_training;
       // this.trainingmandatory = this.jobqualifi[0].jobdata.isprofessional_mandatory;
       // console.log(this.trainingmandatory);
        this.job_language = this.jobqualifi[0].jobdata.language_id;
        this.job_permit = this.jobqualifi[0].jobdata.work_permit_id;
        this.languagemandatory = this.jobqualifi[0].jobdata.islanguage_mandatory;
        this.permitmandatory = this.jobqualifi[0].jobdata.iswork_permit_mandatory;
      }
    );
  }

  getqualification() {
    this.http.get<any>(this.url1 + 'api/getdegreelevel').subscribe(
      response => {
        this.qualification = response;
      }
    );
  }
  getlanguages() {
    this.http.get<any>(this.url1 + 'api/getlanguage').subscribe(
      response => {
        this.languages = response;
      }
    );
  }
  getpermit() {
    this.http.get<any>(this.url1 + 'api/getpermit_type').subscribe(
      response => {
        this.permit_type = response;
        console.log(response);
      }
    );
  }
  onAddJobforth(data){
    var jid = localStorage.getItem('job_id');
    var jid1 = localStorage.getItem('grp_job_id');
    var tokens = this.Token.get();
    const url = this.url1 + 'api/addnewjob';
    this.http.post(url, { token: tokens, grp_job_id: jid1, job_id: jid, qualification_id: data.qualification,professional_training: data.training,language_id: data.language,islanguage_mandatory: data.languagemandatory,work_permit_id:data.wrkpermit,iswork_permit_mandatory: data.permitmandatory, page: 4 })
      .subscribe((result) => {
        var txt='Job Posted Successfully';
        this.alertWithSuccess(txt);
        this.ngOnInit();
      });
      localStorage.removeItem('job_id');
      localStorage.removeItem('grp_job_id');
    this.router.navigateByUrl('/jobs-employer');
  }

  alertWithSuccess(txt){
    Swal.fire('Thank you...',txt, 'success')
    }
    privacy_policy() {
      this.router.navigate(['/privacy_policy']);
    }
  
   agrement(){
      this.router.navigateByUrl('/agreement');
    }
  
  faq_data() {
      this.router.navigate(['/faq_desc']);
    }
    invest_data(){
      this.router.navigate(['/invest']);
    }
    about_us(){
      this.router.navigate(['/about']);
    }
  
}
