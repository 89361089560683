import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { TokenService } from '../../Services/token.service';
import { HttpClient, HttpHeaders } from '@angular/common/http';

declare var $: any;

@Component({
  selector: 'app-candidate-dashboard',
  templateUrl: './candidate-dashboard.component.html',
  styleUrls: ['./candidate-dashboard.component.css']
})
export class CandidateDashboardComponent implements OnInit {

  status: boolean = false;
  scheduledetail: any;
  pic: any;
  clickEvent() {
    this.status = !this.status;
  }

  rest_main_image: any;
  comp_name: any;
  comp_details: any;
  comp_specialty: any;
  comp_type: any;
  comp_address: any;
  comp_established_in: any;

  all_job_data: any;
  all_job_data_by_company: any;

  constructor(private router: Router, private Token: TokenService, private http: HttpClient) { }

  ngOnInit(): void {
    this.showAllapplied();
    this.getintervieweedetails();
  }

  showAllapplied() {
    var token = localStorage.getItem('token');
    this.http.get(this.Token.baseurl() + 'api/getappliedjob/' + token).subscribe((res) => {
      this.all_job_data = res;
    });

    this.http.get<any>(this.Token.baseurl() + 'api/employeedetails/' + token).subscribe(
      (response: any) => {
        localStorage.setItem('employee_id', response.id);
      }
    );
  }

  communicate(employer_id: any) {
    localStorage.setItem('employer_id', employer_id);
    this.router.navigateByUrl('/communication');
  }

  showMoreJobDetails(jobid: any) {
    jQuery.noConflict();
    $('#xlarge').modal('show');

    var token = localStorage.getItem('token');

    this.http.get<any>(this.Token.baseurl() + 'api/getjobdetailsbyjobid/' + jobid + "/" + token).subscribe(
      response => {
        this.rest_main_image = this.Token.baseurl() + 'assets/employersilder/' + response[0].companydata[0].image1;
        this.comp_name = response[0].userdata[0].first_name;
        this.comp_specialty = response[0].companydata[0].speciality;
        this.comp_details = response[0].companydata[0].details;
        this.comp_type = response[0].jobdata.industry_id; 
        this.comp_address = response[0].jobdata.job_location;
        this.comp_established_in = response[0].companydata[0].established_in;

        this.all_job_data_by_company = response;
      });
  }

  hideMoreJobDetails() {
    jQuery.noConflict();
    $('#xlarge').modal('hide');
  }

  getintervieweedetails(){
    var emply_id = sessionStorage.getItem('employee_id');
     this.http.get<any>(this.Token.baseurl()+'api/getlatestinterviewemployee/'+emply_id).subscribe(
       response => {
       this.scheduledetail = response;
       console.log(response);
       if(this.scheduledetail[0].profilepic!=null){
        this.pic=this.scheduledetail[0].profilepic;
        }
        else{
         this.pic=this.Token.baseurl()+'employee/'+'profile.png';
        }
      }
     );
   }
   profile(){
    this.router.navigateByUrl('/profile-builder');
  }
  jobsearch() {
    window.location.href = '/searchjobs';
  }
  invest_data(){
    this.router.navigate(['/invest']);
  }
  about_us(){
    this.router.navigate(['/about']);
  }
  agrement(){
    this.router.navigateByUrl('/agreement');
  }
}
