import { Component, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { FormsModule, FormGroup } from '@angular/forms';
import { FormControl, FormArray, FormBuilder, ReactiveFormsModule } from '@angular/forms';
import { HttpClient,HttpHeaders } from '@angular/common/http';
import { TokenService } from '../../Services/token.service';
import { NgModule, EventEmitter, Output } from '@angular/core';
import {NgbModal, ModalDismissReasons} from '@ng-bootstrap/ng-bootstrap';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-career-info',
  templateUrl: './career-info.component.html',
  styleUrls: ['./career-info.component.css']
})
export class CareerInfoComponent implements OnInit {
closeResult = '';
degree_levels: any;
  degree_level: string="" ;
  educations: any;
  education: string="" ;
  experiences:any;
  exp: string="";

  educbyid: any;
  degree_e: any;
  coun_e: any;
  state_e: any;
  city_e: any;
  inst_e: any;
  result_e: any;
  year_e: any;
  clevel_e: any;
  id_e: any;

  expybyid: any;
  exp_title: any;
  exp_coun: any;
  exp_st: any;
  exp_ci: any;
  exp_desc: any;
  exp_cmp: any;
  exp_sdate: any;
  exp_enddate: any;
  exp_id: any;

formattedaddress=" ";
  citydata=" ";
 statedata=" ";
 options={
    componentRestrictions:{
      country:["USA"]
    }
  }
public AddressChange(address: any) {
    this.formattedaddress=address.formatted_address
   var add= this.formattedaddress.split(',');
   this.citydata=add[add.length-3];
    this.statedata=add[add.length-2];
  // this.statedata=this.statedata.substring(0,this.statedata.length-6);
  }

  constructor( private modalService: NgbModal,private http:HttpClient, private Token : TokenService ) { }

  open(content) {
    this.modalService.open(content, {ariaLabelledBy: 'modal-basic-title'}).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
  }
   edit(education) {
    this.modalService.open(education, {ariaLabelledBy: 'modal-basic-title'}).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
  }

   

  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }

  ngOnInit(): void {
  this.getdegreelevels();
  this.geteduction();
  this.getexp();
 // this.getediteducbyid();

  }

  geteditexpbyid(id){
  this.http.get<any>('https://hospy.snckapp.com/hospyback/api/employeeexperiencedetailsbyid/'+id).subscribe(
    response => {
    this.expybyid = response;
//console.log(response);
     this.exp_title=this.expybyid[0].experience_title;
     this.exp_coun=this.expybyid[0].country_id;
     this.exp_st=this.expybyid[0].state_id;
     this.exp_ci=this.expybyid[0].city_id;
     this.exp_desc=this.expybyid[0].description;
     this.exp_cmp=this.expybyid[0].company;
     this.exp_sdate=this.expybyid[0].start_date;
     this.exp_enddate=this.expybyid[0].end_date;
      this.exp_id=this.expybyid[0].id;
    }
  );
}

  getediteducbyid(id){
  this.http.get<any>('https://hospy.snckapp.com/hospyback/api/employeeeducationdetailsbyid/'+id).subscribe(
    response => {
    this.educbyid = response;
    console.log(response);
     this.degree_e=this.educbyid[0].degree_title;
     this.coun_e=this.educbyid[0].country_id;
     this.state_e=this.educbyid[0].state_id;
     this.city_e=this.educbyid[0].city_id;
     this.inst_e=this.educbyid[0].institute;
     this.result_e=this.educbyid[0].result;
     this.year_e=this.educbyid[0].year;
     this.clevel_e=this.educbyid[0].degree_level_id;
      this.id_e=this.educbyid[0].id;
    }
  );
}

  geteduction(){
  var token = this.Token.get();
  this.http.get<any>('https://hospy.snckapp.com/hospyback/api/employeeeducationdetails/'+token).subscribe(
    response => {
    this.educations = response;
// console.log(response);
    }
  );
}
getexp(){
  var token = this.Token.get();
//  console.log(token);
  this.http.get<any>('https://hospy.snckapp.com/hospyback/api/employeeexperiencedetails/'+token).subscribe(
    response => {
    this.experiences = response;
   //  console.log(response);
    }
  );
}
  getdegreelevels(){
  this.http.get<any>('https://hospy.snckapp.com/hospyback/api/getdegreelevel').subscribe(
    response => {
      this.degree_levels = response;
    }
  );
}

onEditExp(data){
  var tokens = this.Token.get();
  const url = 'https://hospy.snckapp.com/hospyback/api/updateemployeeexperience';
  this.http.post(url, {token:tokens,exp_id:data.exp_id,experience_title: data.experience_title,company: data.company,country_id: data.country_id,state_id: data.state_id,city_id: data.city_id,start_date: data.start_date,end_date: data.end_date,description: data.description})
    .subscribe((result) => {
      this.ngOnInit(); //reload the table
    });
  }

onEditEducation(data){
  var tokens = this.Token.get();
  const url = 'https://hospy.snckapp.com/hospyback/api/updateemployeeeducation';
  this.http.post(url, {token:tokens,edu_id:data.edu_id,degree_level_id: data.degree_level_id,degree_title: data.degree_title,country_id: data.country_id,state_id: data.state_id,city_id: data.city_id,institute: data.institute,result: data.result,year: data.year})
    .subscribe((result) => {
      this.ngOnInit(); //reload the table
    });
  }

  onAddExperience(data){
  var tokens = this.Token.get();
    const url = 'https://hospy.snckapp.com/hospyback/api/updateemployeeexperience';
  this.http.post(url, {token:tokens,experience_title: data.experience_title,company: data.company,currently_working: data.currently_working, start_date: data.start_date,end_date: data.end_date,country_id: data.country_id,state_id: data.state_id,city_id: data.city_id,description: data.description})
    .subscribe((result) => {
      this.ngOnInit(); //reload the table
    });
  }

  onAddEducation(data){
 // console.log(data);

  var tokens = this.Token.get();

    const url = 'https://hospy.snckapp.com/hospyback/api/updateemployeeeducation';
  this.http.post(url, {token:tokens,degree_level_id: data.degree_level_id,degree_title: data.degree_title,country_id: data.country_id,state_id: data.state_id,year: data.year,result: data.result,city_id: data.city_id,institute: data.institute})
    .subscribe((result) => {
    var txt='Education Added successfully';
    this.alertWithSuccess(txt);
      this.ngOnInit(); 
    });
  }

  deleteeducbyid(id){
  this.http.delete('https://hospy.snckapp.com/hospyback/api/delemployeeeducation/'+id) .subscribe((result) => {
      var txt='Education Deleted successfully';
    this.alertWithSuccess(txt);
      this.ngOnInit();
    });
  }
  deletexpbyid(id){
  this.http.delete('https://hospy.snckapp.com/hospyback/api/delemployeeexperience/'+id) .subscribe((result) => {
    var txt='Experience Deleted successfully';
    this.alertWithSuccess(txt);
      this.ngOnInit();
    });
  }

   alertWithSuccess(txt){
    Swal.fire('Thank you...',txt, 'success')
  }

}
