import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, FormBuilder, AbstractControl, Validators } from '@angular/forms';
import { HttpClient } from '@angular/common/http';
import { TokenService } from '../../Services/token.service';
import { Router } from '@angular/router';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-employer-register',
  templateUrl: './employer-register.component.html',
  styleUrls: ['./employer-register.component.css']
})
export class EmployerRegisterComponent implements OnInit {

  status: boolean = true;
  clickEvent() {
    this.status = !this.status;
  }

  hero = { phone: '', email: '', password: '', confirmpwd: '' };

  constructor(private http: HttpClient, private Token: TokenService, private router: Router) { }

  ngOnInit(): void {
    if(this.Token.get()){
      this.router.navigateByUrl('/edit-employer');
    }
    this.loadCss();
  }
  onSubmitemployr(hero) {

    return this.http.post(this.Token.baseurl() + 'api/employerregister', hero).subscribe(

    //  return this.http.get<any>(this.Token.baseurl() + 'api/employerregister/', hero).subscribe(
      // data => console.log(data),
      data => this.handleResponse(data),
      error => alert('Email Already Exist')
    );
  }
  
  handleResponse(data) {
   // this.Token.handle(data.token);
    var txt='Please wait until Admin verifies you. You will receive an Email soon. Thank you!';
        this.alertWithSuccess(txt);
    this.router.navigateByUrl('/employer-login');
  }
  
  public loadCss() {
    const node = document.createElement('link');
    node.href = 'assets/digital-marketing/css/style_old.css'; // put there your js file location
    node.rel = 'stylesheet';
    node.charset = 'utf-8';
    document.getElementsByTagName('head')[0].appendChild(node);
  }
  alertWithSuccess(txt){
    Swal.fire('Thank you...',txt, 'success')
  }


  eyebtnstate1: any = "fa-eye-slash";
  passwordtype1: any = "password";
  passwordstate1: boolean = true;
  
  passwordstatechng() {
    if (this.passwordstate1 === false) {
      this.eyebtnstate1 = "fa-eye";
      this.passwordtype1 = "text";
      this.passwordstate1 = true;
    } else {
      this.eyebtnstate1 = "fa-eye-slash";
      this.passwordtype1 = "password";
      this.passwordstate1 = false;
    }
  }

  eyebtnstate2: any = "fa-eye-slash";
  passwordtype2: any = "password";
  passwordstate2: boolean = true;
  
  cnfpasswordstatechng() {
    if (this.passwordstate2 === false) {
      this.eyebtnstate2 = "fa-eye";
      this.passwordtype2 = "text";
      this.passwordstate2 = true;
    } else {
      this.eyebtnstate2 = "fa-eye-slash";
      this.passwordtype2 = "password";
      this.passwordstate2 = false;
    }
  }

}
