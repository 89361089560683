import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { map, tap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class UserService {
  private url = 'https://hospyapi.snckapp.com/api/getallusers';

  constructor(private http: HttpClient) { }
  
  getUserByEmail(email: string) {
    return this.http.get<any[]>(`${this.url}?email=${email}`);
  }

}