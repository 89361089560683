import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { TokenService } from '../Services/token.service';

declare var $: any;

@Component({
  selector: 'app-communication',
  templateUrl: './communication.component.html',
  styleUrls: ['./communication.component.css']
})
export class CommunicationComponent implements OnInit {

  candidate_name: any;
  resume_token: any;
  employee_id: any;
  employer_id: any;

  all_tickets_data: any;
  ticket_status: any = 1;

  constructor(public httpclient: HttpClient, private router: Router, private Token: TokenService) { }

  ngOnInit(): void {
    this.resume_token = localStorage.getItem('resume_token');
    this.employee_id = localStorage.getItem('employee_id');
    this.employer_id = localStorage.getItem('employer_id');

    this.loadCss();
    this.get_all_ticket_data();
  }

  newticket(data: any) {
    this.httpclient.post(this.Token.baseurl() + 'api/addcommunationsubject', { employee_id: this.employee_id, employer_id: this.employer_id, subjetc: data.subject })
      .subscribe((result: any) => {
        localStorage.setItem('communication_id', result);
        this.router.navigateByUrl('/communication-messaging');
      });
  }

  change_ticket_status(status) {
    if (this.resume_token) {
      $("#all_tickets").css("display", "flex");
      $("#createticket").css("display", "none");
      if (status == 0) {
        $("#ticket_type_heading").html("All Closed Tickets");
      } else if (status == 1) {
        $("#ticket_type_heading").html("All Active Tickets");
      }

      this.httpclient.post(this.Token.baseurl() + 'api/getcommunationsubject', { employee_id: this.employee_id, employer_id: this.employer_id, status: status })
        .subscribe((result: any) => {
          this.all_tickets_data = result;
        });
    } else {
      this.httpclient.post(this.Token.baseurl() + 'api/getcommunationsubjectbyemployer', { employer_id: this.employer_id, status: status }).subscribe(
        (result: any) => {
          console.log(result);
          this.all_tickets_data = result;
        });
    }
  }

  get_all_ticket_data() {
    if (this.resume_token) {
      this.httpclient.post(this.Token.baseurl() + 'api/getcommunationsubject', { employee_id: this.employee_id, employer_id: this.employer_id, status: this.ticket_status })
        .subscribe((result: any) => {
          this.all_tickets_data = result;
        });
    } else {
      this.httpclient.post(this.Token.baseurl() + 'api/getcommunationsubjectbyemployer', { employer_id: this.employer_id, status: this.ticket_status }).subscribe(
        (result: any) => {
          console.log(result);
          this.all_tickets_data = result;
        });
    }
  }

  view_ticket(id) {
    localStorage.setItem("communication_id", id);
    this.router.navigateByUrl('/communication-messaging');
  }

  show() {
    $("#all_tickets").css("display", "none");
    $("#createticket").css("display", "flex");
    $("#ticket_type_heading").html("Create New Ticket");
  }

  public loadCss() {
    const node = document.createElement('link');
    node.href = 'assets/digital-marketing/css/components.css'; // put there your js file location
    node.rel = 'stylesheet';
    node.charset = 'utf-8';
    document.getElementsByTagName('head')[0].appendChild(node);

    const node1 = document.createElement('link');
    node1.href = 'assets/digital-marketing/css/bootstrap.css'; // put there your js file location
    node1.rel = 'stylesheet';
    node1.charset = 'utf-8';
    document.getElementsByTagName('head')[0].appendChild(node1);

    const node2 = document.createElement('link');
    node2.href = 'assets/digital-marketing/css/app-chat.css'; // put there your js file location
    node2.rel = 'stylesheet';
    node2.charset = 'utf-8';
    document.getElementsByTagName('head')[0].appendChild(node2);

    const node3 = document.createElement('link');
    node3.href = 'assets/digital-marketing/css/bootstrap-extended.css'; // put there your js file location
    node3.rel = 'stylesheet';
    node3.charset = 'utf-8';
    document.getElementsByTagName('head')[0].appendChild(node3);

    const node4 = document.createElement('link');
    node4.href = 'assets/digital-marketing/css/colors.css'; // put there your js file location
    node4.rel = 'stylesheet';
    node4.charset = 'utf-8';
    document.getElementsByTagName('head')[0].appendChild(node4);
  }
}