import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { TokenService } from '../Services/token.service';

declare var $: any;

@Component({
  selector: 'app-communication-messaging',
  templateUrl: './communication-messaging.component.html',
  styleUrls: ['./communication-messaging.component.css']
})

export class CommunicationMessagingComponent implements OnInit {

  resume_token: any = localStorage.getItem('resume_token');
  communication_id: any = localStorage.getItem("communication_id");
  user_type: any;
  all_messages_data: any;
  chat_input_message: any;

  constructor(public httpclient: HttpClient, private router: Router, private Token: TokenService) { }

  ngOnInit(): void {
    this.loadCss();
    this.get_all_messages();

    if(!this.chat_input_message){
      $("#chatsendbtn").addClass("disabled");
      $("#chatsendbtn").css("cursor","not-allowed");
      $('#chatsendbtn').prop('disabled', true);
    }
  }

  get_all_messages() {
    if (localStorage.getItem('resume_token')) {
      this.user_type = 1;
    } else {
      this.user_type = 0;
    }

    this.httpclient.post(this.Token.baseurl() + 'api/getcommunationmsg', { conn_id: this.communication_id })
      .subscribe((result: any) => {
        this.all_messages_data = result;
      });
  }

  chatMessagesSend(data) {
    $("#spinner_loader").removeClass("fa-send");
    $("#spinner_loader").addClass("fa-spin fa-spinner");
    $("#chatsendbtn").addClass("disabled");
    $("#chatsendbtn").css("cursor","not-allowed");
    $('#chatsendbtn').prop('disabled', true);

    this.httpclient.post(this.Token.baseurl() + 'api/addcommunationmsg', { conn_id: data.conn_id, message: data.message, type: data.type })
      .subscribe((result: any) => {
        $("#spinner_loader").css("color","white");
        $("#spinner_loader").removeClass("fa-spin fa-spinner");
        $("#spinner_loader").addClass("fa-send");
        $("#chatsendbtn").removeClass("disabled");
        $("#chatsendbtn").css("cursor","pointer");
        $('#chatsendbtn').prop('disabled', false);

        var html = '<div class="chat"><div class="chat-body mr-n3"><div class="chat-content">' + "<p>" + data.message + "</p>" + "</div></div></div>";
        $(".chat-app-window .chats").append(html);
        this.chat_input_message = "";
        console.log(result);
      });
  }

  set_msg_input_value(){
    if(this.chat_input_message){
      $("#spinner_loader").css("color","white");
      $("#chatsendbtn").removeClass("disabled");
      $("#chatsendbtn").css("cursor","pointer");
      $('#chatsendbtn').prop('disabled', false);
    } else{
      $("#chatsendbtn").addClass("disabled");
      $("#chatsendbtn").css("cursor","not-allowed");
      $('#chatsendbtn').prop('disabled', true);
    }
  }

  public loadCss() {
    const node = document.createElement('link');
    node.href = 'assets/digital-marketing/css/components.css'; // put there your js file location
    node.rel = 'stylesheet';
    node.charset = 'utf-8';
    document.getElementsByTagName('head')[0].appendChild(node);

    const node1 = document.createElement('link');
    node1.href = 'assets/digital-marketing/css/bootstrap.css'; // put there your js file location
    node1.rel = 'stylesheet';
    node1.charset = 'utf-8';
    document.getElementsByTagName('head')[0].appendChild(node1);

    const node2 = document.createElement('link');
    node2.href = 'assets/digital-marketing/css/app-chat.css'; // put there your js file location
    node2.rel = 'stylesheet';
    node2.charset = 'utf-8';
    document.getElementsByTagName('head')[0].appendChild(node2);
  }
}