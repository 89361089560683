import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { TokenService } from '../Services/token.service';

@Component({
  selector: 'app-user-agreement',
  templateUrl: './user-agreement.component.html',
  styleUrls: ['./user-agreement.component.css']
})
export class UserAgreementComponent implements OnInit {

  status: boolean = true;
  clickEvent() {
    this.status = !this.status;
  }
  allbillingdala: any;
  islog: any;

  constructor(public httpclient: HttpClient,private Token: TokenService, private router: Router) { }

  isemployee: boolean = false;
  isemployeer: boolean = false;
  
  resume_token: any;
  testtoken: any;

  ngOnInit(): void {
    this.resume_token = localStorage.getItem("resume_token");
    this.testtoken = localStorage.getItem("token");
    if (this.resume_token != '' && this.resume_token != null) {
      this.isemployee = true;
      this.isemployeer = false;
      this.islog = false;

     
    }
    else {
      if (this.testtoken != '' && this.testtoken != null) {
        this.isemployee = false;
        this.isemployeer = true;
        this.islog = false;
        // console.log(this.islog + '2');
      }
      else {
        this.islog = true;
        this.isemployee = false;
        this.isemployeer = false;
        // console.log(this.islog + '3');
      }
    }
  }
  employer_logout(Event: MouseEvent) {
    this.Token.remove();
    localStorage.clear();
    window.location.href = '/index.html';
    this.ngOnInit();
  }
  employers() {
    this.router.navigate(['/employer-login']);
  }
  employer_profile() {
    this.router.navigate(['/edit-employer']);
  }
  employer_dashboard() {
    this.router.navigate(['/employer-dashboard']);
  }
  employer_billing() {
    this.router.navigate(['/employer-billing']);
  }
  faq_data() {
    this.router.navigate(['/faq_desc']);
  }
  privacy_policy() {
    this.router.navigate(['/privacy_policy']);
  }
  employees() {
    this.router.navigateByUrl('/candidate-login');
  }
  jobsearch() {
    window.location.href = '/searchjobs';
  }

  
  employer_communication() {
    this.router.navigate(['/communication']);
  }
  employer_data_management() {
    this.router.navigate(['/employer_communication']);
  }
  employer_manage_jobs() {
    this.router.navigate(['/jobs-employer']);
  }
  profile() {
    this.router.navigateByUrl('/profile-builder');
  }
  communication() {
    this.router.navigateByUrl('/communication');
  }

  blogs() {
    this.router.navigate(['/blog']);
  }
  candidate_logout(Event: MouseEvent) {
    this.Token.remove();
    localStorage.clear();
    this.router.navigateByUrl('/');
    this.ngOnInit();
  }
 
  
  demo() {
    this.router.navigate(['/employer-demo']);
  }
  employer_signup() {
    this.router.navigate(['/employer-signup']);
  }

  dashboard() {
    this.router.navigateByUrl('/candidate-dashboard');
  }
  home_page(){
    window.location.href = '/';
   }
   invest_data(){
    this.router.navigate(['/invest']);
  }
  about_us(){
    this.router.navigate(['/about']);
  }

  agrement(){
    this.router.navigateByUrl('/agreement');
  }
}
