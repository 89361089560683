import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { TokenService } from '../../../Services/token.service';
import { HttpClient,HttpHeaders } from '@angular/common/http';

@Component({
  selector: 'app-employer-dashboard',
  templateUrl: './employer-dashboard.component.html',
  styleUrls: ['./employer-dashboard.component.css']
})
export class EmployerDashboardComponent implements OnInit {

  joblisted: any;
 status: boolean = true;
  scheduledetail: any;
  inttype: any;
  pic: any;
  compnydetail: any;
  cmpny_logo: any;
  logo_name: any;
  cmpny_ceo: any;
  clickEvent(){
      this.status = !this.status;       
  }
  

  url1: any = this.Token.baseurl();

  constructor( private http: HttpClient, private router: Router, private Token : TokenService ) { }

  ngOnInit(): void {
    this.getjoblist();
    this.getintervieweedetails();
    this.getemployercompnydetail();
  }

  logout(Event: MouseEvent) {
  	Event.preventDefault();
  	this.Token.remove();
    localStorage.clear();
  	this.router.navigateByUrl('/employer-login');
  }
  edits(Event: MouseEvent) {
    Event.preventDefault();
    this.Token.get();
    this.router.navigateByUrl('/edit-employer');
  }
  privacy_policy() {
    this.router.navigate(['/privacy_policy']);
  }

 agrement(){
    this.router.navigateByUrl('/agreement');
  }

faq_data() {
    this.router.navigate(['/faq_desc']);
  }

  getjoblist(){
    var token = this.Token.get();
    this.http.get<any>(this.Token.baseurl()+'api/getpostedjob/'+token).subscribe( response => {
      this.joblisted = response;
      //console.log(this.joblisted);
      }
    );
  }

  viewjob(id){
  //  this.router.navigateByUrl('/employer-job-details');
    localStorage.setItem('job_id',id);
    this.router.navigateByUrl('/view-applied-candidate');
  }
  getintervieweedetails(){
    var emplr_id = localStorage.getItem('employer_id');
     this.http.get<any>(this.Token.baseurl()+'api/getlatestinterview/'+emplr_id).subscribe(
       response => {
       this.scheduledetail = response;
       console.log(response);
       if(this.scheduledetail[0].profilepic!=null){
        this.pic=this.scheduledetail[0].profilepic;
        }
        else{
         this.pic=this.Token.baseurl()+'employee/'+'profile.png';
        }
      }
     );
   }
   billing(){
    this.router.navigate(['/employer-billing']);
  }

   getemployercompnydetail() {
    var token = this.Token.get();
    this.http.get<any>(this.url1 + 'api/employercompanydetails/' + token).subscribe(
      response => {
        this.compnydetail = response[0];
        var img_path = 'assets/employersilder/';
        var demo_img = this.Token.baseurl() + img_path + 'demo.png';

        if (this.compnydetail != null) {
          if (this.compnydetail.logo != '') {
            this.cmpny_logo = this.Token.baseurl() + img_path + this.compnydetail.logo;
          } else {
            this.cmpny_logo = demo_img;
          }
          this.logo_name = this.compnydetail.logo;
          this.cmpny_ceo = this.compnydetail.ceo;
        } else {
          this.cmpny_logo = demo_img;
        }
      }
    );
  }
  about_us(){
    this.router.navigate(['/about']);
  }
  invest_data(){
    this.router.navigate(['/invest']);
  }

}
