import { Component, OnInit } from '@angular/core';
import {NgbModal, ModalDismissReasons} from '@ng-bootstrap/ng-bootstrap';
import Swal from 'sweetalert2';
import { TokenService } from '../../Services/token.service';
import { HttpClient,HttpHeaders } from '@angular/common/http';
import { Router } from '@angular/router';

@Component({
  selector: 'app-interview',
  templateUrl: './interview.component.html',
  styleUrls: ['./interview.component.css']
})
export class InterviewComponent implements OnInit {
  appliedcandidate: any;
  apply_candidate: string = "";
  userdetail: any;
  workdetails: any;
  workde: string = "";
  profirst_name: any;
  prolast_name: any;
  proemail: any;
  pro_phone:any;
  pro_whatsapp:any;
  pro_address:any;
  citydata:any;
  statedata:any;
  pindata:any;
  pro_fb:any;
  pro_workas:any;
  transport: any;
  background: any;
  drugtest: any;
  felonies: any;
  pic: any;
  workas: any;
  workin: any;
  educationdata: any;
  education: string = "";
  school_name: any;
  location_educat: any;
  field_educat: any;
  start_educat: any;
  end_educat: any;
  current_study: any;
  desiredetail: any;
  desire_job: any;
  desire_schedule: any;
  desire_relocation: any;
  not_interested: any;
  applydate: any;
  public demo1TabIndex = 0;
  scheduledetail: any;
  interviews_date: any;
  int_stdate: any;
  int_endate: any;
  int_addr: any;
  int_msg: any;
  int_vdolink: any;
  int_phone: any;
  interviews_id: any;
  app_id: any;
public demo1BtnClick() {
  const tabCount = 3;
  this.demo1TabIndex = (this.demo1TabIndex + 0) % tabCount;
}
  open(content) {
    this.modalService.open(content, {ariaLabelledBy: 'modal-basic-title'}).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
    this.ngOnInit();
  }
  closeResult = '';
  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }

  constructor( private http: HttpClient, private modalService: NgbModal,private router: Router, private Token : TokenService ) { }

  ngOnInit(): void {
    this.getappliedcandidate()
    this.getworkdetails();
    this.getuserdetail();
    this.geteducationdetail();
    this.getdesiredetail();
    this.getintervieweedetails();
  }
  toTop() {
  document.getElementById("content")!.scrollIntoView();
}
  getworkdetails(){
    var worktoken = localStorage.getItem('user_token');
     this.http.get<any>(this.Token.baseurl()+'api/getresumeworkdetails/'+worktoken).subscribe(
       response => {
       this.workdetails = response;
      // console.log(response);
       }
     );
   }
  getappliedcandidate(){
    var jb_id = localStorage.getItem('job_id');
    this.http.get<any>(this.Token.baseurl()+'api/getappliedemployee/'+jb_id).subscribe( response => {
      this.appliedcandidate = response;
      console.log(response);
      this.applydate=this.appliedcandidate[0].created_at;
      this.app_id=this.appliedcandidate[0].application_id;
      }
    );
  }
  setemployeeid(id){
    localStorage.setItem('employee_id',id);
  }
  getusertoken(id){
    localStorage.setItem('user_token',id);
    this.router.navigateByUrl('/interview-schedule');
    this.ngOnInit();
  }
  setviewed(id){
    this.http.get(this.Token.baseurl()+'api/updateapplicationproview/'+id) .subscribe((result) => {
      this.ngOnInit();
    });
  }
  getuserdetail(){
    var profiletoken = localStorage.getItem('user_token');
     this.http.get<any>(this.Token.baseurl()+'api/getresumeprofile/'+profiletoken).subscribe(
       response => {
       this.userdetail = response;
      //  console.log(response);
        this.profirst_name=this.userdetail[0].f_name;
        this.prolast_name=this.userdetail[0].l_name;
        this.proemail=this.userdetail[0].email;
        this.pro_phone=this.userdetail[0].phone;
        this.pro_whatsapp=this.userdetail[0].whatsapp;
        this.pro_address=this.userdetail[0].location;
        this.citydata=this.userdetail[0].city;
        this.statedata=this.userdetail[0].state;
        this.pindata=this.userdetail[0].zip;
        this.pro_fb=this.userdetail[0].facebook;
        this.workas=this.userdetail[0].sub_job_types;
        this.workin=this.userdetail[0].job_types;
        this.transport=this.userdetail[0].istransportation;
        this.background=this.userdetail[0].isbackground;
        this.drugtest=this.userdetail[0].isdrugtest;
        this.felonies=this.userdetail[0].isfelonies;
        if(this.userdetail[0].picture!=null){
        this.pic=this.Token.baseurl()+'employee/'+this.userdetail[0].picture;
        }
        else{
         this.pic=this.Token.baseurl()+'employee/'+'profile.png';
        }
       }
     );
   }
   geteducationdetail(){
    var educationtoken = localStorage.getItem('user_token');
     this.http.get<any>(this.Token.baseurl()+'api/getresumeeducationdetails/'+educationtoken).subscribe(
       response => {
       this.educationdata = response;
      // console.log(response);
      // this.resume_edu_id=this.educationdata[0].id;
        this.school_name=this.educationdata[0].school_name;
        this.location_educat=this.educationdata[0].location;
        this.field_educat=this.educationdata[0].field_study;
        this.start_educat=this.educationdata[0].start_date;
        this.end_educat=this.educationdata[0].end_date;
        this.current_study=this.educationdata[0].job_title;
       }
     );
   }
   getdesiredetail(){
  
    var desiretoken = localStorage.getItem('user_token');
     this.http.get<any>(this.Token.baseurl()+'api/getdesiredjobworkschedule/'+desiretoken).subscribe(
       response => {
       this.desiredetail = response;
      //  this.desire_job=this.desiredetail[0].job_type;
      //  this.desire_schedule=this.desiredetail[0].work_schedule;
      //  this.desire_relocation=this.desiredetail[0].relocation;
      }
     );
   }
   getintervieweedetails(){
    var emplr_id = sessionStorage.getItem('employer_id');
     this.http.get<any>(this.Token.baseurl()+'api/getlatestinterview/'+emplr_id).subscribe(
       response => {
       this.scheduledetail = response;
       this.interviews_id=this.scheduledetail[0].interview_id;
       console.log(this.interviews_id);
       this.interviews_date=this.scheduledetail[0].interview_date;
       this.int_stdate=this.scheduledetail[0].start_time;
       this.int_endate=this.scheduledetail[0].end_time;
       this.int_vdolink=this.scheduledetail[0].video_link;
       this.int_msg=this.scheduledetail[0].message;
       this.int_phone=this.scheduledetail[0].phone_number;
       this.int_addr=this.scheduledetail[0].address;
      }
     );
   }
   onscheduleinterview(data){
    var j_id = localStorage.getItem('job_id');
    var empr_id = sessionStorage.getItem('employer_id');
    var e_id = localStorage.getItem('employee_id');
    const url = this.Token.baseurl() + 'api/addinterview';
    this.http.post(url, { interview_id: this.interviews_id, job_id: j_id, empoyee_id: e_id,employer_id: empr_id,interview_type: this.demo1TabIndex, interview_date: data.int_date,start_time: data.st_time, end_time: data.en_time, message: data.with_msg, video_link:data.vdo_link, phone_number:data.with_number, address:data.with_add })
      .subscribe((result) => {
        var txt='Interview scheduled with candidate Successfully';
        this.alertWithSuccess(txt);
        this.ngOnInit();
      });
  }
  onsubmitnote(data){
    console.log(data);
    const url = this.Token.baseurl() + 'api/updatenotinterested';
    this.http.post(url, { application_id:this.app_id, note: data.int_note, notint: data.yint })
      .subscribe((result) => {
        var txt='Note and status updated';
        this.alertWithSuccess(txt);
        this.ngOnInit();
      });
  }
  alertWithSuccess(txt) {
    Swal.fire('Thank you...', txt, 'success')
  }

}
