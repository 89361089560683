import { Component, OnInit, NgZone } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { TokenService } from '../../Services/token.service';
import { Router } from '@angular/router';
import { THIS_EXPR } from '@angular/compiler/src/output/output_ast';
import { NgModule, EventEmitter, Output } from '@angular/core';
import {NgbModal, ModalDismissReasons} from '@ng-bootstrap/ng-bootstrap';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-employer-login',
  templateUrl: './employer-login.component.html',
  styleUrls: ['./employer-login.component.css']
})
export class EmployerLoginComponent implements OnInit {

  status: boolean = true;
  clickEvent() {
    this.status = !this.status;
  }
  public form = {
    email: null,
    password: null
  };
  public error = null;

  closeResult = '';
  url1: any = '';
  scheduled: any;
  passw_set: any;
  

  constructor( private modalService: NgbModal, private http:HttpClient, private Token : TokenService, private router: Router, private ngZone: NgZone ) { }

  open(content) {
    this.modalService.open(content, {ariaLabelledBy: 'modal-basic-title'}).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
  }
  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return  `with: ${reason}`;
    }
  }

  onSubmitemployer() {
   
   return this.http.post(this.Token.baseurl()+'api/employerlogin', this.form).subscribe(

     // data => console.log(data),
     data => this.handleResponse(data),
     error => this.alertWithWarning('Please enter correct Email and Password')
    );
   }
   
   handleResponse(data) {
     console.log(data);
     if(data.isverifi != 1 || data.verifyemail == null){
      this.alertWithWarning('Please verify your Email. Your Email is not verified. Please wait until Admin send you verification mail.')
     }else{
    this.Token.handle(data.token);

    this.http.get<any>(this.Token.baseurl() + 'api/employerdetails/' + data.token).subscribe(
      (response: any) => {
        sessionStorage.setItem('employer_id', response.id);
        localStorage.setItem('employer_id', response.id);
      });
    this.router.navigateByUrl('/edit-employer');
    this.ngOnInit();
      }
   }

    handleError(error) {
    this.error = error.error.error;
  }

  public loadCss() {
    const node = document.createElement('link');
    node.href = 'assets/digital-marketing/css/style_new.css'; // put there your js file location
    node.rel = 'stylesheet';
    node.charset = 'utf-8';
   document.getElementsByTagName('head')[0].appendChild(node);
  }
  isemployee: boolean = false;
  isemployeer: boolean = false;
  
  resume_token: any;
  testtoken: any;
  islog: any;
  user_type: any;
  ngOnInit(): void {

    this.url1 = this.Token.baseurl();

    this.resume_token = localStorage.getItem("resume_token");
    this.testtoken = localStorage.getItem("token");
    if (this.resume_token != '' && this.resume_token != null) {
      this.isemployee = true;
      this.isemployeer = false;
      this.islog = false;

     
    }
    else {
      if (this.testtoken != '' && this.testtoken != null) {
        this.isemployee = false;
        this.isemployeer = true;
        this.islog = false;
        // console.log(this.islog + '2');
      }
      else {
        this.islog = true;
        this.isemployee = false;
        this.isemployeer = false;
        // console.log(this.islog + '3');
      }
    }

   if(this.Token.get()){
    this.http.get<any>(this.Token.baseurl() + 'api/employerdetails/' + this.Token.get()).subscribe((response: any) => {
      this.user_type = response.owner_type;
     // console.log(this.user_type);
      if(this.user_type == 2){
        this.router.navigateByUrl('/employer-dashboard');
       }      
    });
  }
    this.loadCss();
  }


  eyebtnstate: any = "fa-eye-slash";
  passwordtype: any = "password";
  passwordstate: boolean = true;
  
  passwordstatechng() {
    if (this.passwordstate === false) {
      this.eyebtnstate = "fa-eye";
      this.passwordtype = "text";
      this.passwordstate = true;
    } else {
      this.eyebtnstate = "fa-eye-slash";
      this.passwordtype = "password";
      this.passwordstate = false;
    }
  }

 
  
  onforgots_pass(data){
    this.http.post(this.Token.baseurl() + 'api/forgot_passw', { email: data.email })
      .subscribe((result) => {
        this.passw_set = result;
       
        var txt='Email sent for password reset!';
        this.alertWithSuccess(txt);
        this.ngOnInit();
      });
  }
  alertWithSuccess(txt) {
    Swal.fire('Thank you...', txt, 'success')
  }
  alertWithWarning(txt) {
    Swal.fire('Thank you...', txt, 'warning')
  }
  employer_communication() {
    this.router.navigate(['/communication']);
  }
  employer_data_management() {
    this.router.navigate(['/employer_communication']);
  }
  employer_manage_jobs() {
    this.router.navigate(['/jobs-employer']);
  }
  profile() {
    this.router.navigateByUrl('/profile-builder');
  }
  communication() {
    this.router.navigateByUrl('/communication');
  }
  employees() {
    this.router.navigateByUrl('/candidate-login');
  }
  jobsearch() {
    window.location.href = '/searchjobs';
  }
  blogs() {
    this.router.navigate(['/blog']);
  }
  employers() {
    this.router.navigate(['/employer-login']);
  }
  demo() {
    this.router.navigate(['/employer-demo']);
  }
  employer_signup() {
    this.router.navigate(['/employer-signup']);
  }
  faq_data() {
    this.router.navigate(['/faq_desc']);
  }
  dashboard() {
    this.router.navigateByUrl('/candidate-dashboard');
  }
  agrement(){
    this.router.navigateByUrl('/agreement');
  }
  privacy_policy() {
    this.router.navigate(['/privacy_policy']);
  }
  home_page(){
    window.location.href = '/';
   }
   invest_data(){
    this.router.navigate(['/invest']);
  }
  about_us(){
    this.router.navigate(['/about']);
  }
}
