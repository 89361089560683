import { Component, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { Router } from '@angular/router';
import { FormsModule, FormGroup } from '@angular/forms';
import { FormControl, FormArray, FormBuilder, ReactiveFormsModule } from '@angular/forms';
import { HttpClient,HttpHeaders } from '@angular/common/http';
import { TokenService } from '../../Services/token.service';
import { NgModule, EventEmitter, Output } from '@angular/core';

@Component({
  selector: 'app-view-profile',
  templateUrl: './view-profile.component.html',
  styleUrls: ['./view-profile.component.css']
})
export class ViewProfileComponent implements OnInit {
status: boolean = true;
  clickEvent(){
      this.status = !this.status;       
  }
  url1:any="https://hospy.snckapp.com/hospyback";
  viewp: any;
  name_p: any;
  email_p: any;
  phone_p: any;
  dob_p: any;
  coun_p: any;
  st_p: any;
  cty_p: any;

  skillview: any;
  skillv: string="" ;
   canskills: any;
   viewed: string="" ;
   viewedu: any;
   expview: string="" ;
   expviews: any;
  
  constructor( private http:HttpClient, private Token : TokenService,private router: Router ) { }

  ngOnInit(): void {
  this.getviewprofile();
  this.getskillview();
  this.getviewedu();
  this.getviewexp();
  }


getviewexp(){
  var token = this.Token.get();
  this.http.get<any>('https://hospy.snckapp.com/hospyback/api/employeeexperiencedetails/'+token).subscribe(
    response => {
    this.expviews = response;
    }
  );
}

  getviewedu(){
  var token = this.Token.get();
  this.http.get<any>(this.url1+'/api/employeeeducationdetails/'+token).subscribe(
    response => {
    this.viewedu = response;
    }
  );
}

  getskillview(){
  var token = this.Token.get();
  this.http.get<any>(this.url1+'/api/employeeskilldetails/'+token).subscribe(
    response => {
    this.skillview = response;
    }
  );
}
  getviewprofile(){
  var token = this.Token.get();
  this.http.get<any>(this.url1+'/api/employeedetails/'+token).subscribe(
    response => {
    this.viewp = response;
    // console.log(response);
     this.name_p=this.viewp.first_name;
     this.email_p=this.viewp.email;
     this.phone_p=this.viewp.phone;
     this.dob_p=this.viewp.dob;
     this.coun_p=this.viewp.country_id;
     this.st_p=this.viewp.state_id;
     this.cty_p=this.viewp.city_id;

     }
  );
}


}
