import { Component, OnInit, NgZone } from '@angular/core';
declare var $: any;
import { NgModule, EventEmitter, Output } from '@angular/core';
import { FormControl, FormArray, FormBuilder, ReactiveFormsModule } from '@angular/forms';
import { FormsModule, FormGroup } from '@angular/forms';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Router } from '@angular/router';
import { TokenService } from '../../Services/token.service';
import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
import Swal from 'sweetalert2';
import { MatChipsModule, MatChipInputEvent } from '@angular/material/chips';


declare const annyang: any;
@Component({
  selector: 'app-multi-step',
  templateUrl: './multi-step.component.html',
  styleUrls: ['./multi-step.component.css']
})
export class MultiStepComponent implements OnInit {

  transports = false;
  txtvalue: any;
  reemail: any;
  
  resume_skillsum: any;
  clicktrans(){
      this.transports =true;   
  }
  clicktr(){
    this.transports =false;     
  }
  bgcheck = false;
  clickbg(){
      this.bgcheck =true;   
  }
  clickbgy(){
    this.bgcheck =false;     
  }
  drugcheck = false;
  clickdrg(){
      this.drugcheck =true;   
  }
  clickdrgy(){
    this.drugcheck =false;     
  }
  felonycheck = false;
  clickfly(){
      this.felonycheck =true;   
  }
  clickfl(){
    this.felonycheck =false;     
  }


  job_work: any;
  employer_work: any;
  add_work: any;
  sdate_work: any;
  edate_work: any;
  duties_func: any;
  duties_work: any;
  title_work: any;
  workdetail: any;
  workde: string = "";

  addExpnum: any = true;
  profirst_name: any;
  prolast_name: any;
  proemail: any;
  pro_phone: any;
  pro_whatsapp: any;
  pro_address: any;
  citydata: any;
  statedata: any;
  pindata: any;
  pro_fb: any;
  pro_workas: any;
  profiledetail: any;
  resumeimage: any;
  filedata: any;
  desiredetail: any;
  desire_job: any;
  desire_schedule: any;
  desire_relocation: any;
  summaryd: any;
  summary_job: any;
  factdetail: any;
  transport: any;
  background: any;
  drugtest: any;
  felonies: any;
  pic: any;
  work_id: any = [];
  resume_summ_id: any;
  educationdetail: any;
  educatio: string = "";

  school_name: any;
  location_educat: any;
  field_educat: any;
  start_educat: any;
  end_educat: any;
  current_study: any;
  resume_edu_id: any = [];
  url: any;
  desire_jobname: any;
  desire_schedulename: any;
  dutiss: any;
  formattedaddresseducation: any = [];
  educationlocation: any = [];
  trnsprt_cmmnt: any;
  bg_cmmnt: any;
  drug_cmmnt: any;
  felony_cmmnt: any;
  age: any;
  eligible: any;
  travel: any;
  license: any;
  expectations: any;
  nxtrole: any;
  skilldescribe: any;
  soonstart: any;
  high_vol: any;
  nxtrle: string = "";
  skilldescrib: string = "";
  soonstrt: string = "";
  highvol: string = "";
  next_roles: any;
  skill_describes: any;
  soon_starts: any;
  highvol_exps: any;
  fctchip: any;
  nextrole: any;
  skil_describe: any;
  strt_soon: any;
  high_volm: any;

  fileEvent(e) {
    this.filedata = e.target.files[0];
  }

  jobschedule: any;
  schedule: string = "";
  jobworktype: any;
  jobworktyp: string = "";

  closeResult = '';
  jobtypes: any;
  depend: any;
  depen: string = "";
  jobtype: string = "";
  jobrole: any;
  jobrle: string = "";
  duties: any ="";
  duti: string = "";
  pro_lnk: any;	
  url1: any = '';
  // url1:any="http://127.0.0.1:8000";
  sociales: any;
  socia: string = "";
  workas: any = '0';
  iworkas: any = '0';
  workin: any = '0';
  iworkin: any = '0';
  formattedaddress = " ";
  // citydata=" ";
  // statedata=" ";
  // pindata=" ";
  addata = " ";
  options = {
    componentRestrictions: {
      country: ["USA"]
    }
  }
  public AddressChange(address: any) {
    //setting address from API to local variable
    this.formattedaddress = address.address_components;
    var html_attributions = address.html_attributions;
    //console.log(address.formatted_address);
    this.pro_address = address.formatted_address;
    for (const component of address.address_components) {
      const componentType = component.types[0];
      switch (componentType) {
        case "street_number": {


          break;
        }

        case "route": {
          //  this.pro_address = `${component.long_name}`;

          break;
        }

        case "postal_code": {
          this.pindata = `${component.long_name}`;
          break;
        }

        case "postal_code_suffix": {
          this.pindata = `${this.pindata}-${component.long_name}`;
          break;
        }
        case "locality":
          this.citydata = component.long_name;
          break;

        case "administrative_area_level_1": {
          this.statedata = component.short_name;
          break;
        }
        case "country":
          //this. = component.long_name;
          break;
      }

      /*const componentType = component.types[0];
     console.log(this.formattedaddress);
     var add= this.formattedaddress.split(',');
     console.log(add);
     this.citydata=add[add.length-3];
     this.statedata=add[add.length-2];
     this.pindata=this.statedata.substring(this.statedata.length-6);
     this.statedata=this.statedata.substring(0,this.statedata.length-6);
    // console.log(add);*/
    }
  }

  // formattedaddressexp[0]=" ";
  public AddressChangeexp(address: any, i) {
    // console.log(address);
    this.formattedaddressexpm[i] = address.formatted_address
    this.expemployerm[i] = address.name;
    // var add= this.formattedaddressexp.split(',');
    // this.addata=add[add.length-3];
  }

  //School Api for Education /Certification

  public Schooleducation(address: any, i) {
    this.formattedaddresseducation[i] = address.formatted_address
    this.educationlocation[i] = address.name;
  }
  //School Api for Education /Certification

  dtdisable: any = [];

  skilsdescval: any = "";
  getskils(skdata) {
    var csk = document.getElementById('skilsdesc')!.innerHTML;
    skdata = csk + skdata;
    this.skilsdescval = skdata;
  }
  removeskils(skdata) {
    var csk = document.getElementById('skilsdesc')!.innerHTML;
    csk = csk.replace(skdata, '');
    this.skilsdescval = csk;

  }
  // sumdescval:any="";
  // getsummery(sdata){
  // var ck= document.getElementById('summerydesc')!.innerHTML;
  // sdata=ck+sdata;
  // this.sumdescval=sdata;

  // }

  // removesummary(sdata){
  //  var ck= document.getElementById('summerydesc')!.innerHTML;
  // ck=ck.replace(sdata,'');
  // this.sumdescval=ck;
  // }

  summdescval: any = "";
  getsumm(summdata) {
  //  alert(summdata);
    //var summck = document.getElementById('summdesc').innerHTML;
   // alert(summck);
   if(this.summdescval){
    summdata =  this.summdescval +' , '+ summdata;
    }
    else{
      summdata =  this.summdescval + summdata;
    }
    this.summdescval = summdata;
    //alert(this.summdescval);
  }
  removesumm(summdata) {
    //var summck = document.getElementById('summdesc').innerHTML;
    this.summdescval =  this.summdescval.replace(' , '+summdata, '');
    //this.summdescval = summck;
  }

  voiceActiveSectionDisabled: boolean = true;
  voiceActiveSectionError: boolean = false;
  voiceActiveSectionSuccess: boolean = false;
  voiceActiveSectionListening: boolean = false;
  voiceText: any;

  educationForm: FormGroup;
  productForm: FormGroup;
  sclForm: FormGroup;

  constructor(private http: HttpClient, private router: Router, private ngZone: NgZone, private ed: FormBuilder, private fb: FormBuilder, private sc: FormBuilder, private Token: TokenService, private modalService: NgbModal) {

    this.educationForm = this.ed.group({
      educationss: this.ed.array([]),
    });
    this.productForm = this.fb.group({
      quantities: this.fb.array([]),
    });

    this.sclForm = this.sc.group({
      work_as: '',
      f_name: '',
      l_name: '',
      location: '',
      city: '',
      state: '',
      zip: '',
      email: '',
      phone: '',
      whatsapp: '',
      facebook: '',

    });

  }

  open(content) {
    this.ngOnInit();
    this.modalService.open(content, { ariaLabelledBy: 'modal-basic-title' }).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
  }
  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }

  initializeVoiceRecognitionCallback(): void {
    annyang.addCallback('error', (err) => {
      if (err.error === 'network') {
        this.voiceText = "Internet is require";
        annyang.abort();
        this.ngZone.run(() => this.voiceActiveSectionSuccess = true);
      } else if (this.voiceText === undefined) {
        this.ngZone.run(() => this.voiceActiveSectionError = true);
        annyang.abort();
      }
    });

    annyang.addCallback('soundstart', (res) => {
      this.ngZone.run(() => this.voiceActiveSectionListening = true);
    });

    annyang.addCallback('end', () => {
      if (this.voiceText === undefined) {
        this.ngZone.run(() => this.voiceActiveSectionError = true);
        annyang.abort();
      }
    });

    annyang.addCallback('result', (userSaid) => {



      this.ngZone.run(() => this.voiceActiveSectionError = false);

      let queryText: any = userSaid[0];

      annyang.abort();

      this.voiceText = queryText;

      var summck = document.getElementById('summdesc')!.innerHTML;
      //summdata=summck+summdata;
      this.summdescval = summck + queryText;

      this.ngZone.run(() => this.voiceActiveSectionListening = false);
      this.ngZone.run(() => this.voiceActiveSectionSuccess = true);
    });
  }

  startVoiceRecognition(): void {
    this.voiceActiveSectionDisabled = false;
    this.voiceActiveSectionError = false;
    this.voiceActiveSectionSuccess = false;
    this.voiceText = undefined;

    if (annyang) {
      let commands = {
        'demo-annyang': () => { }
      };

      annyang.addCommands(commands);

      this.initializeVoiceRecognitionCallback();

      annyang.start({ autoRestart: false });
    }
  }

  closeVoiceRecognition(): void {
    this.voiceActiveSectionDisabled = true;
    this.voiceActiveSectionError = false;
    this.voiceActiveSectionSuccess = false;
    this.voiceActiveSectionListening = false;
    this.voiceText = undefined;

    if (annyang) {
      annyang.abort();
    }
  }

  @Output() featureSelected = new EventEmitter<string>();

  onSelect(feature: string) {
    this.featureSelected.emit(feature);
    this.loadedFeature = feature;
  }

  activeheader(type: any) {
    for (let i = 0; i <= 6; i++) {
      // alert(type);
      $('#EXPR' + i).removeClass('active');
      $('#step' + i).removeClass('active');
      $('#EXPR' + i).removeClass('cursor-default step-completed');
    }
    for (let i = 0; i <= type; i++) {
      // alert(type);
      $('#EXPR' + i).addClass('active');

      $('#EXPR' + i).addClass('cursor-default step-completed');
    }

    $('#step' + type).addClass('active');

    $('#step1').addClass('scr');

  }
  loadedFeature = 'fst';
  onNavigate(feature: string) {
    this.loadedFeature = feature;
  }



  ngOnInit(): void {

    this.reloadfunction();
    this.getdesiredjobschedule();
    this.getdesiredjobwork();
    //this.addEducation();
    this.getdesiredetail();
    this.geteducationdata();
    this.getjobtype();

    this.getworkdetail();
    this.getjobrole();

    this.get_highvol_experience();
    this.getsoon_start();
    this.getskilldescribe_you();
    this.getnextrole();
    this.getfactchips();
  }
  reloadfunction() {

    //this.productForm.reset();





    //this.geteducationdata();
    this.getsummarydetail();
    this.getprofiledetail();
  }
  educationss(): FormArray {
    return this.educationForm.get("educationss") as FormArray
  }
  newEducation(id: any, school_name: any, location: any, field_study: any, start_date: any, end_date: any, current_study: any): FormGroup {
    return this.ed.group({
      id: id,
      school_name: school_name,
      location: location,
      field_study: field_study,
      start_date: start_date,
      end_date: end_date,
      current_study: current_study,

    })
  }
  addEducation() {
    this.educationss().push(this.newEducation('', '', '', '', '', '', ''));
  }


  removeEducation(i: number) {
    this.educationss().removeAt(i);
  }

  quantities(): FormArray {
    return this.productForm.get("quantities") as FormArray
  }
  newQuantity(id: any, job_title: any, employer: any, address: any, start_date: any, end_date: any, current_work: any): FormGroup {
    return this.fb.group({
      resume_work_id: id,
      job_title: job_title,

      employer: employer,
      address: address,
      start_date: start_date,
      end_date: end_date,
      current_work: current_work,
      // job_duties: '',
      // job_duties: this.fb.array([])
    })
  }


  expdivid: any = 0;
  addQuantity() {
    this.quantities().push(this.newQuantity('', '', '', '', '', '',''));

    
    this.dtdisable[this.expdivid] = false;
    var n = this.expdivid - 1
    $('#exp' + n).hide();
    $('#expedit' + n).show();

    this.addExpnum = false;
    this.expdivid = this.expdivid + 1;

    var title = $('#job_title' + n).val();
    $('#expTitle' + n).html(title);
    $('#addclas' + n).addClass('expdesign');
  }

  editQuantity(i) {
    $('#exp' + i).show();
    $('#exphide' + i).show();
    $('#expedit' + i).hide();
    var title = $('#job_title' + i).val();
    $('#expTitle' + i).html('');
    $('#addclas' + i).removeClass('expdesign');
  }
  hideQuantity(i) {
    $('#exp' + i).hide();
    $('#exphide' + i).hide();
    $('#expedit' + i).show();
    $('#addclas' + i).addClass('expdesign');
    var title = $('#job_title' + i).val();
    $('#expTitle' + i).html(title);
  }

  addexpvalid(i) {
    var loc = $('#location' + i).val();
    if (loc.length > 0) {
      this.addExpnum = true;
    }
  }

  removeQuantity(i: number) {
    this.quantities().removeAt(i);
  }

  deletework(id) {
    this.http.get(this.Token.baseurl() + 'api/delworkexp/' + id).subscribe((result) => {
      this.getworkdetail();
    });
  }
  deleteeducation(id) {
    this.http.get(this.Token.baseurl() + 'api/deleducation/' + id).subscribe((result) => {
      this.getworkdetail();
    });
  }

  getjobtype() {
    this.http.get<any>(this.Token.baseurl() + 'api/getjobtype').subscribe(
      response => {
        this.jobtypes = response;
      }
    );
  }

  dependWorkas(event) {
    const dependVal = event.target.value;
    this.http.get<any>(this.Token.baseurl() + 'api/getsubjobtype/' + dependVal).subscribe(
      response => {
        this.depend = response;

      }
    );
  }
  dependWorkasedit(id) {
    //console.log(id);
    this.http.get<any>(this.Token.baseurl() + 'api/getsubjobtype/' + id).subscribe(
      response => {
        this.depend = response;

      }
    );
  }
  getjobrole() {
    this.http.get<any>(this.Token.baseurl() + 'api/getjobtype').subscribe(
      response => {
        // console.log(response);
        this.jobrole = response;
      }
    );
  }

  getjobrole1(i: any, jb: any) {
    this.http.get<any>(this.Token.baseurl() + 'api/getjobtype').subscribe(
      response => {
        //this.jobrolemod[0] = this.jobr;
        this.jobrole[i] = response;
        // console.log(response);
        // console.log(jb);
        this.jobrolemod[i] = jb;

      }
    );
  }

  dependDuties(event, i) {
    //console.log(i);

    const dependDut = event.target.value;
    //console.log(dependDut);
    this.http.get<any>(this.Token.baseurl() + 'api/getsubjobtype/' + dependDut).subscribe(
      response => {
        this.duties = response;
        // console.log(response);
      }
    );
  }
  dependDuties1(id) {
    //console.log(id);

    const dependDut = id;
    //console.log(dependDut);
    this.http.get<any>(this.Token.baseurl() + 'api/getsubjobtype/' + dependDut).subscribe(
      response => {
        this.duties = response;
       // console.log(response);
       
        // 
       
      }
    );
  }

  scls(): FormArray {
    return this.sclForm.get("scls") as FormArray
  }



  tok: any;
  onAdddetai(data) {
    // console.log(data);
    var uptoken = localStorage.getItem('resume_token');
    var token = localStorage.getItem('token');
    if (token == null) {
      token = 'none';
    }
    // console.log(token);
    const url = this.Token.baseurl() + 'api/addprofiledetail';
    this.http.post(url, {
      resume_token: uptoken, work_as: data.work_as, work_in: data.work_in,
      f_name: data.f_name, l_name: data.l_name, location: data.location, city: data.city,
      state: data.state, zip: data.zip, email: data.email, phone: data.phone, whatsapp: data.whatsapp,
      facebook: data.facebook, linkedin: data.linkedin, user_id: token, ip: 1
    })
      .subscribe((result) => {
        this.tok = result;
        this.dependDuties1(data.work_as);
         console.log(this.tok);
        
        if(this.tok.status == false)
        { 
          this.txtvalue = this.tok.message;
          this.reemail = this.tok.email;
          if(this.tok.is_first == 1)
          {
            this.alertWithSuccess(this.txtvalue);
           
            this.router.navigateByUrl('/candidate-login');
          }
        else  if(this.tok.is_first == 2)
          {
            this.alertWithWarning(this.txtvalue);
           
            this.router.navigateByUrl('/candidate-login');
          }
         else if(this.tok.is_first == 3)
          {
            this.alertWithWarning3(this.txtvalue);
           
            this.router.navigateByUrl('/candidate-login');
          }
         else if(this.tok.is_first == 4)
          {
            localStorage.setItem('email', this.reemail);
            this.alertWithWarning4(this.txtvalue);
           
            this.router.navigateByUrl('/candidate-login');
          }
          else{
          this.alertWithWarning(this.txtvalue);
          this.router.navigateByUrl('/candidate-login');
          }
          // alert(this.tok.message);
        } 
        else if(token != 'none'){
         
          this.onSelect('scnd');
        this.activeheader(1);
        }
        else { 
          localStorage.setItem('resume_token', this.tok.resume_token);
        if (token == 'none') {
          localStorage.setItem('token', this.tok.token);
        }
        localStorage.setItem('workas', data.work_as);
          this.onSelect('scnd');
        this.activeheader(1);
        var txt = 'Mail sent for Password set.Please set your password first!';
         
        this.alertWithSuccess(txt);
         }
        
        // this.addQuantity();
        // this.quantities().push(this.newQuantity('','','','','','','',''));
      });
   // this.reloadfunction();
  }


  onSubmitnew() {
    var jtoken = localStorage.getItem('resume_token');
    var token = localStorage.getItem('token');
    if (token == null) {
      token = 'none';
    }
    var duti = '';

    for (var v in this.productForm.value) {
      var duti = '';
      for (var i = 0; i < this.productForm.value[v].length; i++) {
        var duti = '';
        var data = this.productForm.value[v][i];
        //console.log(data);
        /*  var summarytoken = localStorage.getItem('resume_token');
         this.http.get<any>(this.Token.baseurl()+'api/gettempdutiesdata/'+summarytoken+'/'+i).subscribe(
           response => {
           this.duti_data=response;
           for(var b=0;b<this.duti_data.length;b++)
           {
             if(this.duti_data[b].status==1){
   duti=this.duti_data[b].name+'|'+duti;
             }
            this.http.get<any>(this.Token.baseurl()+'api/deltempdutiesdata/'+this.duti_data[b].id+'/'+summarytoken).subscribe(
               response => {
               });
           }*/


        const url = this.Token.baseurl() + 'api/addworkdetail';
        this.http.post(url, {
          resume_token: jtoken, job_title: this.expjob_workm[i], start_date: this.expsdatew[i],
          end_date: this.expenddatewend[i], location: this.expemployerm[i], employer: this.formattedaddressexpm[i], user_id: token, current_work: this.icw[i], resume_work_id: this.work_id[i]
        })
          .subscribe((result) => {
            this.reloadfunction(); //reload the table

            this.onSelect('sixth');
            this.activeheader(5);

          });
        //});
      }
    }
    this.reloadfunction(); //reload the table
  }



  onSubmitskil(data) {
    var stoken = localStorage.getItem('resume_token');
    // var ski= document.getElementById('skilsdesc').innerHTML;
    const url = this.Token.baseurl() + 'api/addskilldetail';
    this.http.post(url, { resume_token: stoken, skill: data.skilsdesc, user_id: 1 })
      .subscribe((result) => {
        this.reloadfunction(); //reload the table
        this.onSelect('fifth');
        this.activeheader(4);
      });
  }

  sub_job_types: any = '';
  job_types: any = '';

  getprofiledetail() {
    var profiletoken = localStorage.getItem('resume_token');
    this.http.get<any>(this.Token.baseurl() + 'api/getresumeprofile/' + profiletoken).subscribe(
      response => {
        this.profiledetail = response;
        console.log(response);
        // if(this.profiledetail.length>0){
          if (this.profiledetail != '') {
         console.log(response);
        this.profirst_name = this.profiledetail[0].f_name;
        this.prolast_name = this.profiledetail[0].l_name;
        this.proemail = this.profiledetail[0].email;
        this.pro_phone = this.profiledetail[0].phone;
        this.pro_whatsapp = this.profiledetail[0].whatsapp;
        this.pro_address = this.profiledetail[0].location;
        this.citydata = this.profiledetail[0].city;
        this.statedata = this.profiledetail[0].state;
        this.pindata = this.profiledetail[0].zip;
        this.pro_fb = this.profiledetail[0].facebook;
	      this.pro_lnk = this.profiledetail[0].linkedin;
        this.workas = this.profiledetail[0].work_as;
        this.sub_job_types = this.profiledetail[0].sub_job_types;
        this.job_types = this.profiledetail[0].job_types;

        this.workin = this.profiledetail[0].work_in;
        this.transport = this.profiledetail[0].istransportation;
        this.background = this.profiledetail[0].isbackground;
        this.drugtest = this.profiledetail[0].isdrugtest;
        this.felonies = this.profiledetail[0].isfelonies;
        
        
        this.drug_cmmnt = this.profiledetail[0].drugtest_comment;

        if (this.profiledetail[0].background_comment != null) {
          this.bg_cmmnt = this.profiledetail[0].background_comment;
        }else{
          this.bg_cmmnt = ' ';
        }
        if (this.profiledetail[0].transport_comment != null) {
          this.trnsprt_cmmnt = this.profiledetail[0].transport_comment;
        }else{
          this.trnsprt_cmmnt = ' ';
        }
        
        if (this.profiledetail[0].felony_comment != null) {
          this.felony_cmmnt = this.profiledetail[0].felony_comment;
        }else{
          this.felony_cmmnt = ' ';
        }
        this.age = this.profiledetail[0].age_eighteen;
        this.eligible = this.profiledetail[0].eligible_towork;
        this.travel = this.profiledetail[0].travel_willing;
        this.license = this.profiledetail[0].license_valid;
        this.expectations = this.profiledetail[0].hourly_expectation;
        this.dutiss  = this.profiledetail[0].job_duties;
         //console.log(this.dutiss);
         if (this.profiledetail[0].picture != null) {
          this.pic = this.Token.baseurl() + 'employee/' + this.profiledetail[0].picture;
          // console.log(this.pic);
        }
        else {
          this.pic = this.Token.baseurl() + 'employee/' + 'profile.png';
        }

        var dt = this.dutiss.split('|');

          for (let index = 0; index < dt.length; index++) {
            if (dt[index] != '') {
              // console.log(dt[index]);
              this.changeSelecteddutis('s', dt[index], dt[index]);
            }
          }
          
          this.next_roles  = this.profiledetail[0].next_role;
          var rl = this.next_roles.split('|');
          for (let index = 0; index < rl.length; index++) {
            if (rl[index] != '') {
              this.changeSelectedrole('s', rl[index], rl[index]);
            }
          }
          this.skill_describes  = this.profiledetail[0].skill_describe;
          var skl = this.skill_describes.split('|');
          for (let index = 0; index < skl.length; index++) {
            if (skl[index] != '') {
              this.changeSelecteddescribe('s', skl[index], skl[index]);
            }
          }
          this.soon_starts  = this.profiledetail[0].soon_start;
          var sn = this.next_roles.split('|');
          for (let index = 0; index < sn.length; index++) {
            if (sn[index] != '') {
              this.changeSelectedsoon('s', sn[index], sn[index]);
            }
          }
          this.highvol_exps  = this.profiledetail[0].highvol_exp;
          var exps = this.next_roles.split('|');
          for (let index = 0; index < exps.length; index++) {
            if (exps[index] != '') {
              this.changeSelectedhighvol('s', exps[index], exps[index]);
            }
          }

       
        this.dependWorkasedit(this.workas);
      }
    }
    );
  }

  getfactchips() {
    var chipstoken = localStorage.getItem('resume_token');
    this.http.get<any>(this.Token.baseurl() + 'api/getfactchips/' + chipstoken).subscribe(
      response => {
        this.fctchip = response;
        this.nextrole = this.fctchip.next_role_name;
        this.skil_describe = this.fctchip.skilldescribe_name;
        this.strt_soon = this.fctchip.soonstart_name;
        this.high_volm = this.fctchip.highvol_name;
      }
    );
  }

  getadatework(event, i) {
    this.expsdatew[i] = event;
    
  }
  getadateworkend(event, i) {
    this.expenddatewend[i] = event;
  }
  expsdatew: any = [];
  expenddatewend: any = [];

  getadatef(event, i) {
    this.expsdatekm[i] = event;
   
  }
  getadatet(event, i) {
    this.expenddatekm[i] = event;
  }
  jobrolemod: any = [];
  joid: any = [];
  explocationm: any = [];
  expemployerm: any = [];
  expjob_workm: any = [];
  expsdatekm: any = [];
  expenddatekm: any = [];
  formattedaddressexpm: any = [];
  icw: any = [];
  jobr: any;
  getworkdetail() {
    for (var i1 = 0; i1 <= this.quantities().length; i1++) {
      this.quantities().removeAt(i1);
    }
    var worktoken = localStorage.getItem('resume_token');
    this.http.get<any>(this.Token.baseurl() + 'api/getresumeworkdetails/' + worktoken).subscribe(
      response => {
        this.workdetail = response;
        this.expdivid = this.workdetail.length;
        // console.log(this.workdetail);
        for (let i = 0; i < this.workdetail.length; i++) {
          this.work_id[i] = this.workdetail[i].id;
          this.expjob_workm[i] = this.job_work = this.workdetail[i].job_title;
          this.expemployerm[i] = this.employer_work = this.workdetail[i].employer;
          this.title_work = this.workdetail[i].job_title;
          this.expsdatew[i] = this.sdate_work = this.workdetail[i].start_date;
          this.expenddatewend[i] = this.edate_work = this.workdetail[i].end_date;
          this.formattedaddressexpm[i] = this.add_work = this.workdetail[i].location;
        
          this.icw[i] = this.workdetail[i].current_work;

          //console.log(this.jobrolemod[i]);
          this.dtdisable[i] = this.workdetail[i].current_work;

          this.quantities().push(this.newQuantity(this.workdetail[i].id, this.workdetail[i].job_title, this.workdetail[i].employer, this.workdetail[0].location, this.workdetail[i].start_date, this.workdetail[i].end_date, this.workdetail[0].current_work));

        }
        //console.log(this.jobr);

      }
    );
  }
  geteducationdata() {
    for (var i = 0; i <= this.educationss().length; i++) {
      this.educationss().removeAt(i);

    }
    var educationtoken = localStorage.getItem('resume_token');
    this.http.get<any>(this.Token.baseurl() + 'api/getresumeeducationdetails/' + educationtoken).subscribe(
      response => {
        this.educationdetail = response;
       // console.log(response);
        if (this.educationdetail.length > 0) {
          //console.log(this.educationdetail.length);
          for (let i = 0; i < this.educationdetail.length; i++) {
            this.resume_edu_id[i] = this.educationdetail[i].id;
            this.educationlocation[i] = this.educationdetail[i].school_name;
            this.formattedaddresseducation[i] = this.educationdetail[i].location;
            this.field_educat = this.educationdetail[i].field_study;
            this.expsdatekm[i] = this.educationdetail[i].start_date;
            this.expenddatekm[i] = this.educationdetail[i].end_date;
            this.current_study = this.educationdetail[i].current_study;
            this.educationss().push(this.newEducation(this.educationdetail[i].id, this.educationdetail[i].educationlocation, this.educationdetail[i].formattedaddresseducation, this.educationdetail[i].field_study, this.educationdetail[i].start_date, this.educationdetail[i].end_date, this.educationdetail[i].current_study));
          }
        }
      }
    );
  }

  work_schedule_id: any;
  getdesiredetail() {

    var desiretoken = localStorage.getItem('resume_token');
    this.http.get<any>(this.Token.baseurl() + 'api/getdesiredjobworkschedule/' + desiretoken).subscribe(
      response => {
        this.desiredetail = response;
      //  console.log(response);
        if (this.desiredetail != '') {
          this.desire_jobname = this.desiredetail.job_type_name;
          this.desire_schedulename = this.desiredetail.work_schedule_name;


          this.work_schedule_id = this.desiredetail.id;
          this.desire_job = this.desiredetail.job_type;

          var dj = this.desire_job.split('|');

          for (let index = 0; index < dj.length; index++) {
            if (dj[index] != '') {
              // console.log(dj[index]);
              this.changeSelectedjob('s', dj[index], dj[index]);
            }
          }

          this.desire_schedule = this.desiredetail.work_schedule;
          var dsch = this.desire_schedule.split('|');
          for (let index = 0; index < dsch.length; index++) {
            // console.log(dsch[index]);
            if (dsch[index] != '') {

              this.changeSelecteddesire('s', dsch[index], dsch[index]);
            }
          }
          this.desire_relocation = this.desiredetail.relocation;
          // console.log(this.desire_job);
        }
      }
    );
  }
  getsummarydetail() {
    var summarytoken = localStorage.getItem('resume_token');
    this.http.get<any>(this.Token.baseurl() + 'api/getresumesummarydetails/' + summarytoken).subscribe(
      response => {
        this.summaryd = response;
        this.summdescval='';
        if (this.summaryd.length > 0) {
          this.summary_job = this.summaryd[0].summary;
          //  console.log(this.summary_job);
          this.summdescval= this.summdescval + this.summary_job;
          this.resume_summ_id = this.summaryd[0].id;
          this.resume_skillsum = this.summaryd[0].skill_summary;
        }
      }
    );
  }


  selectedChips: any[] = [];
  duti_data: any = '';
  gettempdutiesdata(ind1: any) {
    //console.log(ind1);
    var summarytoken = localStorage.getItem('resume_token');
    this.http.get<any>(this.Token.baseurl() + 'api/gettempdutiesdata/' + summarytoken + '/' + ind1).subscribe(
      response => {
        this.duti_data = response;
        //console.log(this.duties[ind1].length);
        var d = '';
        for (var j = 0; j < this.duties.length; j++) {
          $('#matchiplist' + j + ind1).removeClass('chiplistactive');
        }
        for (var k = 0; k < this.duti_data.length; k++) {

          if (this.duti_data[k].status == 1) {
            $('#matchiplist' + this.duti_data[k].ind2 + this.duti_data[k].ind1).addClass('chiplistactive');
            d = this.duti_data[k].name + '|' + d;
          }
          else {
            $('#matchiplist' + this.duti_data[k].ind2 + this.duti_data[k].ind1).removeClass('chiplistactive');
          }

        }
      //  console.log(d);
        this.selectedChips[ind1] = d;
      }
    );
  }

  changeSelected(parameter: string, query: string, ind1: any) {
    //console.log(this.selectedChips[ind2].length);
    /* console.log(this.selectedChips[ind2]);
    if(this.selectedChips.length>0){
       for(var n=0;n<this.selectedChips[ind2].length;n++)
        {
          console.log(this.selectedChips[ind2]);
          if(this.selectedChips[ind2][n]==query)
          {
            this.selectedChips[ind2].splice(n, 1);
            $('#matchiplist'+ind+ind2).removeClass('chiplistactive');
          }
          else{
            this.selectedChips[ind2].push(query);
            $('#matchiplist'+ind+ind2).addClass('chiplistactive');
          }
        }
        this.selectedChips[ind2].push(query);
      }
      else{
        this.selectedChips[ind2].push(query);
      }*/
    /*const index = this.selectedChips[ind2].indexOf(query);
    if (index >= 0) {
      
  
      
    } else {
     
  
      
    }*/
    //this.duti_data=query+'|'+this.duti_data;
    //console.log(this.duti_data);
    var stoken = localStorage.getItem('resume_token');
    const url = this.Token.baseurl() + 'api/tempaddduties';
    this.http.post(url, { resume_token: stoken, ind1: ind1, name: query })
      .subscribe((result) => {
        this.duti_data = result;
        var d = '';

        for (var k = 0; k < this.duti_data.length; k++) {
          if (this.duti_data[k].status == 1) {
            $('#matchiplist' + this.duti_data[k].ind1).addClass('chiplistactive');
            d = this.duti_data[k].name + '|' + d;
          }
          else {
            $('#matchiplist' + this.duti_data[k].ind1).removeClass('chiplistactive');
          }
        }
      
      });

  }

  selectedDutis: any[] = [];
  dutis_data: any = '';
  changeSelecteddutis(parameter: string, query: string, ind: any) {

    const index = this.selectedDutis.indexOf(query);
   
    if (index >= 0) {
      this.selectedDutis.splice(index, 1);
      //console.log(this.selectedDutis);
      $('#matchiplistdutis' + ind).removeClass('chiplistactive');
      this.dutis_data = this.dutis_data.replace(query + '|', "");

    } else {
      //console.log(ind);
      this.selectedDutis.push(query);

      $('#matchiplistdutis' + ind).addClass('chiplistactive');
    }
    this.dutis_data = query + '|' + this.dutis_data;
  }

  selectedChipsdesire: any[] = [];
  schedule_data: any = '';
  changeSelecteddesire(parameter: string, query: string, ind: any) {

    const index = this.selectedChipsdesire.indexOf(query);
    if (index >= 0) {
      this.selectedChipsdesire.splice(index, 1);

      $('#matchiplistdesire' + ind).removeClass('chiplistactive');
      this.schedule_data = this.schedule_data.replace(query + '|', "");

    } else {
      this.selectedChipsdesire.push(query);

      $('#matchiplistdesire' + ind).addClass('chiplistactive');
    }
    this.schedule_data = query + '|' + this.schedule_data;
  }

  selectedChipsjob: any[] = [];
  jobtype_data: any = '';
  changeSelectedjob(parameter: string, query: string, ind: any) {

    const index = this.selectedChipsjob.indexOf(query);

    if (index >= 0) {
      this.selectedChipsjob.splice(index, 1);

      $('#matchiplistjob' + ind).removeClass('chiplistactive');
      this.jobtype_data = this.jobtype_data.replace(query + '|', "");
    } else {
      this.selectedChipsjob.push(query);
      // console.log(this.selectedChipsjob);
      $('#matchiplistjob' + ind).addClass('chiplistactive');
      this.jobtype_data = query + '|' + this.jobtype_data;
    }

    //  console.log(this.jobtype_data);
  }

  selectedChipsrole: any[] = [];
  nxtrole_data: any = '';
  changeSelectedrole(parameter: string, query: string, ind: any) {
    const index = this.selectedChipsrole.indexOf(query);
    if (index >= 0) {
      this.selectedChipsrole.splice(index, 1);
      $('#matchiplistrole' + ind).removeClass('chiplistactive');
      this.nxtrole_data = this.nxtrole_data.replace(query + '|', "");
    } else {
      this.selectedChipsrole.push(query);
      $('#matchiplistrole' + ind).addClass('chiplistactive');
      this.nxtrole_data = query + '|' + this.nxtrole_data;
    }
  }
  selectedChipsdescribe: any[] = [];
  skdescribe_data: any = '';
  changeSelecteddescribe(parameter: string, query: string, ind: any) {
    const index = this.selectedChipsrole.indexOf(query);
    if (index >= 0) {
      this.selectedChipsrole.splice(index, 1);
      $('#matchiplistdescribe' + ind).removeClass('chiplistactive');
      this.skdescribe_data = this.skdescribe_data.replace(query + '|', "");
    } else {
      this.selectedChipsrole.push(query);
      $('#matchiplistdescribe' + ind).addClass('chiplistactive');
      this.skdescribe_data = query + '|' + this.skdescribe_data;
    }
  }

  selectedChipssoon: any[] = [];
  soon_data: any = '';
  changeSelectedsoon(parameter: string, query: string, ind: any) {
    const index = this.selectedChipssoon.indexOf(query);
    if (index >= 0) {
      this.selectedChipssoon.splice(index, 1);
      $('#matchiplistsoon' + ind).removeClass('chiplistactive');
      this.soon_data = this.soon_data.replace(query + '|', "");
    } else {
      this.selectedChipssoon.push(query);
      $('#matchiplistsoon' + ind).addClass('chiplistactive');
      this.soon_data = query + '|' + this.soon_data;
    }
  }
  selectedChipshighvol: any[] = [];
  highvol_data: any = '';
  changeSelectedhighvol(parameter: string, query: string, ind: any) {
    const index = this.selectedChipshighvol.indexOf(query);
    if (index >= 0) {
      this.selectedChipshighvol.splice(index, 1);
      $('#matchiplisthighvol' + ind).removeClass('chiplistactive');
      this.highvol_data = this.highvol_data.replace(query + '|', "");
    } else {
      this.selectedChipshighvol.push(query);
      $('#matchiplisthighvol' + ind).addClass('chiplistactive');
      this.highvol_data = query + '|' + this.highvol_data;
    }
  }


  onSubmitneweducation() {
    var edutoken = localStorage.getItem('resume_token');
    var token = localStorage.getItem('token');
    if (token == null) {
      token = 'none';
    }
    for (var v in this.educationForm.value) {
      for (var i = 0; i < this.educationForm.value[v].length; i++) {
        var data = this.educationForm.value[v][i];

        const url = this.Token.baseurl() + 'api/addeducationdetail';
        this.http.post(url, { resume_token: edutoken, resume_edu_id: data.id, school_name: this.educationlocation[i], location: this.formattedaddresseducation[i], field_study: data.field_study, start_date: data.start_date, end_date: data.end_date, current_study: data.current_study, user_id: token })
          .subscribe((result) => {
            this.reloadfunction(); //reload the table
            this.onSelect('forth');
            this.activeheader(3);
          });

      }
    }
  }
  getdesiredjobschedule() {
    this.http.get<any>(this.Token.baseurl() + 'api/getdesired_work_schedule').subscribe(
      response => {
        this.jobschedule = response;
        // console.log(response);
      }
    );
  }
  getdesiredjobwork() {
    this.http.get<any>(this.Token.baseurl() + 'api/getdesired_job_types').subscribe(
      response => {
        this.jobworktype = response;
      }
    );
  }
  getnextrole() {
    this.http.get<any>(this.Token.baseurl() + 'api/getnext_role').subscribe(
      response => {
        this.nxtrole = response;
      }
    );
  }
  getskilldescribe_you() {
    this.http.get<any>(this.Token.baseurl() + 'api/getskill_describe_you').subscribe(
      response => {
        this.skilldescribe = response;
      }
    );
  }
  getsoon_start() {
    this.http.get<any>(this.Token.baseurl() + 'api/get_soon_start').subscribe(
      response => {
        this.soonstart = response;
      }
    );
  }
  get_highvol_experience() {
    this.http.get<any>(this.Token.baseurl() + 'api/get_highvol_exp').subscribe(
      response => {
        this.high_vol = response;
      }
    );
  }

  onSubmitdesired(data) {
    // console.log(data);

    var desiretoken = localStorage.getItem('resume_token');
    var token = localStorage.getItem('token');
    if (token == null) {
      token = 'none';
    }

    const url = this.Token.baseurl() + 'api/adddesiredjobworkschedule';
    this.http.post(url, { resume_token: desiretoken, work_schedule_id: this.work_schedule_id, job_type: this.jobtype_data, work_schedule: this.schedule_data, relocation: data.relocation, user_id: token })
      .subscribe((result) => {
        this.reloadfunction(); //reload the table

        this.onSelect('fifth');
        this.activeheader(4);
      });
    this.reloadfunction(); //reload the table
  }

  onSubmitfact(data) {
     console.log(data);
    var img = '';
    var facttoken = localStorage.getItem('resume_token');

    var myFormData = new FormData();
    const headers = new HttpHeaders();
    headers.append('Content-Type', 'multipart/form-data');
    headers.append('Accept', 'application/json');
    var jio = myFormData.append('profilepic', this.filedata);
    //console.log(this.filedata);
    /* Image Post Request */
    this.http.post(this.Token.baseurl() + 'api/addresumeimage', myFormData, {
      headers: headers
    }).subscribe((result) => {
      this.resumeimage = result;
      img = this.resumeimage.image;
      const url = this.Token.baseurl() + 'api/addprofilefact';
      this.http.post(url, { resume_token: facttoken, istransportation: data.transportation,transport_comment: data.trnsprt_cmmnt, isbackground: data.background,background_comment: data.bg_cmmnt, isdrugtest: data.drug, drugtest_comment: data.drug_cmmnt, isfelonies: data.felonies, felony_comment: data.felony_cmmnt,age_eighteen: data.age,eligible_towork: data.eligible, travel_willing: data.travel, license_valid: data.license, hourly_expectation: data.expectations,next_role: this.nxtrole_data, skill_describe: this.skdescribe_data,soon_start: this.soon_data, highvol_exp: this.highvol_data, picture: img, job_duties: this.dutis_data })
        .subscribe((result) => {
          var txt = 'Your Hospy Profile is created Successfully';
         
          this.alertWithSuccess(txt);
          this.reloadfunction(); //reload the table
        });
      this.reloadfunction(); //reload the table
      this.onSelect('seventh');
      this.activeheader(6);
    });


  }

  onSubmitsumm(data) {
    // console.log(data);
    var sumarytoken = localStorage.getItem('resume_token');
    var token = localStorage.getItem('token');
    if (token == null) {
      token = 'none';
    }
    //  var summar= document.getElementById('summdesc').innerHTML;
    const url = this.Token.baseurl() + 'api/addsummarydetail';
    this.http.post(url, { resume_token: sumarytoken, resume_summ_id: this.resume_summ_id, summary: data.summdesc,skilsum:data.skill_sum, user_id: token })
      .subscribe((result) => {
        this.reloadfunction(); //reload the table
        this.onSelect('thrd');
        this.activeheader(2);
      });
  }

  alertWithSuccess(txt) {
    Swal.fire('Thank you...', txt, 'success')
  }
  alertWithWarning(txt) {
       Swal.fire('Warning...', txt, 'warning')
  }
  alertWithWarning3(txt) {
     Swal.fire('Warning...', txt, 'warning')
   }
   alertWithWarning4(txt) {
   // Swal.fire('Warning...', txt, 'warning')
     Swal.fire({
       icon: 'warning',
       title: txt,
       showConfirmButton: true,
       confirmButtonText: 'Resend verification link',
       showCancelButton: true,
      cancelButtonText: 'Cancel'
    }).then(result=>{
      if (result.value) {

      const url = this.Token.baseurl() + 'api/resend_link';
      var email_id = localStorage.getItem('email');
      this.http.post(url, { email: email_id })
      .subscribe((result) => {
        this.ngOnInit();
      });
    }
    else {
      console.log('email_id')
    }
      
     // console.log(email_id)
    
    });
    
   }

  jobsearch() {
    window.location.href = '/searchjobs';
  }

}

