import { Component, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { FormsModule, FormGroup } from '@angular/forms';
import { FormControl, FormArray, FormBuilder, ReactiveFormsModule } from '@angular/forms';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Router } from '@angular/router';
import { TokenService } from '../../Services/token.service';
import {NgbModal, ModalDismissReasons} from '@ng-bootstrap/ng-bootstrap';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-edit-employer',
  templateUrl: './edit-employer.component.html',
  styleUrls: ['./edit-employer.component.css']
})
export class EditEmployerComponent implements OnInit {

  name_empyr: any;
  email_empyr: any;
  phone_empyr: any
  fb_e: any;
  tw_e: any;
  ln_e: any;
  gl_e: any;
  pn_e: any;

  compnydetails: any;
  compny_logo1 = true;
  compny_logo2 = true;
  compny_logo3 = true;
  compny_logo4 = true;
  compny_logo5 = true;
  
  cmpny_logo: any;
  cmpny_img1: any;
  cmpny_img2: any;
  cmpny_img3: any;
  cmpny_img4: any;
  cmpny_ceo: any;
  cmpny_locat: any;
  cmpny_fax: any;
  cmpny_web: any;

  logo_name: any;
  image1_name: any
  image2_name: any
  image3_name: any
  image4_name: any

  cmpny_addrs: any;
  cmpny_city: any;
  city_short_name: any;
  cmpny_state: any;
  state_short_name: any;
  country_short_name: any;
  cmpny_zipcode: any;
  cmpny_place_id: any;
  cmpny_lat: any;
  cmpny_lng: any;

  cmpny_ofcs: any;
  cmpny_establish: any;
  cmpny_dtail: any;
  cmpny_owner: any;
  cmpny_indus: any;
  specialty: any;
  cmpny_size: any;

  logodata: any;
  imagedata1: any;
  imagedata2: any;
  imagedata3: any;
  imagedata4: any;
  logoname: any;
  imagename1: any;
  imagename2: any;
  imagename3: any;
  imagename4: any;

  formattedaddress: any;
  citydata : any;
  statedata : any;
  options = {
    componentRestrictions: {
      country: ["USA"]
    }
  }
  gbls_url: any;
  zipdata: any;

  public AddressChange(address: any) {
    // console.log(address);
    this.formattedaddress = address.address_components;

    this.cmpny_addrs = address.formatted_address;
    this.cmpny_place_id = address.place_id;
    this.cmpny_lat = address.geometry.location.lat();
    this.cmpny_lng = address.geometry.location.lng();

    for (var i = 0; i < this.formattedaddress.length; i++) {
      if (this.formattedaddress[i].types[0] && 'postal_code' === this.formattedaddress[i].types[0]) {
        this.zipdata= this.formattedaddress[i].long_name;
        this.cmpny_zipcode = this.formattedaddress[i].long_name;
      }
      if (this.formattedaddress[i].types[0] && 'country' === this.formattedaddress[i].types[0]) {
        this.country_short_name = this.formattedaddress[i].short_name;
      }

      if (this.formattedaddress[i].types[0] && 'administrative_area_level_1' === this.formattedaddress[i].types[0]) {
        this.statedata = this.formattedaddress[i].short_name;
        this.state_short_name = this.formattedaddress[i].short_name;
      }

      if (this.formattedaddress[i].types[0] && 'administrative_area_level_2' === this.formattedaddress[i].types[0]) {
        this.citydata = this.formattedaddress[i].long_name;
        this.city_short_name = this.formattedaddress[i].short_name;
      }

      if (this.formattedaddress[i].types[0] && 'locality' === this.formattedaddress[i].types[0]) {
        this.cmpny_locat = this.formattedaddress[i].long_name;
      }
    }
  }

  // data = {name: '',phone: '',email: '',ceo: '',industry_id: ''};

  status: boolean = true;
  clickEvent() {
    this.status = !this.status;
  }

  url1: any = this.Token.baseurl();

  empyrdetails: any;
  detail: string = "";
  industries: any;
  industry: string = "";
  owners: any;
  owner: string = "";
  companies: any;
  company: string = "";
  multi_set: any;
  job_multiple_loc: any;

  constructor(private modalService: NgbModal, private http: HttpClient, private router: Router, private Token: TokenService) { }

  closeResult = '';
  addMultiple_location(content) {
    this.modalService.open(content, {ariaLabelledBy: 'modal-basic-title'}).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
  }
  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return  `with: ${reason}`;
    }
  }

  ngOnInit(): void {

    this.getdetail();
    this.getcompnydetail();
    this.getindustry();
    this.getowner();
    this.getcompanysize();
    this.getmultilocationby_id();
  }
  logout(Event: MouseEvent) {
    Event.preventDefault();
    this.Token.remove();
    this.router.navigateByUrl('/employer-login');
  }

  logoupload(e: any) {
    this.logodata = e.target.files[0];
  }

  imgupload1(e: any) {
    this.imagedata1 = e.target.files[0];
  }

  imgupload2(e: any) {
    this.imagedata2 = e.target.files[0];
  }

  imgupload3(e: any) {
    this.imagedata3 = e.target.files[0];
  }

  imgupload4(e: any) {
    this.imagedata4 = e.target.files[0];
  }

  onmulti_locs(data){
    var ml_token = localStorage.getItem('token');
    const url = this.Token.baseurl() + 'api/addmultiplelocation';
    this.http.post(url, { address: this.name_empyr+' '+this.cmpny_addrs, city_code: data.city_code, zip_code: data.zip, state_code: data.state_code, country_code: data.country_code, place_id: data.place_id, lat: data.lat, lng: data.lng, state_id: data.state_id, city_id: data.city_id, loc_token: ml_token })
      .subscribe((result) => {
        this.multi_set = result;
       
        var txt='Location added successfully!';
        this.alertWithSuccess(txt);
        this.ngOnInit();
      });
  }

  getmultilocationby_id() {
    var em_id = localStorage.getItem('employer_id');
    if(em_id){
    this.http.get<any>(this.Token.baseurl()+ 'api/getmultilocationby_id/' + em_id).subscribe(
      response => {
        this.job_multiple_loc = response;
       // console.log(this.job_multiple_loc);
      }
    );
    }
  }
  deletepostlocation(id){
    Swal.fire({
      title: 'Are you sure want to remove this location?',
      text: 'You will not be able to recover this location!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, delete it!',
      cancelButtonText: 'No, keep it'
    }).then((result) => {
      if (result.value) {
        Swal.fire(
          'Deleted!',
          'Location has been deleted.',
          'success'
        )
        this.http.delete(this.Token.baseurl()+'api/deleteposted_location/'+id).subscribe((result) => {          
            this.ngOnInit();
          });
      } else if (result.dismiss === Swal.DismissReason.cancel) {
        Swal.fire(
          'Cancelled',
          'Location is safe :)',
          'error'
        )
      }
    })
  }
  
  removeemloyerlgo(i: any){
    var tokens = this.Token.get();
    const url = this.Token.baseurl() + 'api/removelogo';
    this.http.post(url, { token: tokens,step:i})
    .subscribe((result) => {
      var txt='Logo removed Successfully';
            this.alertWithSuccess(txt);
      this.ngOnInit();
    });
  }

  onEditemployr(data) {

    const url = this.Token.baseurl() + 'api/updateemployer';
    var tokens = this.Token.get();

    const headers = new HttpHeaders();
    headers.append('Content-Type', 'multipart/form-data');
    headers.append('Accept', 'application/json');

    var img = '';
    var img1 = '';
    var img2 = '';
    var img3 = '';
    var img4 = '';

    var myFormData = new FormData();
    myFormData.append('profilepic', this.logodata);

    if (this.logodata != null) {
      /* Image Post Request */
      this.http.post(this.Token.baseurl() + 'api/addemployersliderimage', myFormData, {
        headers: headers
      }).subscribe((result) => {
        this.logoname = result;
        img = this.logoname.image;

        this.http.post(url, { token: tokens, logo: img, image1: img1, image2: img2, image3: img3, image4: img4, first_name: data.name, phone: data.phone, email: data.email, address: this.cmpny_addrs, city_code: data.city_code, zip_code: data.zip, state_code: data.state_code, country_code: data.country_code, place_id: data.place_id, lat: data.lat, lng: data.lng, location: data.location, state_id: data.state_id, city_id: data.city_id, facebook_url: data.facebook_url, twitter_url: data.twitter_url, linkedin_url: data.linkedin_url, google_plus_url: data.google_plus_url, pinterest_url: data.pinterest_url, gbl_url: data.gbl_url, region_code: data.region_code, ceo: data.ceo, no_of_offices: data.no_of_offices, industry_id: data.industry_id, speciality: data.speciality, ownership_type_id: data.ownership_type_id, company_size_id: data.company_size_id, established_in: data.established_in, details: data.details, fax: data.fax, website: data.website })
          .subscribe((result) => {
            var txt='Profile created Successfully';
            this.alertWithSuccess(txt);
            this.ngOnInit(); //reload the table
          });
          this.router.navigateByUrl('/jobs-employer');
      });
    } else {
      img = this.logo_name;

      this.http.post(url, { token: tokens, logo: img, image1: img1, image2: img2, image3: img3, image4: img4, first_name: data.name, phone: data.phone, email: data.email, address: this.cmpny_addrs, city_code: data.city_code, zip_code: data.zip, state_code: data.state_code, country_code: data.country_code, place_id: data.place_id, lat: data.lat, lng: data.lng, location: data.location, state_id: data.state_id, city_id: data.city_id, facebook_url: data.facebook_url, twitter_url: data.twitter_url, linkedin_url: data.linkedin_url, google_plus_url: data.google_plus_url, pinterest_url: data.pinterest_url, gbl_url: data.gbl_url, region_code: data.region_code, ceo: data.ceo, no_of_offices: data.no_of_offices, industry_id: data.industry_id, speciality: data.speciality, ownership_type_id: data.ownership_type_id, company_size_id: data.company_size_id, established_in: data.established_in, details: data.details, fax: data.fax, website: data.website })
        .subscribe((result) => {
          var txt='Profile created Successfully';
            this.alertWithSuccess(txt);
          this.ngOnInit(); //reload the table
        });
        this.router.navigateByUrl('/jobs-employer');
    }

    var myFormData = new FormData();
    myFormData.append('profilepic', this.imagedata1);

    if (this.imagedata1 != null) {
      /* Image Post Request */
      this.http.post(this.Token.baseurl() + 'api/addemployersliderimage', myFormData, {
        headers: headers
      }).subscribe((result) => {
        this.imagename1 = result;
        img1 = this.imagename1.image;

        this.http.post(url, { token: tokens, logo: img, image1: img1, image2: img2, image3: img3, image4: img4, first_name: data.name, phone: data.phone, email: data.email, address: this.cmpny_addrs, city_code: data.city_code, zip_code: data.zip, state_code: data.state_code, country_code: data.country_code, place_id: data.place_id, lat: data.lat, lng: data.lng, location: data.location, state_id: data.state_id, city_id: data.city_id, facebook_url: data.facebook_url, twitter_url: data.twitter_url, linkedin_url: data.linkedin_url, google_plus_url: data.google_plus_url, pinterest_url: data.pinterest_url, gbl_url: data.gbl_url, region_code: data.region_code, ceo: data.ceo, no_of_offices: data.no_of_offices, industry_id: data.industry_id, speciality: data.speciality, ownership_type_id: data.ownership_type_id, company_size_id: data.company_size_id, established_in: data.established_in, details: data.details, fax: data.fax, website: data.website })
          .subscribe((result) => {
            var txt='Profile created Successfully';
            this.alertWithSuccess(txt);
            this.ngOnInit(); //reload the table
          });
          this.router.navigateByUrl('/jobs-employer');
      });
    } else {
      img1 = this.image1_name;

      this.http.post(url, { token: tokens, logo: img, image1: img1, image2: img2, image3: img3, image4: img4, first_name: data.name, phone: data.phone, email: data.email, address: this.cmpny_addrs, city_code: data.city_code, zip_code: data.zip, state_code: data.state_code, country_code: data.country_code, place_id: data.place_id, lat: data.lat, lng: data.lng, location: data.location, state_id: data.state_id, city_id: data.city_id, facebook_url: data.facebook_url, twitter_url: data.twitter_url, linkedin_url: data.linkedin_url, google_plus_url: data.google_plus_url, pinterest_url: data.pinterest_url, gbl_url: data.gbl_url, region_code: data.region_code, ceo: data.ceo, no_of_offices: data.no_of_offices, industry_id: data.industry_id, speciality: data.speciality, ownership_type_id: data.ownership_type_id, company_size_id: data.company_size_id, established_in: data.established_in, details: data.details, fax: data.fax, website: data.website })
        .subscribe((result) => {
          var txt='Profile created Successfully';
            this.alertWithSuccess(txt);
          this.ngOnInit(); //reload the table
        });
        this.router.navigateByUrl('/jobs-employer');
    }


    var myFormData = new FormData();
    myFormData.append('profilepic', this.imagedata2);

    if (this.imagedata2 != null) {
      /* Image Post Request */
      this.http.post(this.Token.baseurl() + 'api/addemployersliderimage', myFormData, {
        headers: headers
      }).subscribe((result) => {
        this.imagename2 = result;
        img2 = this.imagename2.image;

        this.http.post(url, { token: tokens, logo: img, image1: img1, image2: img2, image3: img3, image4: img4, first_name: data.name, phone: data.phone, email: data.email, address: this.cmpny_addrs, city_code: data.city_code, zip_code: data.zip, state_code: data.state_code, country_code: data.country_code, place_id: data.place_id, lat: data.lat, lng: data.lng, location: data.location, state_id: data.state_id, city_id: data.city_id, facebook_url: data.facebook_url, twitter_url: data.twitter_url, linkedin_url: data.linkedin_url, google_plus_url: data.google_plus_url, pinterest_url: data.pinterest_url, gbl_url: data.gbl_url, region_code: data.region_code, ceo: data.ceo, no_of_offices: data.no_of_offices, industry_id: data.industry_id, speciality: data.speciality, ownership_type_id: data.ownership_type_id, company_size_id: data.company_size_id, established_in: data.established_in, details: data.details, fax: data.fax, website: data.website })
          .subscribe((result) => {
            var txt='Profile created Successfully';
            this.alertWithSuccess(txt);
            this.ngOnInit(); //reload the table
          });
          this.router.navigateByUrl('/jobs-employer');
      });
    } else {
      img2 = this.image2_name;

      this.http.post(url, { token: tokens, logo: img, image1: img1, image2: img2, image3: img3, image4: img4, first_name: data.name, phone: data.phone, email: data.email, address: this.cmpny_addrs, city_code: data.city_code, zip_code: data.zip, state_code: data.state_code, country_code: data.country_code, place_id: data.place_id, lat: data.lat, lng: data.lng, location: data.location, state_id: data.state_id, city_id: data.city_id, facebook_url: data.facebook_url, twitter_url: data.twitter_url, linkedin_url: data.linkedin_url, google_plus_url: data.google_plus_url, pinterest_url: data.pinterest_url, gbl_url: data.gbl_url, region_code: data.region_code, ceo: data.ceo, no_of_offices: data.no_of_offices, industry_id: data.industry_id, speciality: data.speciality, ownership_type_id: data.ownership_type_id, company_size_id: data.company_size_id, established_in: data.established_in, details: data.details, fax: data.fax, website: data.website })
        .subscribe((result) => {
          this.ngOnInit(); //reload the table
        });
        this.router.navigateByUrl('/jobs-employer');
    }


    var myFormData = new FormData();
    myFormData.append('profilepic', this.imagedata3);

    if (this.imagedata3 != null) {
      /* Image Post Request */
      this.http.post(this.Token.baseurl() + 'api/addemployersliderimage', myFormData, {
        headers: headers
      }).subscribe((result) => {
        this.imagename3 = result;
        img3 = this.imagename3.image;

        this.http.post(url, { token: tokens, logo: img, image1: img1, image2: img2, image3: img3, image4: img4, first_name: data.name, phone: data.phone, email: data.email, address: this.cmpny_addrs, city_code: data.city_code, zip_code: data.zip, state_code: data.state_code, country_code: data.country_code, place_id: data.place_id, lat: data.lat, lng: data.lng, location: data.location, state_id: data.state_id, city_id: data.city_id, facebook_url: data.facebook_url, twitter_url: data.twitter_url, linkedin_url: data.linkedin_url, google_plus_url: data.google_plus_url, pinterest_url: data.pinterest_url, gbl_url: data.gbl_url, region_code: data.region_code, ceo: data.ceo, no_of_offices: data.no_of_offices, industry_id: data.industry_id, speciality: data.speciality, ownership_type_id: data.ownership_type_id, company_size_id: data.company_size_id, established_in: data.established_in, details: data.details, fax: data.fax, website: data.website })
          .subscribe((result) => {
            var txt='Profile created Successfully';
            this.alertWithSuccess(txt);
            this.ngOnInit(); //reload the table
          });
          this.router.navigateByUrl('/jobs-employer');
      });
    } else {
      img3 = this.image3_name;

      this.http.post(url, { token: tokens, logo: img, image1: img1, image2: img2, image3: img3, image4: img4, first_name: data.name, phone: data.phone, email: data.email, address: this.cmpny_addrs, city_code: data.city_code, zip_code: data.zip, state_code: data.state_code, country_code: data.country_code, place_id: data.place_id, lat: data.lat, lng: data.lng, location: data.location, state_id: data.state_id, city_id: data.city_id, facebook_url: data.facebook_url, twitter_url: data.twitter_url, linkedin_url: data.linkedin_url, google_plus_url: data.google_plus_url, pinterest_url: data.pinterest_url, gbl_url: data.gbl_url, region_code: data.region_code, ceo: data.ceo, no_of_offices: data.no_of_offices, industry_id: data.industry_id, speciality: data.speciality, ownership_type_id: data.ownership_type_id, company_size_id: data.company_size_id, established_in: data.established_in, details: data.details, fax: data.fax, website: data.website })
        .subscribe((result) => {
          var txt='Profile created Successfully';
            this.alertWithSuccess(txt);
          this.ngOnInit(); //reload the table
        });
        this.router.navigateByUrl('/jobs-employer');
    }


    var myFormData = new FormData();
    myFormData.append('profilepic', this.imagedata4);

    if (this.imagedata4 != null) {
      /* Image Post Request */
      this.http.post(this.Token.baseurl() + 'api/addemployersliderimage', myFormData, {
        headers: headers
      }).subscribe((result) => {
        this.imagename4 = result;
        img4 = this.imagename4.image;

        this.http.post(url, { token: tokens, logo: img, image1: img1, image2: img2, image3: img3, image4: img4, first_name: data.name, phone: data.phone, email: data.email, address: this.cmpny_addrs, city_code: data.city_code, zip_code: data.zip, state_code: data.state_code, country_code: data.country_code, place_id: data.place_id, lat: data.lat, lng: data.lng, location: data.location, state_id: data.state_id, city_id: data.city_id, facebook_url: data.facebook_url, twitter_url: data.twitter_url, linkedin_url: data.linkedin_url, google_plus_url: data.google_plus_url, pinterest_url: data.pinterest_url, gbl_url: data.gbl_url, region_code: data.region_code, ceo: data.ceo, no_of_offices: data.no_of_offices, industry_id: data.industry_id, speciality: data.speciality, ownership_type_id: data.ownership_type_id, company_size_id: data.company_size_id, established_in: data.established_in, details: data.details, fax: data.fax, website: data.website })
          .subscribe((result) => {
            var txt='Profile created Successfully';
            this.alertWithSuccess(txt);
            this.ngOnInit(); //reload the table
          });
          this.router.navigateByUrl('/jobs-employer');
      });
    } else {
      img4 = this.image4_name;

      this.http.post(url, { token: tokens, logo: img, image1: img1, image2: img2, image3: img3, image4: img4, first_name: data.name, phone: data.phone, email: data.email, address: this.cmpny_addrs, city_code: data.city_code, zip_code: data.zip, state_code: data.state_code, country_code: data.country_code, place_id: data.place_id, lat: data.lat, lng: data.lng, location: data.location, state_id: data.state_id, city_id: data.city_id, facebook_url: data.facebook_url, twitter_url: data.twitter_url, linkedin_url: data.linkedin_url, google_plus_url: data.google_plus_url, pinterest_url: data.pinterest_url, gbl_url: data.gbl_url, region_code: data.region_code, ceo: data.ceo, no_of_offices: data.no_of_offices, industry_id: data.industry_id, speciality: data.speciality, ownership_type_id: data.ownership_type_id, company_size_id: data.company_size_id, established_in: data.established_in, details: data.details, fax: data.fax, website: data.website })
        .subscribe((result) => {
          var txt='Profile created Successfully';
            this.alertWithSuccess(txt);
          this.ngOnInit(); //reload the table
        });
        this.router.navigateByUrl('/jobs-employer');
    }
  }
  alertWithSuccess(txt){
    Swal.fire('Thank you...',txt, 'success')
    } 

  getdetail() {
    var token = this.Token.get();

    this.http.get<any>(this.url1 + 'api/employerdetails/' + token).subscribe(
      response => {
        this.empyrdetails = response;
        this.name_empyr = this.empyrdetails.first_name;
        this.email_empyr = this.empyrdetails.email;
        this.phone_empyr = this.empyrdetails.phone;
        // this.statedata = this.empyrdetails.state_id;
        // this.citydata = this.empyrdetails.city_id;
        // this.state_short_name = this.empyrdetails.state_short_name;
        this.fb_e = this.empyrdetails.facebook_url;
        this.tw_e = this.empyrdetails.twitter_url;
        this.ln_e = this.empyrdetails.linkedin_url;
        this.gl_e = this.empyrdetails.google_plus_url;
        this.pn_e = this.empyrdetails.pinterest_url;
        this.gbls_url = this.empyrdetails.gbl_url;
      }
    );
  }
  getcompnydetail() {
    var token = this.Token.get();
    this.http.get<any>(this.url1 + 'api/employercompanydetails/' + token).subscribe(
      response => {
        this.compnydetails = response[0];
        console.log(this.compnydetails);

        var img_path = 'assets/employersilder/';
        var demo_img = this.Token.baseurl() + img_path + 'demo.png';
        this.cmpny_logo = this.Token.baseurl() + img_path + this.compnydetails.logo;

        if (this.compnydetails != null) {
          if (this.compnydetails.logo != '') {
            //this.cmpny_logo = this.Token.baseurl() + img_path + this.compnydetails.logo;
            this.compny_logo1 = true;
          } else {
            //this.cmpny_logo = demo_img;
            this.compny_logo1 = false;
          }

          if (this.compnydetails.image1 != '') {
            this.cmpny_img1 = this.Token.baseurl() + img_path + this.compnydetails.image1;
            this.compny_logo2 = true;
          } else {
            this.cmpny_img1 = demo_img;
            this.compny_logo2 = false;
          }

          if (this.compnydetails.image2 != '') {
            this.cmpny_img2 = this.Token.baseurl() + img_path + this.compnydetails.image2;
            this.compny_logo3 = true;
          } else {
            this.cmpny_img2 = demo_img;
            this.compny_logo3 = false;
          }

          if (this.compnydetails.image3 != '') {
            this.cmpny_img3 = this.Token.baseurl() + img_path + this.compnydetails.image3;
            this.compny_logo4 = true;
          } else {
            this.cmpny_img3 = demo_img;
            this.compny_logo4 = false;
          }

          if (this.compnydetails.image4 != '') {
            this.cmpny_img4 = this.Token.baseurl() + img_path + this.compnydetails.image4;
            this.compny_logo5 = true;
          } else {
            this.cmpny_img4 = demo_img;
            this.compny_logo5 = false;
          }

          this.logo_name = this.compnydetails.logo;
          this.image1_name = this.compnydetails.image1;
          this.image2_name = this.compnydetails.image2;
          this.image3_name = this.compnydetails.image3;
          this.image4_name = this.compnydetails.image4;

          this.cmpny_ceo = this.compnydetails.ceo;
          // this.cmpny_addrs = this.compnydetails.address;
          // this.city_short_name = this.compnydetails.city_code;
          // this.state_short_name = this.compnydetails.state_code;
          // this.country_short_name = this.compnydetails.country_code;
          // this.cmpny_locat = this.compnydetails.location;
          // this.zipdata = this.compnydetails.zip_code;
          // this.cmpny_lat = this.compnydetails.lat;
          // this.cmpny_lng = this.compnydetails.lng;
          // this.cmpny_place_id = this.compnydetails.place_id;
          this.cmpny_fax = this.compnydetails.fax;
          this.cmpny_ofcs = this.compnydetails.no_of_offices;
          this.cmpny_establish = this.compnydetails.established_in;
          this.cmpny_web = this.compnydetails.website;
          this.cmpny_dtail = this.compnydetails.details;
          this.cmpny_owner = this.compnydetails.ownership_type_id;
          this.cmpny_indus = this.compnydetails.industry_id;
          this.specialty = this.compnydetails.speciality;
          this.cmpny_size = this.compnydetails.company_size_id;
        } else {
          this.cmpny_logo = demo_img;
          this.cmpny_img1 = demo_img;
          this.cmpny_img2 = demo_img;
          this.cmpny_img3 = demo_img;
          this.cmpny_img4 = demo_img;
        }
      }
    );
  }

  getindustry() {
    this.http.get<any>(this.url1 + 'api/getindustrylist').subscribe(
      response => {
        this.industries = response;
      }
    );
  }
  getowner() {
    this.http.get<any>(this.url1 + 'api/getownership_typelist').subscribe(
      response => {
        this.owners = response;
      }
    );
  }
  getcompanysize() {
    this.http.get<any>(this.url1 + 'api/getcompany_sizelist').subscribe(
      response => {
        this.companies = response;
      }
    );
  }
  privacy_policy() {
    this.router.navigate(['/privacy_policy']);
  }

 agrement(){
    this.router.navigateByUrl('/agreement');
  }

faq_data() {
    this.router.navigate(['/faq_desc']);
  }
  invest_data(){
    this.router.navigate(['/invest']);
  }
  about_us(){
    this.router.navigate(['/about']);
  }
  home_page(){
    window.location.href = '/';
   }

}
