import { Component, OnInit, NgZone } from '@angular/core';
import { Router } from '@angular/router';
import { TokenService } from '../Services/token.service';
import { HttpClient } from '@angular/common/http';
import { NgModule, EventEmitter, Output } from '@angular/core';
import {NgbModal, ModalDismissReasons} from '@ng-bootstrap/ng-bootstrap';
import Swal from 'sweetalert2';

declare var $: any;

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss'],
})
export class HomeComponent implements OnInit {

  resume_token: any;
  testtoken: any;

  find_search_jobs_data: any;
  find_search_jobs_address: any;
  find_search_jobs_city: any;
  find_search_jobs_state: any;
  filtered_searchdata: any = [];
  searchdata: any = [];
  url1: any = '';
  contactus: any;
  totaljob: any;
  tjob: any;

  constructor(  private modalService: NgbModal, private http:HttpClient, private router: Router, private Token: TokenService, private ngZone: NgZone ) { }
  isemployee: boolean = false;
  isemployeer: boolean = false;
  islog: any;
  ngOnInit(): void {
    
    this.url1 = this.Token.baseurl();

    this.resume_token = localStorage.getItem("resume_token");
    this.testtoken = localStorage.getItem("token");
    if (this.resume_token != '' && this.resume_token != null) {
      this.isemployee = true;
      this.isemployeer = false;
      this.islog = false;

      this.http.get<any>(this.Token.baseurl() + 'api/getresumeprofile/' + this.resume_token).subscribe(
        (response: any) => {
          this.find_search_jobs_city = response[0]['city'];
          this.find_search_jobs_state = response[0]['state'];
          localStorage.setItem("mapsearch_state", this.find_search_jobs_state);
        }
      );

    }
    else {
      if (this.testtoken != '' && this.testtoken != null) {
        this.isemployee = false;
        this.isemployeer = true;
        this.islog = false;
        // console.log(this.islog + '2');
      }
      else {
        this.islog = true;
        this.isemployee = false;
        this.isemployeer = false;
        // console.log(this.islog + '3');
      }
    }

    this.loadCss();
    this.getasumrjobtype();

    $("#search").click(function () {
      $(".searchbox").toggle();

    });
  }
  dropboxarrow() {
    $(".dropbox").toggle("slow");
  }

  onMouseover(dd: any) {
    $('#info-box').css('display', 'block');
   
    this.http.get<any>(this.Token.baseurl() + 'api/homemap/'+dd).subscribe(
      (response: any) => {
        this.totaljob=response;
        this.tjob=this.totaljob.totalnojobs;
        
        $('#info-box').html($('#' + dd).data('info')+'<br> Total available Jobs '+this.tjob);
      } 
    );
  }

  onMouseout() {
    $('#info-box').css('display', 'none');
  }

  clickmap(dd: any) {
    // console.log(dd);
    this.router.navigate(['/searchmap'], { queryParams: { state: dd } });
  }

  getasumrjobtype() {
    this.http.get<any>(this.Token.baseurl() + 'api/getjobtype').subscribe(
      (response: any) => {
       // console.log(response);
        for (var i = 0; i < response.length; i++) {
          this.searchdata[i] = response[i]['name'];
          this.filtered_searchdata[i] = response[i]['name'];
        }
      }
    );
  }

  onJobsearchInput(data: any) {
    let res = data.target.value;
    if (res == '' || res == ' ' || res == 'null' || res == 'NULL' || res == null) {
     // console.log(res);
      $("#searchrecommand").css("display", "none");
    } else if (res != '' || res != ' ' || res != 'null' || res != 'NULL' || res != null) {
      let query = res.toLowerCase();
      this.filtered_searchdata = this.searchdata.filter(item => item.toLowerCase().indexOf(query) >= 0);
      $("#searchrecommand").css("display", "block");
    }
  }

  fill_find_search_jobs(data) {
    this.find_search_jobs_data = data;
    $("#searchrecommand").css("display", "none");
  }

  AddressChange(address) {
    this.find_search_jobs_address = address.formatted_address;
    if (this.resume_token == '' || this.resume_token == null) {
      for (const component of address.address_components) {
        const componentType = component.types[0];
        switch (componentType) {
          case "administrative_area_level_2": {
            var city;
            city = component.long_name;
            this.find_search_jobs_city = city.split(" ")[0];
            break;
          }
          case "administrative_area_level_1": {
            this.find_search_jobs_state = component.short_name;
            localStorage.setItem("mapsearch_state", this.find_search_jobs_state);
            break;
          }
        }
      }
    }
  }

  find_search_jobs() {
    if (this.find_search_jobs_data) {
      var link = 'jobsearchmanual=TRUE&jobsearchdata=' + this.find_search_jobs_data + '&jobsearchaddress=' + this.find_search_jobs_address + '&jobsearchcity=' + this.find_search_jobs_city + '&state=' + this.find_search_jobs_state;

      this.router.navigateByUrl('/searchmap?' + link);
    }
  }


  public loadCss() {
    const node = document.createElement('link');
    node.href = 'assets/digital-marketing/css/home_main.css'; // put there your js file location
    node.rel = 'stylesheet';
    node.charset = 'utf-8';
    document.getElementsByTagName('head')[0].appendChild(node);
  }

  employer_profile() {
    this.router.navigate(['/edit-employer']);
  }
  employer_dashboard() {
    this.router.navigate(['/employer-dashboard']);
  }
  employer_billing() {
    this.router.navigate(['/employer-billing']);
  }
  privacy_policy() {
    this.router.navigate(['/privacy_policy']);
  }
  employer_communication() {
    this.router.navigate(['/communication']);
  }
  employer_data_management() {
    this.router.navigate(['/employer_communication']);
  }
  employer_manage_jobs() {
    this.router.navigate(['/jobs-employer']);
  }
  profile() {
    this.router.navigateByUrl('/profile-builder');
  }
  communication() {
    this.router.navigateByUrl('/communication');
  }
  employees() {
    this.router.navigateByUrl('/candidate-login');
  }
  appli() {
    this.router.navigateByUrl('applied-jobs');
  }
  jobsearch() {
    window.location.href = '/searchjobs';
  }
  blogs() {
    this.router.navigate(['/blog']);
  }
  candidate_logout(Event: MouseEvent) {
    this.Token.remove();
    localStorage.clear();
    this.router.navigateByUrl('');
    this.ngOnInit();
  }
  employer_logout(Event: MouseEvent) {
    this.Token.remove();
    localStorage.clear();
    this.router.navigateByUrl('');
    this.ngOnInit();
  }
  employers() {
    this.router.navigate(['/employer-login']);
  }
  demo() {
    this.router.navigate(['/employer-demo']);
  }
  employer_signup() {
    this.router.navigate(['/employer-signup']);
  }
  faq_data() {
    this.router.navigate(['/faq_desc']);
  }
  about_us(){
    this.router.navigate(['/about']);
  }
  invest_data(){
    this.router.navigate(['/invest']);
  }
  dashboard() {
    this.router.navigateByUrl('/candidate-dashboard');
  }
  agrement(){
    this.router.navigateByUrl('/agreement');
  }
  candidate_regist(){
    this.router.navigateByUrl('/register-candidate');
  }
  testimon(){
    this.router.navigateByUrl('/testimonials');
  }
  status: boolean = true;
  clickEvent() {
    this.status = !this.status;
  }

  contactform(data){
  //  const url = this.url1 + 'api/contactus';
    this.http.post(this.Token.baseurl() + 'api/contactus', { userType: data.userType, userName:data.userName, userEmail: data.userEmail, userPhone: data.userPhone, userSubject: data.userSubject, userMessage: data.userMessage })
      .subscribe((result) => {
      //  console.log(result);
        this.contactus = result;
        if(this.contactus.status == false){
          var txt='We have already received your request. Be patient!';
          this.alertWithSuccess(txt);
        }
        else{
        var txt='Thank you for contacting us. Hospy team member will contact you shortly.';
        this.alertWithSuccess(txt);
        this.ngOnInit();
        }
      });
  }
  alertWithSuccess(txt) {
    Swal.fire('Thank you...', txt, 'success')
  }
}

