import { Component, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';
import { TokenService } from '../../Services/token.service';
import {NgbModal, ModalDismissReasons} from '@ng-bootstrap/ng-bootstrap';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-employer-header',
  templateUrl: './employer-header.component.html',
  styleUrls: ['./employer-header.component.css']
})
export class EmployerHeaderComponent implements OnInit {
  cl_set: any;

  constructor(private modalService: NgbModal, private router: Router, private Token: TokenService, private http:HttpClient) { }

  status: boolean = true;
  clickEvent(){
      this.status = !this.status;    
  }

  passw_set: any;
  closeResult = '';

  open(content) {
    this.modalService.open(content, {ariaLabelledBy: 'modal-basic-title'}).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
  }
  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return  `with: ${reason}`;
    }
  }

  ngOnInit(): void {
    if(!this.Token.get()){
      this.router.navigateByUrl('/employer-login');
    }

    this.loadCss();
  }

  oncalendly_pass(data){
    var cl_token = localStorage.getItem('token');
    const url = this.Token.baseurl() + 'api/calendarly_data';
    this.http.post(url, { calendar_link: data.calendr_link, c_token: cl_token })
      .subscribe((result) => {
        this.cl_set = result;
       
        var txt='Your calendly account is integrated successfully!';
        this.alertWithSuccess(txt);
        this.ngOnInit();
      });
  }

  alertWithSuccess(txt) {
    Swal.fire('Thank you...', txt, 'success')
  }

  profile_builder(){
    this.router.navigate(['/profile-builder']);
  }

  on_demand(){
    this.router.navigate(['/']);
  }

  dashboard(){
    this.router.navigate(['/employer-dashboard']);
  }

  manage_jobs(){
    this.router.navigate(['/jobs-employer']);
  }

  billing(){
    this.router.navigate(['/employer-billing']);
  }

  communication(){
    this.router.navigate(['/communication']);
  }

  profile(){
    this.router.navigate(['/edit-employer']);
  }
  homepage(){
    this.router.navigate(['/']);
  }
  demo() {
    this.router.navigate(['/employer-demo']);
  }
  employer_logout(Event: MouseEvent) {
    this.Token.remove();
    localStorage.clear();
    this.router.navigateByUrl('');
    this.ngOnInit();
  }

  data_management(){}

  logout(Event: MouseEvent){
    //Event.preventDefault();
    this.Token.remove();
    localStorage.clear();
    this.router.navigateByUrl('/employer-login');
  }

  public loadCss() {
    const node = document.createElement('link');
    node.href = 'assets/digital-marketing/css/style_new.css'; // put there your js file location
    node.rel = 'stylesheet';
    node.charset = 'utf-8';
   document.getElementsByTagName('head')[0].appendChild(node);
  }
  home_page(){
    window.location.href = '/';
   }
}
