import { Component, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { Router } from '@angular/router';
import { FormsModule, FormGroup } from '@angular/forms';
import { FormControl, FormArray, FormBuilder, ReactiveFormsModule } from '@angular/forms';
import { HttpClient,HttpHeaders } from '@angular/common/http';
import { TokenService } from '../../Services/token.service';
import { NgModule, EventEmitter, Output } from '@angular/core';
import { IDropdownSettings } from 'ng-multiselect-dropdown';

@Component({
  selector: 'app-edit-candidate',
  templateUrl: './edit-candidate.component.html',
  styleUrls: ['./edit-candidate.component.css']
})
export class EditCandidateComponent implements OnInit {

  dropdownList;
  dropdownSettings;
  selectedItems;
  selectedItem;

  languagedropdownList;
  languagedropdownSettings;

  @Output() featureSelected = new EventEmitter<string>();

  onSelect(feature: string){
    this.featureSelected.emit(feature);
    this.loadedFeature = feature;
  }

  loadedFeature = 'edit-candidate';
  onNavigate(feature: string){
    this.loadedFeature = feature;
  }

status: boolean = true;
  clickEvent(){
      this.status = !this.status;       
  }

  st: boolean = false;
  clickChange(){
      this.st = !this.st;     
  }

  filedata:any;
   
    fileEvent(e){
        this.filedata = e.target.files[0];
    }
  
  formattedaddress=" ";
  citydata=" ";
 statedata=" ";
 options={
    componentRestrictions:{
      country:["USA"]
    }
  }

  public AddressChange(address: any) {
    this.formattedaddress=address.formatted_address
   var add= this.formattedaddress.split(',');
   this.citydata=add[add.length-3];
    this.statedata=add[add.length-2];
  // this.statedata=this.statedata.substring(0,this.statedata.length-6);
  }
  
  maritals: any;
  marital: string="" ;
  currencies: any;
  currency: string="" ;
  industriess: any;
  industryy: string="" ;
   functionalarea: any;
  func: string="" ;
  careers: any;
  carer: string="" ;
  skilldetail: any;
  skilld: string="";

 // url1:any="https://hospy.snckapp.com/hospyback";

 url1:any="https://hospy.snckapp.com/hospyback";
// detaild: any = []; 
 detaild: any;
  detaile: string="" ;

  can_detail: any;
  can_father: any;
  can_marital: any;
  can_nation: any;
  can_nation_id: any;
  can_exp: any;
  can_career_id: any;
  can_industry_id: any;
  can_fun_id: any;
  can_salary: any;
  can_expsalary: any;
  can_currency: any;
  can_address: any;
  can_immediate_available: any;
  can_available_at: any;

  canskills: any;

  first_name_e: any;
  last_name_e: any;
  email_e: any;
  fb_e:any;
  tw_e:any;
  ln_e:any;
  gl_e:any;
  pn_e:any;

  constructor( private http:HttpClient, private Token : TokenService,private router: Router ) { }

  ngOnInit(): void {
  this.getmarital();
  this.getcurrencylist();
  this.getindustrylist();
   this.getfuncarea();
    this.getcreerlevel();
    this.getdetailed();
    this.getskilldetail();
    this.getcandidatedetail();


   this.dropdownList = this.getData();
    this.dropdownSettings = {
      singleSelection: false,
      idField: 'item_id',
      textField: 'item_text',
      selectAllText: 'Select All',
      unSelectAllText: 'UnSelect All',
      itemsShowLimit: 5,
      allowSearchFilter: true
    };

    this.getskilldetail();

    this.languagedropdownList = this.getlanguageData();
    this.languagedropdownSettings = {
      singleSelection: false,
      idField: 'item_id',
      textField: 'item_text',
      selectAllText: 'Select All',
      unSelectAllText: 'UnSelect All',
      itemsShowLimit: 3,
      allowSearchFilter: true
    };
    this.getlanguagedetail();
   
  }

   logout(Event: MouseEvent) {
    Event.preventDefault();
    this.Token.remove();
    this.router.navigateByUrl('');
  }
  
   getData(){

     this.http.get<any>(this.url1+'/api/getskill').subscribe(
    response => {
      this.dropdownList=response;
        }
      );
     
    }

    getlanguageData(){
   this.http.get<any>(this.url1+'/api/getlanguage').subscribe(
    response => {
      this.languagedropdownList=response;
        }
      );
     
    }
   

  getmarital(){
  this.http.get<any>(this.url1+'/api/getmaritallist').subscribe(
    response => {
      this.maritals = response;
     //  console.log(response);
    }
  );
}
getcurrencylist(){
  this.http.get<any>(this.url1+'/api/getcurrencylist').subscribe(
    response => {
      this.currencies = response;
    }
  );
}
getindustrylist(){
  this.http.get<any>(this.url1+'/api/getindustrylist').subscribe(
    response => {
      this.industriess = response;
    }
  );
}
getfuncarea(){
  this.http.get<any>(this.url1+'/api/getfunctionalarea').subscribe(
    response => {
      this.functionalarea = response;
    }
  );
}
getcreerlevel(){
  this.http.get<any>(this.url1+'/api/getcareerlevel').subscribe(
    response => {
      this.careers = response;
    }
  );
}
getdetailed(){
  var token = this.Token.get();
  this.http.get<any>(this.url1+'/api/employeedetails/'+token).subscribe(
    response => {
    this.detaild = response;
    // console.log(response);
     this.first_name_e=this.detaild.first_name;
     this.last_name_e=this.detaild.last_name;
     this.email_e=this.detaild.email;
     this.fb_e=this.detaild.facebook_url;
     this.tw_e=this.detaild.twitter_url;
     this.ln_e=this.detaild.linkedin_url;
     this.gl_e=this.detaild.google_plus_url;
     this.pn_e=this.detaild.pinterest_url;
    }
  );
}
getcandidatedetail(){
  var token = this.Token.get();
  this.http.get<any>(this.url1+'/api/employeecandidatedetails/'+token).subscribe(
    response => {
    this.can_detail = response;
   // console.log(response);
     this.can_father=this.can_detail[0].father_name;
     this.can_marital=this.can_detail[0].marital_status_id;
     this.can_nation=this.can_detail[0].nationality;
     this.can_nation_id=this.can_detail[0].national_id_card;
     this.can_exp=this.can_detail[0].experience;
     this.can_career_id=this.can_detail[0].career_level_id;
     this.can_industry_id=this.can_detail[0].industry_id;
     this.can_fun_id=this.can_detail[0].functional_area_id;
     this.can_salary=this.can_detail[0].current_salary;
     this.can_expsalary=this.can_detail[0].expected_salary;
     this.can_currency=this.can_detail[0].salary_currency;
     this.can_address=this.can_detail[0].address;
     this.can_immediate_available=this.can_detail[0].immediate_available;
     this.can_available_at=this.can_detail[0].available_at;
     
    }
  );
}

getskilldetail(){
  var token = this.Token.get();
  this.http.get<any>(this.url1+'/api/employeeskilldetails/'+token).subscribe(
    response => {
    this.selectedItems = response;
    }
  );
}
getlanguagedetail(){
  var token = this.Token.get();
  this.http.get<any>(this.url1+'/api/employeelanguagedetails/'+token).subscribe(
    response => {
    this.selectedItem = response;
    }
  );
}


onEditcandidate(data){
//  console.log(data); 

  var tokens = this.Token.get();

    var myFormData = new FormData();
      const headers = new HttpHeaders();
      headers.append('Content-Type', 'multipart/form-data');
       headers.append("Authorization" , `Bearer ${tokens}`);
      headers.append('Accept', 'application/json');
     
      myFormData.append('profilepic', this.filedata);
      /* Image Post Request */
      this.http.post('https://hospy.snckapp.com/hospyback/api/addemployeeimage', myFormData, {
      headers: headers
      }).subscribe((result) => {
     // this.ngOnInit(); //reload the table
    });
    

for(var dt in data.cn_skill){
    const urls = 'https://hospy.snckapp.com/hospyback/api/updateemployeeskill';
        this.http.post(urls, {skill_id: data.cn_skill[dt].item_id, token:tokens})
      .subscribe((result) => {
       // this.ngOnInit(); //reload the table
        });
}

for(var dt in data.cn_language){
    const urli = 'https://hospy.snckapp.com/hospyback/api/updateemployeelanguage';
        this.http.post(urli, {language_id: data.cn_language[dt].item_id, token:tokens})
      .subscribe((result) => {
       // this.ngOnInit(); //reload the table
        });
}

    const url = 'https://hospy.snckapp.com/hospyback/api/updateemployee';
  this.http.post(url, {token:tokens,first_name: data.first_name,last_name: data.last_name,father_name: data.father_name, phone: data.phone,gender: data.gender,marital_status_id: data.marital_status_id,email: data.email,country_id: data.country_id,state_id: data.state_id,city_id: data.city_id,facebook_url: data.facebook_url,twitter_url: data.twitter_url,linkedin_url: data.linkedin_url,google_plus_url: data.google_plus_url,pinterest_url: data.pinterest_url,national_id_card: data.national_id_card,experience: data.experience,dob: data.dob,career_level_id: data.career_level_id,industry_id: data.industry_id,functional_area_id: data.functional_area_id,current_salary: data.current_salary,expected_salary: data.expected_salary,salary_currency: data.salary_currency,immediate_available: data.immediate_available,address: data.address})
    .subscribe((result) => {
      this.ngOnInit(); //reload the table
    });
  }

}
