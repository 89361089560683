import { Component, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { TokenService } from '../Services/token.service';
import { Router } from '@angular/router';
import {NgbModal, ModalDismissReasons} from '@ng-bootstrap/ng-bootstrap';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-login-candidate',
  templateUrl: './login-candidate.component.html',
  styleUrls: ['./login-candidate.component.css']
})
export class LoginCandidateComponent implements OnInit {

  public form = {
    email: null,
    password: null
  };
  public error = null;
  closeResult = '';
  pass_set: any;

  constructor(private modalService: NgbModal, private http: HttpClient, private Token: TokenService, private router: Router) { }

  open(content) {
    this.modalService.open(content, {ariaLabelledBy: 'modal-basic-title'}).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
  }
  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return  `with: ${reason}`;
    }
  }

  onSubmit() {
    return this.http.post(this.Token.baseurl() + 'api/employeelogin', this.form).subscribe(
      // data => console.log(data),
      data => this.handleResponse(data),
      error => this.alertWithWarning('Please enter correct User ID and Password'),
    );
  }
  
  handleResponse(data) {
    console.log(data);
    if(data.owner_type == 3){
      this.Token.handle(data.token);
    localStorage.setItem('resume_token', data.token);
    // sessionStorage.setItem('resume_token', data.token); /// This is using in communication section to detect is employee or employer
    this.router.navigateByUrl('/profile-builder');
    }
    else{
      var txt='Incorrect credentials for jobseeker';
        this.alertWithWarning(txt);
        this.ngOnInit();
    }
  }

  handleError(error) {
    this.error = error.error.error;
  }
  profile(){
    this.router.navigateByUrl('/profile-builder');
    this.ngOnInit();
  }
  profile_signup(){
    this.router.navigateByUrl('/register-candidate');
    this.ngOnInit();
  }
  ngOnInit(): void {
    if(this.Token.get()){
      this.router.navigateByUrl('/candidate-dashboard');
    }

    this.loadCss();
  }
  public loadCss() {
    const node = document.createElement('link');
    node.href = 'assets/digital-marketing/css/stylelogin.css'; // put there your js file location
    node.rel = 'stylesheet';
    node.charset = 'utf-8';
    document.getElementsByTagName('head')[0].appendChild(node);
  }
  onforgot_pass(data){
    this.http.post('https://hospy-api.aofenggsys.us/api/forgot_passw', { email: data.email })
      .subscribe((result) => {
        this.pass_set = result;
       
        var txt='Email sent for password reset!';
        this.alertWithSuccess(txt);
        this.ngOnInit();
      });
  }
  alertWithSuccess(txt) {
    Swal.fire('Thank you...', txt, 'success')
  }
  alertWithWarning(txt) {
    Swal.fire('Thank you...', txt, 'warning')
  }
  agrement(){
    this.router.navigateByUrl('/agreement');
  }

  
  eyebtnstate: any = "fa-eye-slash";
  passwordtype: any = "password";
  passwordstate: boolean = true;
  
  passwordstatechng() {
    if (this.passwordstate === false) {
      this.eyebtnstate = "fa-eye";
      this.passwordtype = "text";
      this.passwordstate = true;
    } else {
      this.eyebtnstate = "fa-eye-slash";
      this.passwordtype = "password";
      this.passwordstate = false;
    }
  }
}
