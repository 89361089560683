import { Component, OnInit } from '@angular/core';
import { HttpClient,HttpHeaders } from '@angular/common/http';
import { NgModule, EventEmitter, Output } from '@angular/core';
declare var $: any;
import { NgForm } from '@angular/forms';
import { TokenService } from '../../Services/token.service';

@Component({
  selector: 'app-profile-forth',
  templateUrl: './profile-forth.component.html',
  styleUrls: ['./profile-forth.component.css']
})
export class ProfileForthComponent implements OnInit {

 

  constructor( private http:HttpClient, private Token : TokenService) { }

  

  ngOnInit(): void {
 
  }

}