import { Component, OnInit } from '@angular/core';
import { Editor } from 'ngx-editor';
import { NgForm } from '@angular/forms';
import { FormsModule, FormGroup } from '@angular/forms';
import { FormControl, FormArray, FormBuilder, ReactiveFormsModule } from '@angular/forms';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Router } from '@angular/router';
import { TokenService } from '../../Services/token.service';

@Component({
  selector: 'app-job-post-s2',
  templateUrl: './job-post-s2.component.html',
  styleUrls: ['./job-post-s2.component.css']
})
export class JobPostS2Component implements OnInit {

  status: boolean = true;
  jobdetaildesc: any;
  job_experience: any;
  job_responsibilities: any;
  job_desired: any;
  clickEvent() {
    this.status = !this.status;
  }
  url1: any = this.Token.baseurl();
  careerlv: any;
  careerl: string = "";
  degreelv: any;
  degreel: string = "";
  funcarea: any;
  funcar: string = "";
  jobshift: any;
  jobshif: string = "";
  

  constructor(private http: HttpClient, private Token: TokenService, private router: Router) { }

  ngOnInit(): void {
    this.getcareer();
    this.getdegree();
    this.getfuncarea();
    this.getshift();
    this.getpostedjobdesc();
    
    
  }

  logout(Event: MouseEvent) {
    Event.preventDefault();
    this.Token.remove();
    this.router.navigateByUrl('/employer-login');
  }

  getpostedjobdesc() {

    var jb_id = localStorage.getItem('job_id');
    this.http.get<any>(this.Token.baseurl()+ 'api/getpostedjobbyid/' + jb_id).subscribe(
      response => {
        this.jobdetaildesc = response;
        console.log(response);
        this.job_experience = this.jobdetaildesc[0].jobdata.experience_id;
         this.job_responsibilities = this.jobdetaildesc[0].jobdata.role_responsibilities;
         this.job_desired = this.jobdetaildesc[0].jobdata.desired_profile;
      }
    );
  }
 
  getcareer() {
    this.http.get<any>(this.url1 + 'api/getcareerlevel').subscribe(
      response => {
        this.careerlv = response;
      }
    );
  }
  getdegree() {
    this.http.get<any>(this.url1 + 'api/getdegreelevel').subscribe(
      response => {
        this.degreelv = response;
      }
    );
  }
  getfuncarea() {
    this.http.get<any>(this.url1 + 'api/getfunctionalarea').subscribe(
      response => {
        this.funcarea = response;
      }
    );
  }
  getshift() {
    this.http.get<any>(this.url1 + 'api/getjobshift').subscribe(
      response => {
        this.jobshift = response;
      }
    );
  }
  onAddJobthird(data) {
    var jid = localStorage.getItem('job_id');
    var tokens = this.Token.get();
    const url = this.url1 + 'api/addnewjob';
    this.http.post(url, { token: tokens, job_id: jid, career_level_id: data.career_level_id, functional_area_id: data.functional_area_id, job_shift_id: data.job_shift_id, degree_level_id: data.degree_level_id, position: data.position, hide_salary: data.hide_salary, is_freelance: data.is_freelance, experience: data.experience, page: 3 })
      .subscribe((result) => {
        this.ngOnInit();
      });
    for (var dt in data.job_tag) {
      const urls = this.url1 + 'api/updatejobtag';
      this.http.post(urls, { tag_id: data.job_tag[dt].item_id, job_id: jid, token: tokens })
        .subscribe((result) => {
          // this.ngOnInit(); //reload the table
        });
    }

    localStorage.removeItem('job_id');
    this.router.navigateByUrl('/jobs-employer');
  }

  onAddJobthirds(data){
    var jid = localStorage.getItem('job_id');
    var jid1 = localStorage.getItem('grp_job_id');
    var tokens = this.Token.get();
    const url = this.url1 + 'api/addnewjob';
    this.http.post(url, { token: tokens, grp_job_id: jid1, job_id: jid, experience_id: data.experience,description: data.description,role_responsibilities: data.responsibilities,desired_profile: data.profile, page: 3 })
      .subscribe((result) => {
        this.ngOnInit();
      });
      this.router.navigateByUrl('/job-post4');
  }
  privacy_policy() {
    this.router.navigate(['/privacy_policy']);
  }

 agrement(){
    this.router.navigateByUrl('/agreement');
  }

faq_data() {
    this.router.navigate(['/faq_desc']);
  }
  invest_data(){
    this.router.navigate(['/invest']);
  }
  about_us(){
    this.router.navigate(['/about']);
  }

  
}
