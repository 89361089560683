import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { LoginCandidateComponent } from './login-candidate/login-candidate.component';
import { RegisterCandidateComponent } from './register-candidate/register-candidate.component';
import { EmployerLoginComponent } from './loginregister/employer-login/employer-login.component';
import { EmployerRegisterComponent } from './loginregister/employer-register/employer-register.component';
import { EmployerDashboardComponent } from './loginregister/dashboard/employer-dashboard/employer-dashboard.component';
import { BeforeLoginService } from './Services/before-login.service';
import { AfterLoginService } from './Services/after-login.service';
import { CandidateDashboardComponent } from './dashboard-candidate/candidate-dashboard/candidate-dashboard.component';
import { EditEmployerComponent } from './loginregister/edit-employer/edit-employer.component';
import { EditCandidateComponent } from './dashboard-candidate/edit-candidate/edit-candidate.component';
import { JobsComponent } from './loginregister/jobs/jobs.component';
import { JobPostComponent } from './loginregister/job-post/job-post.component';
import { JobSeekerComponent } from './loginregister/job-seeker/job-seeker.component';
import { AppliedJobsComponent } from './dashboard-candidate/applied-jobs/applied-jobs.component';
import { LatestJobComponent } from './dashboard-candidate/latest-job/latest-job.component';
import { AlertJobComponent } from './dashboard-candidate/alert-job/alert-job.component';
import { DetailJobComponent } from './dashboard-candidate/detail-job/detail-job.component';
import { ViewProfileComponent } from './dashboard-candidate/view-profile/view-profile.component';
import { JobPostS1Component } from './loginregister/job-post-s1/job-post-s1.component';
import { JobPostS2Component } from './loginregister/job-post-s2/job-post-s2.component';
import { EditJobpostComponent } from './loginregister/edit-jobpost/edit-jobpost.component';
import { EditJobpost1Component } from './loginregister/edit-jobpost1/edit-jobpost1.component';
import { EditJobpost2Component } from './loginregister/edit-jobpost2/edit-jobpost2.component';
import { JobPost4Component } from './loginregister/job-post4/job-post4.component';

import { MultiStepComponent } from './profile-builder/multi-step/multi-step.component';
import { SearchmapComponent } from './searchmap/searchmap.component';
import { HomeComponent } from './home/home.component';
import { ViewAppliedCandidateComponent } from './loginregister/view-applied-candidate/view-applied-candidate.component';
import { EmployerBillingComponent } from './loginregister/employer-billing/employer-billing.component';
import { CommunicationComponent } from './communication/communication.component';
import { CommunicationMessagingComponent } from './communication-messaging/communication-messaging.component';
import { SearchjobsComponent } from './searchjobs/searchjobs.component';
import { InterviewComponent } from './loginregister/interview/interview.component';
import { BlogsComponent } from './blogs/blogs.component';
import { AboutComponent } from './about/about.component';
import { PrivacyPolicyComponent } from './privacy-policy/privacy-policy.component';
import { FaqDescComponent } from './faq-desc/faq-desc.component';
import { ViewalljobsbyidComponent } from './viewalljobsbyid/viewalljobsbyid.component';
import { TermsServiceComponent } from './loginregister/terms-service/terms-service.component';
import { EmployerDemoComponent } from './loginregister/employer-demo/employer-demo.component';
import { UserAgreementComponent } from './user-agreement/user-agreement.component';
import { EmployerInvestComponent } from './loginregister/employer-invest/employer-invest.component';
import { TestimonialComponent } from './testimonial/testimonial.component';
import { CandidateVerificationComponent } from './candidate-verification/candidate-verification.component';

const routes: Routes = [
	{ path: 'candidate-login', component: LoginCandidateComponent },
	{ path: 'register-candidate', component: RegisterCandidateComponent },
	{ path: 'employer-login', component: EmployerLoginComponent },
	{ path: 'employer-signup', component: EmployerRegisterComponent },
	{ path: 'employer-dashboard', component: EmployerDashboardComponent, canActivate: [AfterLoginService] },
	{ path: 'edit-employer', component: EditEmployerComponent, canActivate: [AfterLoginService] },
	{ path: 'candidate-dashboard', component: CandidateDashboardComponent, canActivate: [AfterLoginService] },
	{ path: 'edit-candidate', component: EditCandidateComponent, canActivate: [AfterLoginService] },
	{ path: 'jobs-employer', component: JobsComponent, canActivate: [AfterLoginService] },
	{ path: 'job-add', component: JobPostComponent, canActivate: [AfterLoginService] },
	{ path: 'job-seekers', component: JobSeekerComponent, canActivate: [AfterLoginService] },
	{ path: 'applied-jobs', component: AppliedJobsComponent },
	{ path: 'latest-jobs', component: LatestJobComponent, canActivate: [AfterLoginService] },
	{ path: 'alert-jobs', component: AlertJobComponent, canActivate: [AfterLoginService] },
	{ path: 'detail-jobs', component: DetailJobComponent, canActivate: [AfterLoginService] },
	{ path: 'employee-profile', component: ViewProfileComponent, canActivate: [AfterLoginService] },
	{ path: 'job-post2', component: JobPostS1Component, canActivate: [AfterLoginService] },
	{ path: 'job-post3', component: JobPostS2Component, canActivate: [AfterLoginService] },
	{ path: 'job-post4', component: JobPost4Component, canActivate: [AfterLoginService] },
	{ path: 'edijob-post', component: EditJobpostComponent, canActivate: [AfterLoginService] },
	{ path: 'editjob-post1', component: EditJobpost1Component, canActivate: [AfterLoginService] },
	{ path: 'editjob-post2', component: EditJobpost2Component, canActivate: [AfterLoginService] },
	{ path: 'profile-builder', component: MultiStepComponent },
	{ path: 'searchmap', component: SearchmapComponent },
	{ path: '', component: HomeComponent },
	{ path: 'view-applied-candidate', component: ViewAppliedCandidateComponent, canActivate: [AfterLoginService] },
	{ path: 'employer-billing', component: EmployerBillingComponent },
	{ path: 'communication', component: CommunicationComponent, canActivate: [AfterLoginService] },
	{ path: 'communication-messaging', component: CommunicationMessagingComponent, canActivate: [AfterLoginService] },
	{ path: 'searchjobs', component: SearchjobsComponent },
	{ path: 'interview-schedule', component: InterviewComponent, canActivate: [AfterLoginService] },
	{ path: 'blog', component: BlogsComponent },
	{ path: 'about', component: AboutComponent },
	{ path: 'privacy_policy', component: PrivacyPolicyComponent },
	{ path: 'faq_desc', component: FaqDescComponent },
	{ path: 'job_apply', component: ViewalljobsbyidComponent },
	{ path: 'terms-srv', component: TermsServiceComponent },
	{ path: 'employer-demo', component: EmployerDemoComponent },
	{ path: 'agreement', component: UserAgreementComponent },
	{ path: 'invest', component: EmployerInvestComponent },
	{ path: 'testimonials', component: TestimonialComponent },
	{ path: 'jobseeker-verification', component: CandidateVerificationComponent },
];

@NgModule({
	imports: [RouterModule.forRoot(routes)],
	exports: [RouterModule]
})
export class AppRoutingModule { }
