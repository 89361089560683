import { Component, OnInit } from '@angular/core';
import { NgModule, EventEmitter, Output } from '@angular/core';
import { FormControl, FormArray, FormBuilder, ReactiveFormsModule } from '@angular/forms';
import { FormsModule, FormGroup } from '@angular/forms';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Router } from '@angular/router';
import { TokenService } from '../../Services/token.service';
import { MatAutocompleteModule } from '@angular/material/autocomplete';

@Component({
  selector: 'app-profile-fifth',
  templateUrl: './profile-fifth.component.html',
  styleUrls: ['./profile-fifth.component.css']
})
export class ProfileFifthComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}