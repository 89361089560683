import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { HttpClientModule } from '@angular/common/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatNativeDateModule } from '@angular/material/core';
import { MatIconModule } from '@angular/material/icon';
import { MaterialModule } from './material.module';

import { NgxEditorModule } from 'ngx-editor';
import { AngularEditorModule } from '@kolkov/angular-editor';

import { MatMenuModule } from '@angular/material/menu';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatButtonModule } from '@angular/material/button';

import { MatSelectModule } from '@angular/material/select';
import { NgxMatSelectSearchModule } from 'ngx-mat-select-search';

import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';

import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { Ng2SearchPipeModule } from 'ng2-search-filter';

import { MatChipsModule } from '@angular/material/chips';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import {MatTabsModule} from '@angular/material/tabs';
import {MatDividerModule} from '@angular/material/divider';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { EmployerHeaderComponent } from './loginregister/employer-header/employer-header.component';
import { LoginCandidateComponent } from './login-candidate/login-candidate.component';
import { RegisterCandidateComponent } from './register-candidate/register-candidate.component';
import { EmployerLoginComponent } from './loginregister/employer-login/employer-login.component';
import { EmployerRegisterComponent } from './loginregister/employer-register/employer-register.component';
import { EmployerDashboardComponent } from './loginregister/dashboard/employer-dashboard/employer-dashboard.component';
import { SidebarComponent } from './loginregister/dashboard/sidebar/sidebar.component';
import { CandidateDashboardComponent } from './dashboard-candidate/candidate-dashboard/candidate-dashboard.component';
import { EditEmployerComponent } from './loginregister/edit-employer/edit-employer.component';

import { GooglePlaceModule } from "ngx-google-places-autocomplete";
import { EditCandidateComponent } from './dashboard-candidate/edit-candidate/edit-candidate.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { CertificateComponent } from './dashboard-candidate/certificate/certificate.component';
import { JobsComponent } from './loginregister/jobs/jobs.component';
import { JobPostComponent } from './loginregister/job-post/job-post.component';
import { JobSeekerComponent } from './loginregister/job-seeker/job-seeker.component';
import { LatestJobComponent } from './dashboard-candidate/latest-job/latest-job.component';
import { AlertJobComponent } from './dashboard-candidate/alert-job/alert-job.component';
import { DetailJobComponent } from './dashboard-candidate/detail-job/detail-job.component';
import { ViewProfileComponent } from './dashboard-candidate/view-profile/view-profile.component';
import { CareerInfoComponent } from './dashboard-candidate/career-info/career-info.component';
import { JobPostS1Component } from './loginregister/job-post-s1/job-post-s1.component';
import { JobPostS2Component } from './loginregister/job-post-s2/job-post-s2.component';
import { EditJobpostComponent } from './loginregister/edit-jobpost/edit-jobpost.component';
import { EditJobpost1Component } from './loginregister/edit-jobpost1/edit-jobpost1.component';
import { EditJobpost2Component } from './loginregister/edit-jobpost2/edit-jobpost2.component';
import { MultiStepComponent } from './profile-builder/multi-step/multi-step.component';
import { HeaderComponent } from './profile-builder/header/header.component';
import { ProfileFooterComponent } from './profile-builder/profile-footer/profile-footer.component';
import { ProfileFirstComponent } from './profile-builder/profile-first/profile-first.component';
import { ProfileSecondComponent } from './profile-builder/profile-second/profile-second.component';
import { ProfileThirdComponent } from './profile-builder/profile-third/profile-third.component';
import { ProfileForthComponent } from './profile-builder/profile-forth/profile-forth.component';
import { ProfileFifthComponent } from './profile-builder/profile-fifth/profile-fifth.component';
import { ProfileSixthComponent } from './profile-builder/profile-sixth/profile-sixth.component';
import { ProfileViewComponent } from './profile-builder/profile-view/profile-view.component';

import { SearchmapComponent } from './searchmap/searchmap.component';
import { HomeComponent } from './home/home.component';
import { ProfileSeventhComponent } from './profile-builder/profile-seventh/profile-seventh.component';
import { CandidateHeaderComponent } from './dashboard-candidate/candidate-header/candidate-header.component';
import { JobPost4Component } from './loginregister/job-post4/job-post4.component';
import { ViewAppliedCandidateComponent } from './loginregister/view-applied-candidate/view-applied-candidate.component';
import { AppliedJobsComponent } from './dashboard-candidate/applied-jobs/applied-jobs.component';
import { EmployerBillingComponent } from './loginregister/employer-billing/employer-billing.component';
import { CommunicationComponent } from './communication/communication.component';
import { CommunicationMessagingComponent } from './communication-messaging/communication-messaging.component';
import { IvyCarouselModule } from 'angular-responsive-carousel';
import * as $ from 'jquery';
import { SearchjobsComponent } from './searchjobs/searchjobs.component';
import { InterviewComponent } from './loginregister/interview/interview.component';
import { BlogsComponent } from './blogs/blogs.component';
import { AboutComponent } from './about/about.component';
import { UniqueEmailValidatorDirective } from './Services/unique-email-validator.directive';
import { PrivacyPolicyComponent } from './privacy-policy/privacy-policy.component';
import { FaqDescComponent } from './faq-desc/faq-desc.component';
import { ViewalljobsbyidComponent } from './viewalljobsbyid/viewalljobsbyid.component';
import { TermsServiceComponent } from './loginregister/terms-service/terms-service.component';
import { EmployerDemoComponent } from './loginregister/employer-demo/employer-demo.component';
import { UserAgreementComponent } from './user-agreement/user-agreement.component';
import { EmployerInvestComponent } from './loginregister/employer-invest/employer-invest.component';
import { TestimonialComponent } from './testimonial/testimonial.component';
import { CandidateVerificationComponent } from './candidate-verification/candidate-verification.component';

@NgModule({
  declarations: [
    AppComponent,
    LoginCandidateComponent,
    RegisterCandidateComponent,
    EmployerLoginComponent,
    EmployerRegisterComponent,
    EmployerDashboardComponent,
    SidebarComponent,
    CandidateDashboardComponent,
    EditEmployerComponent,
    EditCandidateComponent,
    CertificateComponent,
    JobsComponent,
    JobPostComponent,
    JobSeekerComponent,
    LatestJobComponent,
    AlertJobComponent,
    DetailJobComponent,
    ViewProfileComponent,
    CareerInfoComponent,
    JobPostS1Component,
    JobPostS2Component,
    EditJobpostComponent,
    EditJobpost1Component,
    EditJobpost2Component,
    MultiStepComponent,
    HeaderComponent,
    EmployerHeaderComponent,
    ProfileFooterComponent,
    ProfileFirstComponent,
    ProfileSecondComponent,
    ProfileThirdComponent,
    ProfileForthComponent,
    ProfileFifthComponent,
    ProfileSixthComponent,
    ProfileSeventhComponent,
    ProfileViewComponent,
    SearchmapComponent,
    HomeComponent,
    ProfileSeventhComponent,
    CandidateHeaderComponent,
    JobPost4Component,
    ViewAppliedCandidateComponent,
    AppliedJobsComponent,
    EmployerBillingComponent,
    CommunicationComponent,
    CommunicationMessagingComponent,
    SearchjobsComponent,
    InterviewComponent,
    BlogsComponent,
    AboutComponent,
    UniqueEmailValidatorDirective,
    PrivacyPolicyComponent,
    FaqDescComponent,
    ViewalljobsbyidComponent,
    TermsServiceComponent,
    EmployerDemoComponent,
    UserAgreementComponent,
    EmployerInvestComponent,
    TestimonialComponent,
    CandidateVerificationComponent,

  ],
  imports: [
    GooglePlaceModule,
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
    MatDatepickerModule,
    MatInputModule,
    MaterialModule,
    MatFormFieldModule,
    MatNativeDateModule,
    MatIconModule,
    BrowserAnimationsModule,
    NgbModule,
    Ng2SearchPipeModule,
    MatChipsModule,
    MatAutocompleteModule,
    IvyCarouselModule,
    MatTabsModule,
    MatDividerModule,
    NgxSkeletonLoaderModule,
    MatSelectModule,
    NgxMatSelectSearchModule,
    NgxEditorModule,
    AngularEditorModule,
    NgMultiSelectDropDownModule.forRoot()
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
