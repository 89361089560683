import { Component, OnInit } from "@angular/core";
import { FormControl } from "@angular/forms";
import { Router } from "@angular/router";
import { TokenService } from "../Services/token.service";
import { HttpClient } from "@angular/common/http";
import Swal from "sweetalert2";

declare var $: any;

@Component({
  selector: "app-searchjobs",
  templateUrl: "./searchjobs.component.html",
  styleUrls: ["./searchjobs.component.css"],
})
export class SearchjobsComponent implements OnInit {
  isemployee: boolean = false;
  isemployeer: boolean = false;
  islog: any;

  resume_token: any;
  testtoken: any;

  all_job_data: any;
  leatest_job_data: any;

  searchdata: any = [];
  filtered_searchdata: any = [];

  find_search_jobs_data: any = null;
  find_search_jobs_address: any = null;
  find_search_jobs_city: any = null;
  find_search_jobs_state: any = null;
  filter_search_jobs_control = new FormControl();
  latitude: any;
  longitude: any;

  constructor(
    public httpclient: HttpClient,
    private router: Router,
    private Token: TokenService
  ) {}

  ngOnInit(): void {
    $(".loader-area").css("display", "none");

    // window.addEventListener('load', () => {
    //   console.log("hi");
    //
    // });

    // $(".loader-area").css("display", "none");
    // $(window).on('load', function(){
    //   console.log("Hi");
    //   // "use strict";
    //   // /*  ===================================
    //   //     Loading Timeout
    //   // ====================================== */
    //   $(".loader-area").css("display", "none");
    // });

    this.resume_token = localStorage.getItem("resume_token");
    this.testtoken = localStorage.getItem("token");
    if (this.resume_token != "" && this.resume_token != null) {
      this.isemployee = true;
      this.isemployeer = false;
      this.islog = false;

      this.httpclient
        .get<any>(
          this.Token.baseurl() + "api/getresumeprofile/" + this.resume_token
        )
        .subscribe((response: any) => {
          this.find_search_jobs_city = response[0]["city"];
          this.find_search_jobs_state = response[0]["state"];
          localStorage.setItem("mapsearch_state", this.find_search_jobs_state);
        });
    } else {
      if (this.testtoken != "" && this.testtoken != null) {
        this.isemployee = false;
        this.isemployeer = true;
        this.islog = false;
        // console.log(this.islog + '2');
      } else {
        this.islog = true;
        this.isemployee = false;
        this.isemployeer = false;
        // console.log(this.islog + '3');
      }
    }

    this.getasumrjobtype();
    this.getleatestjobs();

    this.loadCss();
  }

  onJobsearchInput(data: any) {
    let res = data.target.value;
    if (
      res == "" ||
      res == " " ||
      res == "null" ||
      res == "NULL" ||
      res == null
    ) {
      console.log(res);
      $("#searchrecommand").css("display", "none");
    } else if (
      res != "" ||
      res != " " ||
      res != "null" ||
      res != "NULL" ||
      res != null
    ) {
      let query = res.toLowerCase();
      this.find_search_jobs_data = res;
      this.filtered_searchdata = this.searchdata.filter(
        (item) => item.toLowerCase().indexOf(query) >= 0
      );
      $("#searchrecommand").css("display", "block");
    }
  }

  getasumrjobtype() {
    this.httpclient
      .get<any>(this.Token.baseurl() + "api/getjobtype")
      .subscribe((response: any) => {
        for (var i = 0; i < response.length; i++) {
          this.searchdata[i] = response[i]["name"];
          this.filtered_searchdata[i] = response[i]["name"];
        }
      });
  }

  getleatestjobs() {
    this.httpclient
      .get<any>(this.Token.baseurl() + "api/getavaiablelatestjob")
      .subscribe((response: any) => {
        this.leatest_job_data = response;
        //  console.log(this.leatest_job_data);
      });
  }

  fill_find_search_jobs(data) {
    this.find_search_jobs_data = data;
    $("#searchrecommand").css("display", "none");
  }

  AddressChange(address) {
    this.find_search_jobs_address = address.formatted_address;
    this.latitude = address.geometry.location.lat();
    this.longitude = address.geometry.location.lng();
    if (this.resume_token == "" || this.resume_token == null) {
      for (const component of address.address_components) {
        const componentType = component.types[0];
        switch (componentType) {
          case "administrative_area_level_2": {
            var city;
            city = component.long_name;
            this.find_search_jobs_city = city.split(" ")[0];
            //  console.log(this.find_search_jobs_city);
            break;
          }
          case "administrative_area_level_1": {
            this.find_search_jobs_state = component.short_name;
            // localStorage.setItem("mapsearch_state", this.find_search_jobs_state);
            break;
          }
        }
      }
    }
  }

  find_search_jobs() {
    if (this.find_search_jobs_address) {
      var link =
        "jobsearchmanual=TRUE&jobsearchdata=" +
        this.find_search_jobs_data +
        "&jobsearchaddress=" +
        this.find_search_jobs_address +
        "&lat=" +
        this.latitude +
        "&long=" +
        this.longitude;

      //this.router.navigateByUrl('/searchmap?' + link);
      window.location.href = "/searchmap?" + link;
      // this.httpclient.post(this.Token.baseurl() + 'api/getpostedjobfullbyfiltercity', { src: this.find_search_jobs_data, address: data.address, city: this.city }).subscribe(
      //   (response: any) => {
      //     this.all_job_data = response;
      //     console.log(this.all_job_data);
      //   }
      // );
    } else {
      Swal.fire({
        title: "Warning!",
        text: "Please choose location",
        icon: "warning",
        confirmButtonText: "Ok",
      });
    }
  }

  dropboxarrow() {
    $(".dropbox").toggle("slow");
  }

  employer_profile() {
    this.router.navigate(["/edit-employer"]);
  }
  employer_dashboard() {
    this.router.navigate(["/employer-dashboard"]);
  }
  employer_billing() {
    this.router.navigate(["/employer-billing"]);
  }
  employer_communication() {
    this.router.navigate(["/communication"]);
  }
  employer_data_management() {
    this.router.navigate(["/employer_communication"]);
  }
  employer_manage_jobs() {
    this.router.navigate(["/jobs-employer"]);
  }
  profile() {
    this.router.navigateByUrl("/profile-builder");
  }
  communication() {
    this.router.navigateByUrl("/communication");
  }
  employees() {
    this.router.navigate(["/candidate-login"]);
  }
  candidate_logout(Event: MouseEvent) {
    this.Token.remove();
    localStorage.clear();
    this.router.navigateByUrl("");
    this.ngOnInit();
  }
  employer_logout(Event: MouseEvent) {
    this.Token.remove();
    localStorage.clear();
    this.router.navigateByUrl("");
    this.ngOnInit();
  }
  employers() {
    this.router.navigate(["/employer-login"]);
  }
  dashboard() {
    this.router.navigateByUrl("/candidate-dashboard");
  }
  status: boolean = true;
  clickEvent() {
    this.status = !this.status;
  }

  public loadCss() {
    const node = document.createElement("link");
    node.href = "assets/digital-marketing/css/style-map.css"; // put there your js file location
    node.rel = "stylesheet";
    node.charset = "utf-8";
    document.getElementsByTagName("head")[0].appendChild(node);
  }
}
