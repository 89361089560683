import { Component, OnInit } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Router, ActivatedRoute } from "@angular/router";
import { TokenService } from "../Services/token.service";
import { NgbModal, ModalDismissReasons } from "@ng-bootstrap/ng-bootstrap";
import Swal from "sweetalert2";
import { DomSanitizer, SafeResourceUrl } from "@angular/platform-browser";
import * as turf from "@turf/turf";

declare var $: any;

@Component({
  selector: "app-viewalljobsbyid",
  templateUrl: "./viewalljobsbyid.component.html",
  styleUrls: ["./viewalljobsbyid.component.css"],
})
export class ViewalljobsbyidComponent implements OnInit {
  jobid: any;
  token: any;

  alertjob_list: any;
  all_job_data_by_company: any;
  rest_main_image: any;
  comp_name: any;
  comp_specialty: any;
  comp_details: any;
  comp_type: any;
  comp_address: any;
  comp_established_in: any;
  comp_embd_url: any;

  isapplied: any;

  constructor(
    private http: HttpClient,
    private router: Router,
    private Token: TokenService,
    public sanitizer: DomSanitizer,
    private Activatedroute: ActivatedRoute
  ) {}

  ngOnInit(): void {
    this.Activatedroute.queryParamMap.subscribe((params) => {
      this.jobid = params.get("jobid");
      this.token = params.get("token");
    });
    this.showDetails();
  }

  showDetails() {
    jQuery.noConflict();

    var demo_img = this.Token.baseurl() + "assets/employersilder/" + 'demo.png';

    this.http
      .get<any>(this.Token.baseurl() + "api/addtotalviewjob/" + this.jobid)
      .subscribe();

    this.http
      .get<any>(
        this.Token.baseurl() +
          "api/getjobdetailsbyjobid/" +
          this.jobid +
          "/" +
          this.token
      )
      .subscribe((response) => {
        console.log(response);
        this.isapplied = response[0].jobdata.isapplied;

        // this.rest_main_image =
        //   this.Token.baseurl() +
        //   "assets/employersilder/" +
        //   response[0].companydata[0].image1;

          if (response[0].companydata[0].image1 != '') {
            this.rest_main_image = this.Token.baseurl() + "assets/employersilder/" + response[0].companydata[0].image1;
          } else {
            this.rest_main_image = demo_img;
          }

        this.comp_name = response[0].userdata[0].first_name;
        this.comp_specialty = response[0].companydata[0].speciality;
        this.comp_details = response[0].companydata[0].details;
        this.comp_type = response[0].jobdata.industry_id; 
       // console.log(this.comp_type);
        this.comp_address = response[0].jobdata.job_location;
        this.comp_established_in = response[0].companydata[0].established_in;

        this.comp_embd_url = this.sanitizer.bypassSecurityTrustResourceUrl(
          "https://maps.google.com/maps/embed/v1/place?key=AIzaSyDL5VMrQ9M0XPXz-RqNrPGXxNtTRIjyhys&q=" +
            this.comp_address
              .replace(" ", "+")
              .replace(" ", "+")
              .replace(" ", "+")
              .replace(" ", "+")
              .replace(" ", "+")
              .replace(" ", "+")
              .replace(" ", "+")
              .replace(" ", "+")
              .replace(",", "")
              .replace(",", "")
              .replace(",", "")
        );

        this.all_job_data_by_company = response;
      });
  }
}
