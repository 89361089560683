import { Component, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { FormsModule, FormGroup } from '@angular/forms';
import { FormControl, FormArray, FormBuilder, ReactiveFormsModule } from '@angular/forms';
import { HttpClient,HttpHeaders } from '@angular/common/http';
import { TokenService } from '../../Services/token.service';
import { Router } from '@angular/router';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-jobs',
  templateUrl: './jobs.component.html',
  styleUrls: ['./jobs.component.css']
})
export class JobsComponent implements OnInit {
  
  empyrdetails: any;
  statedata: any;

public changestatus(job_id,event){
  const newVal = event.target.value;
 this.http.get<any>(this.Token.baseurl()+'api/updatepostedjobstatus/'+job_id+'/'+newVal).subscribe(
    response => {
    }
  );
}

searchText: any;
filter: any;
status_value:any='0';
j_status:any='0';

  values = [
  { id: 1, name: "Active" },
  { id: 2, name: "Closed" },
  { id: 3, name: "Paused" },
  { id: 4, name: "Suspended" }
];

value = [
  { id: 1, name: "Active" },
  { id: 2, name: "Closed" },
  { id: 3, name: "Paused" },
  { id: 4, name: "Suspended" }
];

public filterstatus(event){
   var token = this.Token.get();
  const newValu = event.target.value;
 // alert(newValu);
 if(newValu == '0: 0'){
    this.getjoblist();
 }else{
   this.http.get<any>(this.Token.baseurl()+'api/getpostedjobbystatus/'+token+'/'+newValu).subscribe(
    response => {
   // console.log(response);
    this.filter = response;
    this.joblisted = response;
    }
    );
 }

  
}

status: boolean = true;
  clickEvent(){
      this.status = !this.status;       
  }
selected:any;
  joblisted:any;
  joblist: string="";

  constructor( private http:HttpClient, private Token : TokenService, private router: Router ) { }

  ngOnInit(): void {
  this.getjoblist();
  }

  editjobbyid(id,grp_job_id) {
  localStorage.setItem('job_id',id);
  localStorage.setItem('grp_job_id',grp_job_id);
  this.router.navigate(['/job-add']);
 }
 viewjobs(id){
  var token = localStorage.getItem('token');
  
  var data = 'jobid='+id+'&token='+ token;
  
  // window.location.href = '/job_apply?' + data;
  window.open('/job_apply?' + data);
 }

  getjoblist(){
  var token = this.Token.get();
  this.http.get<any>(this.Token.baseurl()+'api/getpostedjob/'+token).subscribe( response => {
    this.joblisted = response;
    
    console.log(response);
    }
  );
}

deletepostjob(id){
    Swal.fire({
      title: 'Are you sure want to remove this job?',
      text: 'You will not be able to recover this job!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, delete it!',
      cancelButtonText: 'No, keep it'
    }).then((result) => {
      if (result.value) {
        Swal.fire(
          'Deleted!',
          'Your posted job has been deleted.',
          'success'
        )
        this.http.delete(this.Token.baseurl()+'api/deletepostedjob/'+id).subscribe((result) => {          
            this.ngOnInit();
          });
      } else if (result.dismiss === Swal.DismissReason.cancel) {
        Swal.fire(
          'Cancelled',
          'Your posted job is safe :)',
          'error'
        )
      }
    })
  }
  addnewjob(){
    var token = this.Token.get();
    this.http.get<any>(this.Token.baseurl() + 'api/employerdetails/' + token).subscribe(
      response => {
        this.empyrdetails = response;
        this.statedata = this.empyrdetails.state_id;

        if(this.statedata == null){
          var txt='Please complete your profile first!';
          this.alertWithSuccess(txt);
          this.router.navigate(['/edit-employer']);
        }else{
          localStorage.removeItem('job_id');
          this.router.navigate(['/job-add']);
        }
      }
    );

    
  }
  alertWithSuccess(txt){
    Swal.fire('Thank you...',txt, 'success')
    }
    invest_data(){
      this.router.navigate(['/invest']);
    }
    about_us(){
      this.router.navigate(['/about']);
    }
    home_page(){
      window.location.href = '/';
     }
}
