import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { TokenService } from '../../Services/token.service';
import { HttpClient } from '@angular/common/http';
declare var $: any;

@Component({
  selector: 'app-candidate-header',
  templateUrl: './candidate-header.component.html',
  styleUrls: ['./candidate-header.component.css']
})
export class CandidateHeaderComponent implements OnInit {
  status: boolean = true;
  resume_token: any;

  clickEvent(){
      this.status = !this.status;    
  }
  
  find_search_jobs_data: any;
  find_search_jobs_address: any;
  find_search_jobs_city: any;
  find_search_jobs_state: any;
  filtered_searchdata: any = [];
  searchdata: any = [];

  constructor(public httpclient: HttpClient, private router: Router, private Token: TokenService ) { }

  ngOnInit(): void {
    // if(!this.Token.get()){
    //   this.router.navigateByUrl('/');
    // }

    this.resume_token = localStorage.getItem("resume_token");
  	this.loadCss();
    $("#search").click(function() {
      $(".searchbox").toggle();

    });
  //  this.getasumrjobtype();
  }

  dropboxarrow() {
    $(".dropbox").toggle("fast");
  }

  logout(Event: MouseEvent){
    this.Token.remove();
    localStorage.clear();
    this.router.navigateByUrl('/candidate-login');
  }
  communication() {
    this.router.navigateByUrl('/communication');
  }
  
  dashboard() {
    var token = localStorage.getItem("token");
    if (token) {
      location.href="/candidate-dashboard";
    //this.router.navigateByUrl('/candidate-dashboard');
    }
    else{
      location.href="/";
    }
  }
  profile(){
    this.router.navigateByUrl('/profile-builder');
  }
  home(){
    this.router.navigateByUrl('/home');
  }
  employees(){
    this.router.navigateByUrl('/candidate-dashboard');
  }
  candidate_logout(Event: MouseEvent){
    this.Token.remove();
    localStorage.clear();
    window.location.href = '/index.html';
  //  this.ngOnInit();
  }

  // search icon in header
  // getasumrjobtype() {
  //   this.httpclient.get<any>(this.Token.baseurl() + 'api/getjobtype').subscribe(
  //     (response: any) => {
  //       for (var i = 0; i < response.length; i++) {
  //         this.searchdata[i] = response[i]['name'];
  //         this.filtered_searchdata[i] = response[i]['name'];
  //       }
  //     }
  //   );
  // }

  // onJobsearchInput(data: any) {
  //   let res = data.target.value;
  //   if (res == '' || res == ' ' || res == 'null' || res == 'NULL' || res == null) {
  //     console.log(res);
  //     $("#searchrecommand").css("display", "none");
  //   } else if (res != '' || res != ' ' || res != 'null' || res != 'NULL' || res != null) {
  //     let query = res.toLowerCase();
  //     this.filtered_searchdata = this.searchdata.filter(item => item.toLowerCase().indexOf(query) >= 0);
  //     $("#searchrecommand").css("display", "block");
  //   }
  // }

  // fill_find_search_jobs(data) {
  //   this.find_search_jobs_data = data;
  //   $("#searchrecommand").css("display", "none");
  // }

  // AddressChange(address){
  //   this.find_search_jobs_address = address.formatted_address;
  //   if (this.resume_token == '' || this.resume_token == null) {
  //     for (const component of address.address_components) {
  //       const componentType = component.types[0];
  //       switch (componentType) {
  //         case "administrative_area_level_2": {
  //           var city;
  //           city = component.long_name;
  //           this.find_search_jobs_city = city.split(" ")[0];
  //           break;
  //         }
  //         case "administrative_area_level_1": {
  //           this.find_search_jobs_state = component.short_name;
  //           localStorage.setItem("mapsearch_state",this.find_search_jobs_state);
  //           break;
  //         }
  //       }
  //     }
  //   }
  // }

  // find_search_jobs() {
  //   if (this.find_search_jobs_data) {
  //     var link = 'jobsearchmanual=TRUE&jobsearchdata=' + this.find_search_jobs_data + '&jobsearchaddress=' + this.find_search_jobs_address + '&jobsearchcity=' + this.find_search_jobs_city + '&state=' + this.find_search_jobs_state;

  //     this.router.navigateByUrl('/searchmap?' + link);
  //   }
  // }
  // Ends Search icon


  public loadCss() {
    const node = document.createElement('link');
    node.href = 'assets/digital-marketing/css/style_new.css'; // put there your js file location
    node.rel = 'stylesheet';
    node.charset = 'utf-8';
   document.getElementsByTagName('head')[0].appendChild(node);
  }
  jobsearch() {
    window.location.href = '/searchjobs';
  }
  home_page(){
    window.location.href = '/';
   }
   aplydjobs() {
    this.router.navigateByUrl('/applied-jobs');
  }
  
}
